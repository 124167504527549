import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { FadeInView, Header } from '@/Components'
import { TextInput } from 'react-native-paper'
import { Colors } from '@/Theme/Variables'
import { navigate } from '@/Navigators/utils'
import userApi from '@/Services/modules/users'
import { Validation } from '@/Util/validation'
import { useDispatch } from 'react-redux'
import Jssha from 'jssha'
import { SignUpBasketRequestFields } from '@/Services/modules/users/user.type'
import { userActions } from '@/Store/User'
import { useIsFocused } from '@react-navigation/native'
import { openApplyInviteCodeWeb, removeEmptyKeys } from '@/Util/global'
// import { useTranslation } from 'react-i18next'
import { api } from '@/Services/api'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

interface RegisterType {
  account: string
  password: string
  inviteCode: string
}

const InitData = {
  account: '',
  password: '',
  inviteCode: '',
}

const EmailRegisterContainer = ({ navigation }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { data: siteMetaData } = api.useGetSiteMetaQuery()

  // NOTE:邀請碼選必填機制
  const isMGMCodeRequired = siteMetaData?.features?.invitation || false

  const [
    signUpBasket,
    {
      data: signUpBasketData,
      error: signUpBasketError,
      isError: signUpBasketIsError,
      isSuccess: signUpBasketIsSuccess,
      isLoading: signUpBasketIsLoading,
      reset: signUpBasketReset,
    },
  ] = userApi.useSignUpBasketMutation()

  const [register, setRegister] = useState<RegisterType>(InitData)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [openPassword, setOpenPassword] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState(InitData)
  const [passwordValidation, setPasswordValidation] = useState({
    isLongEnough: false,
    hasNumber: false,
    hasMixedCase: false,
  })

  const styles = stylesSheet()
  const loginBtnBgColor = submitButtonDisabled ? '#D9D9D9' : Colors.primary

  useEffect(() => {
    if (isFocused) {
      dispatch(
        userActions.setUserRegisterData({
          socialHasAccount: false,
        }),
      )
    }
  }, [dispatch, isFocused])

  // 下一步後，倒頁至 opt 驗證碼頁面，如果是 social 則導到 social 的頁面
  useEffect(() => {
    if (signUpBasketIsSuccess) {
      navigation.navigate(PagesEnum.EmailOTPContainer, {
        basket: signUpBasketData?.data?.basket || '',
        account: signUpBasketData?.data?.account || '',
      })
      signUpBasketReset()
      setRegister(InitData)

      // 將帳密存起來，之後完成註冊登入用
      const shaPassword = new Jssha('SHA3-512', 'TEXT')
      shaPassword.update(register.password)
      dispatch(
        userActions.setUserRegisterData({
          account: register.account,
          password: shaPassword.getHash('HEX'),
        }),
      )
    }
  }, [
    dispatch,
    navigation,
    register.account,
    register.password,
    signUpBasketData?.data?.account,
    signUpBasketData?.data?.basket,
    signUpBasketIsSuccess,
    signUpBasketReset,
  ])

  useEffect(() => {
    if (signUpBasketIsError) {
      const errorRes = signUpBasketError as any
      const message = errorRes?.data?.errors?.[0]?.message

      if (message === 'InvalidInviteCode') {
        setErrorMessage({
          ...errorMessage,
          inviteCode: '邀請碼無效',
        })
      }

      if (message === 'InviteCodeMaxUsagesExceed') {
        setErrorMessage({
          ...errorMessage,
          inviteCode: '邀請碼兌換已滿額',
        })
      }

      if (message === 'AccountExisted') {
        setErrorMessage({
          ...errorMessage,
          account: '此信箱已被註冊',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpBasketError, signUpBasketIsError])

  useEffect(() => {
    // NOTE:邀請碼選必填機制
    const disabled = isMGMCodeRequired
      ? !Validation.isEmail(register?.account) ||
        !Validation.signUpPasswordRegex(register?.password) ||
        !register?.inviteCode?.length
      : !Validation.isEmail(register?.account) ||
        !Validation.signUpPasswordRegex(register?.password)

    if (disabled) {
      setSubmitButtonDisabled(true)
    } else {
      setSubmitButtonDisabled(false)
    }
  }, [register, isMGMCodeRequired])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      setRegister(InitData)
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
    setRegister(InitData)
  }, [navigation])

  const handleOnChangeInput = (
    type: 'account' | 'password' | 'inviteCode',
    value: string,
  ) => {
    setRegister({
      ...register,
      [type]: value,
    })
    setErrorMessage({
      ...errorMessage,
      [type]: '',
    })

    // 當輸入密碼時，更新密碼驗證狀態
    if (type === 'password') {
      setPasswordValidation({
        isLongEnough: Validation.isPasswordLongEnough(value),
        hasNumber: Validation.hasPasswordNumber(value),
        hasMixedCase: Validation.hasPasswordMixedCase(value),
      })
    }
  }

  const handleSubmit = () => {
    if (signUpBasketIsLoading) {
      return
    }

    let checked = true

    if (!Validation.isEmail(register.account)) {
      checked = false
      setErrorMessage({ ...errorMessage, account: '請輸入正確的信箱格式' })
    }

    // NOTE:邀請碼選必填機制
    if (!register.inviteCode && isMGMCodeRequired) {
      checked = false
      setErrorMessage({ ...errorMessage, inviteCode: '請輸入邀請碼' })
    }

    if (!Validation.signUpPasswordRegex(register.password)) {
      checked = false
      setErrorMessage({
        ...errorMessage,
        password: '密碼必須至少 8 個字符，含一個數字，一個大小寫字母',
      })
    }

    if (checked) {
      const shaPassword = new Jssha('SHA3-512', 'TEXT')
      shaPassword.update(register.password)

      const payload: Partial<SignUpBasketRequestFields> = {
        account: register.account,
        password: shaPassword.getHash('HEX'),
        inviteCode: register.inviteCode,
      }

      signUpBasket({
        fields: {
          ...removeEmptyKeys(payload),
        },
      })
    }
  }

  const renderRegisterView = () => {
    return (
      <ScrollView style={[Layout.fill]}>
        <View>
          <View style={[Gutters.regularHPadding]}>
            <Image
              source={Images.app_icon}
              style={[Layout.iconSize40, { borderRadius: 10 }]}
              resizeMode="contain"
            />
          </View>
          {/* 輸入帳密欄位 */}
          <FadeInView duration={550} style={Gutters.regularHPadding}>
            <Text
              style={[
                Fonts.size20,
                Fonts.weight700,
                Gutters.smallTMargin,
                Gutters.tinyBMargin,
                { color: Colors.fontText.light.primary1 },
              ]}
            >
              建立您的烈酒探索家帳號
            </Text>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight400,
                Gutters.tinyBMargin,
                { color: Colors.fontText.light.primary1 },
              ]}
            >
              請輸入您的電郵，例 spirits@mail.com
            </Text>
            <View style={[Gutters.regularBMargin]}>
              <View style={[Gutters.regularBMargin]}>
                <TextInput
                  label="電子郵件地址"
                  value={register.account}
                  autoFocus={true}
                  style={[{ color: Colors.white }, styles.textInput]}
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  activeOutlineColor={Colors.primary}
                  theme={{
                    colors: {
                      onSurfaceVariant: Colors.fontText.light.primary3,
                      error: Colors.snackbar.error,
                      outline: Colors.background.top,
                    },
                    roundness: 10,
                  }}
                  textColor="#fff"
                  mode="outlined"
                  error={!!errorMessage?.account}
                  onChangeText={value => handleOnChangeInput('account', value)}
                />
              </View>
              {!!errorMessage?.account && (
                <Text
                  style={[
                    Gutters.tinyBMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage?.account}
                </Text>
              )}
              <View style={[Gutters.tinyBMargin]}>
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight400,
                    Gutters.tinyBMargin,
                    { color: Colors.fontText.light.primary1 },
                  ]}
                >
                  設定您的密碼
                </Text>
                <TextInput
                  label="密碼"
                  value={register.password}
                  secureTextEntry={!openPassword}
                  style={[{ color: Colors.white }, styles.textInput]}
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  activeOutlineColor={Colors.primary}
                  theme={{
                    colors: {
                      onSurfaceVariant: Colors.fontText.light.primary3,
                      error: Colors.snackbar.error,
                      outline: Colors.background.top,
                    },
                    roundness: 10,
                  }}
                  textColor="#fff"
                  mode="outlined"
                  error={!!errorMessage?.password}
                  onChangeText={value => handleOnChangeInput('password', value)}
                  right={
                    <TextInput.Icon
                      icon={() => (
                        <TouchableOpacity
                          onPress={() => setOpenPassword(!openPassword)}
                        >
                          <Image
                            source={
                              openPassword ? Images.eye_open : Images.eye_off
                            }
                            resizeMode="contain"
                            style={[Layout.iconSize20]}
                          />
                        </TouchableOpacity>
                      )}
                    />
                  }
                />
              </View>
              {!!errorMessage?.password && (
                <Text
                  style={[
                    Gutters.tinyBMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage?.password}
                </Text>
              )}
              <View style={[Gutters.smallTMargin, Gutters.regularBMargin]}>
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Image
                    source={
                      passwordValidation.isLongEnough
                        ? Images.register_checked_icon
                        : Images.register_check_icon
                    }
                    resizeMode="contain"
                    style={[Layout.iconSize20, Gutters.tinyRMargin]}
                  />
                  <Text
                    style={[
                      Fonts.size14,
                      {
                        color: Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    至少 8 個字符
                  </Text>
                </View>

                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Image
                    source={
                      passwordValidation.hasNumber
                        ? Images.register_checked_icon
                        : Images.register_check_icon
                    }
                    resizeMode="contain"
                    style={[Layout.iconSize20, Gutters.tinyRMargin]}
                  />
                  <Text
                    style={[
                      Fonts.size14,
                      {
                        color: Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    含一個數字
                  </Text>
                </View>

                <View style={[Layout.row, Layout.alignItemsCenter]}>
                  <Image
                    source={
                      passwordValidation.hasMixedCase
                        ? Images.register_checked_icon
                        : Images.register_check_icon
                    }
                    resizeMode="contain"
                    style={[Layout.iconSize20, Gutters.tinyRMargin]}
                  />
                  <Text
                    style={[
                      Fonts.size14,
                      {
                        color: Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    一個大寫及小寫字母
                  </Text>
                </View>
              </View>
              {/* TODO: 邀請碼機制暫時隱藏（避免使用者註冊流程混亂） */}
              {/* <Text
                style={[
                  Fonts.size16,
                  Fonts.weight400,
                  Gutters.tinyBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {`輸入邀請碼${isMGMCodeRequired ? '' : '（選填）'}`}
              </Text>
              <View
                style={[
                  errorMessage?.inviteCode
                    ? Gutters.tinyBMargin
                    : Gutters.regularBMargin,
                ]}
              >
                <TextInput
                  label="邀請碼"
                  value={register.inviteCode}
                  style={[{ color: Colors.white }, styles.textInput]}
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  activeOutlineColor={Colors.primary}
                  theme={{
                    colors: {
                      onSurfaceVariant: Colors.fontText.light.primary3,
                      error: Colors.snackbar.error,
                      outline: Colors.background.top,
                    },
                    roundness: 10,
                  }}
                  textColor="#fff"
                  mode="outlined"
                  error={!!errorMessage?.inviteCode}
                  onChangeText={value =>
                    handleOnChangeInput('inviteCode', value)
                  }
                />
              </View>
              {!!errorMessage?.inviteCode && (
                <Text
                  style={[
                    Gutters.tinyBMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage?.inviteCode}
                </Text>
              )}
              {isMGMCodeRequired && (
                <TouchableOpacity
                  style={[Layout.rowCenter, Gutters.regularTMargin]}
                  onPress={() => openApplyInviteCodeWeb()}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight500,
                      {
                        color: Colors.fontText.light.primary2,
                        textDecorationLine: 'underline',
                        // @ts-ignore
                        textUnderlineOffset: 4,
                      },
                    ]}
                  >
                    我沒有邀請碼
                  </Text>
                </TouchableOpacity>
              )} */}
            </View>
          </FadeInView>
          {/* 登入按鈕 */}
          <FadeInView duration={550}>
            <View style={[Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.regularBMargin,
                  styles.buttonContainer,
                  {
                    backgroundColor: loginBtnBgColor,
                  },
                ]}
                disabled={submitButtonDisabled || signUpBasketIsLoading}
                onPress={handleSubmit}
              >
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.bold,
                    {
                      color: submitButtonDisabled
                        ? Colors.gray
                        : Colors.fontText.dark.primary2,
                    },
                  ]}
                >
                  下一步
                </Text>
              </TouchableOpacity>
            </View>
          </FadeInView>
        </View>
        {/* 註冊帳號、隱私權 */}
        <View style={styles.signInContainer}>
          <View style={[Layout.rowCenter, Gutters.smallBMargin]}>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size16,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              已經有帳號了？
            </Text>
            <TouchableOpacity
              onPress={() => navigation.navigate(PagesEnum.EmailLoginContainer)}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  {
                    color: Colors.primary,
                  },
                ]}
              >
                登入
              </Text>
            </TouchableOpacity>
          </View>
          {/* <TouchableOpacity style={[Gutters.tinyBMargin]}>
            <Text style={[Fonts.bold, { color: Colors.primary }]}>
              服務條款
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={[Gutters.tinyBMargin]}>
            <Text style={[Fonts.bold, { color: Colors.primary }]}>
              隱私權政策
            </Text>
          </TouchableOpacity> */}
        </View>
      </ScrollView>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <View style={[Gutters.smallBMargin]}>
        <Header
          title={'註冊（1/4）'}
          leftIcon={
            <Image
              // @ts-ignore
              style={styles.closeIcon}
              source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
      </View>
      {renderRegisterView()}
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    inputContainer: {
      width: '100%',
      height: 40,
      borderRadius: 32,
      borderColor: '#F2F2F2',
      borderWidth: 1,
      justifyContent: 'center',
      paddingHorizontal: 12,
    },
    buttonContainer: {
      borderRadius: 8,
      backgroundColor: Colors.primary,
      paddingVertical: 9,
      height: 48,
    },
    divider: {
      width: 90,
      height: 1,
      backgroundColor: Colors.gray,
    },
    signInContainer: {
      marginTop: 70,
      alignItems: 'center',
      width: '100%',
    },
    headerContainer: {
      width: '100%',
      paddingTop: 60,
      marginBottom: 34,
      backgroundColor: '#1E1E1E',
    },
    successContentContainer: {
      width: '100%',
      borderColor: Colors.primary,
      borderWidth: 1,
      borderRadius: 10,
      paddingVertical: 16,
    },
    inputOutline: {
      // @ts-ignore
      outlineStyle: 'none',
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    FacebookButtonContainer: {
      borderRadius: 8,
      backgroundColor: '#2D62F2',
      paddingVertical: 9,
      height: 56,
    },
    textInput: {
      backgroundColor: '#1C1C20',
      height: 56,
    },
  })

export default EmailRegisterContainer
