import React, { useMemo } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'

import { PagesEnum } from '@/Navigators/Application'
import { navigate } from '@/Navigators/utils'

import SpiritImage from './SpiritImage/SpiritImage'

type ImagePath = {
  kind: string
  url: string
  id: string
}

type Props = {
  imagePaths: ImagePath[]
}

const MAX_IMAGES = 3

const ReviewImagesComponent = ({ imagePaths }: Props) => {
  const imageWidth = useMemo(() => {
    if (imagePaths.length === 1) {
      return 160
    }
    if (imagePaths.length === 2) {
      return '49%'
    }
    return '32%'
  }, [imagePaths])

  const previewImage = useMemo(() => {
    return imagePaths.slice(0, MAX_IMAGES)
  }, [imagePaths])

  const hasOverImages = useMemo(() => {
    return imagePaths.length > MAX_IMAGES
  }, [imagePaths])

  const handleRedirectToImagesContainer = () => {
    const imagePath = imagePaths.map(image => image.url)

    navigate(PagesEnum.ImagesContainer, {
      imagePath,
    })
  }

  if (!imagePaths?.length) return null

  return (
    <View style={[styles.container]}>
      {previewImage.map((imagePath, index) => {
        const isLastImage = index === previewImage.length - 1 && hasOverImages
        const overImagesCount = imagePaths.length - MAX_IMAGES
        const isShowOverImagesCount = isLastImage && hasOverImages
        return (
          <TouchableOpacity
            key={imagePath.id}
            style={[
              styles.imageContainer,
              {
                width: imageWidth,
              },
            ]}
            onPress={handleRedirectToImagesContainer}
          >
            <SpiritImage
              style={styles.image}
              linearGradientContainerStyle={styles.image}
              imageUrl={imagePath.url}
              needSEO
            />
            {isShowOverImagesCount && (
              <View style={styles.overImagesCountContainer}>
                <Text
                  style={styles.overImagesCount}
                >{`+${overImagesCount}`}</Text>
              </View>
            )}
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 8,
  },
  imageContainer: {
    marginBottom: 8,
    width: 160,
    height: 160,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    objectFit: 'cover',
  },
  overImagesCountContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overImagesCount: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
})

export default ReviewImagesComponent
