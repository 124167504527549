import { FadeInView, LoadingComponent } from '@/Components'
import { useCategory, useTheme } from '@/Hooks'
import { PagesEnum } from '@/Navigators/Application'
import { Colors } from '@/Theme/Variables'
import React, { useCallback, useMemo } from 'react'
import {
  FlatList,
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import useGetCategoriesData from '../hooks/useGetCategoriesData'

interface CategoryContainerProps {
  navigation: any
}

const TITLE = '酒類'

const CategoryContainer = ({ navigation }: CategoryContainerProps) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const categoryData = useCategory()
  const { categoriesData, categoriesDataIsLoading } = useGetCategoriesData()
  const styles = getStyle()

  const handleClickCategoryCard = useCallback(
    ({ keys, filters }: { keys: string; filters: string }) => {
      navigation.push(PagesEnum.FilterSearchListContainer, {
        [filters]: keys,
      })
    },
    [navigation],
  )

  // 計算數量，有兩種狀態，categoryNot 是排除，category 是包含
  const convertCategoryCount = useCallback(
    (filterKey: string, categoryType: 'categoryNot' | 'category') => {
      const keysArr = filterKey.split(',')
      const totalCount = categoriesData.reduce((acc, item) => {
        return acc + item.count
      }, 0)

      let count = 0
      if (categoryType === 'categoryNot') {
        keysArr.forEach(key => {
          count +=
            categoriesData.find((item: any) => item.enName === key)?.count || 0
        })

        return totalCount - count
      }
      if (categoryType === 'category') {
        keysArr.forEach(key => {
          count +=
            categoriesData.find((item: any) => item.enName === key)?.count || 0
        })

        return count
      }
    },
    [categoriesData],
  )

  const CategoryItem = useCallback(
    (item: any) => {
      let icon
      if (item?.icon) {
        icon = item.icon
      }
      const { title, zhTitle, keys, block, filters } = item
      return (
        <TouchableOpacity
          style={[Gutters.smallBMargin, styles.categoryItemContainer]}
          onPress={() => handleClickCategoryCard({ filters, keys })}
        >
          <ImageBackground
            style={[styles.categoryItemCard]}
            source={block}
            resizeMode="cover"
          >
            <View style={styles.categoryBorder} />
            <View
              style={[
                Layout.fullWidth,
                Gutters.smallVPadding,
                Gutters.smallHPadding,
              ]}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  styles.categoryTitle,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {title}
              </Text>
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size14,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {zhTitle}
              </Text>
            </View>
            <View style={[Layout.row, styles.count]}>
              <Image
                source={Images.search_landing_bottle_icon}
                style={[Layout.iconSize14, Gutters.miniRMargin]}
              />
              <Text
                style={[
                  Fonts.weight300,
                  Fonts.size12,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {convertCategoryCount(keys, filters)}
              </Text>
            </View>
            <Image
              source={icon}
              style={styles.categoryIcon}
              resizeMode="contain"
            />
          </ImageBackground>
        </TouchableOpacity>
      )
    },
    [
      Fonts,
      Gutters,
      Images,
      Layout,
      convertCategoryCount,
      handleClickCategoryCard,
      styles,
    ],
  )

  const renderLoading = useMemo(() => {
    if (categoriesDataIsLoading) {
      return (
        <View style={[Layout.fill, Layout.rowCenter, Gutters.largeTPadding]}>
          <LoadingComponent backgroundColor="transparent" />
        </View>
      )
    }
  }, [Layout.fill, Layout.rowCenter, Gutters, categoriesDataIsLoading])

  return (
    <FadeInView duration={500} style={Layout.fill}>
      <View
        style={[Layout.fill, Gutters.regularHPadding, Gutters.smallTPadding]}
      >
        <View style={[styles.filterCardHeader]}>
          <Text
            style={[
              Fonts.weight700,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {`探索 - ${TITLE}`}
          </Text>
        </View>
        {categoriesDataIsLoading ? (
          renderLoading
        ) : (
          <FlatList
            data={categoryData}
            renderItem={({ item }) => <CategoryItem {...item} />}
            keyExtractor={item => item.id}
            numColumns={2}
            style={[Gutters.regularBMargin]}
          />
        )}
      </View>
    </FadeInView>
  )
}

const getStyle = () =>
  StyleSheet.create({
    filterCardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    articleBanner: {
      width: '100%',
      height: 180,
    },
    categoryItemContainer: {
      width: '48%',
      height: 104,
      marginRight: 16,
      borderRadius: 8,
      overflow: 'hidden',
    },
    categoryItemCard: {
      width: '100%',
      height: 104,
      flexDirection: 'row',
    },
    categoryBorder: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: 8,
    },
    categoryIcon: {
      width: 60,
      height: 60,
      position: 'absolute',
      right: 0,
      bottom: 0,
      opacity: 0.2,
    },
    count: { position: 'absolute', right: 25, bottom: 15 },
    categoryTitle: {
      lineHeight: 24,
      fontFamily: 'Poppins',
    },
    padding32: {
      padding: 32,
    },
  })

export default CategoryContainer
