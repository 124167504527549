import React, { useRef, useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import { AdvertiseItem, AdvertiseSpaceEnum } from '@/Services/advertiseApi'
import { AdvertiseComponent } from '@/Components'
import { Colors } from '@/Theme/Variables'

interface SliderCardProps {
  data: AdvertiseItem[]
  space: AdvertiseSpaceEnum
  autoPlay?: boolean
  infiniteLoop?: boolean
}

const IMAGE_SIZE = {
  width: 385,
  height: 100,
}

// workaround: 避免首頁切換 tab re-render 時，輪播圖會回到第一張
// 使用一個全局變數來存儲每個輪播的當前索引
// 使用 space 作為 key 來區分不同位置的輪播
const carouselStates = new Map<string, number>()

const AdvertiseCarousel = React.memo(
  ({ data, space, autoPlay = true, infiniteLoop = true }: SliderCardProps) => {
    // 從全局變量中獲取初始索引，如果不存在則使用 0
    const initialIndex = carouselStates.get(space) || 0
    const [sliderIndex, setSliderIndex] = useState(initialIndex)
    const [isInitialized, setIsInitialized] = useState(false)

    const containerRef = useRef<View>(null)
    const carouselRef = useRef<Carousel>(null)

    const sliderItemWidth =
      // @ts-ignore
      containerRef.current?.getBoundingClientRect().width - 48 || 382
    const sliderNumber = data?.length

    const styles = style(sliderItemWidth)

    // 當 index 變化時，更新全局變量
    useEffect(() => {
      if (isInitialized) {
        carouselStates.set(space, sliderIndex)
      }
    }, [sliderIndex, space, isInitialized])

    useEffect(() => {
      setIsInitialized(true)

      // 如果有初始 index，手動設置輪播位置
      let timeoutId: NodeJS.Timeout
      if (initialIndex > 0 && carouselRef.current) {
        // 使用 setTimeout 確保 DOM 已經 render
        timeoutId = setTimeout(() => {
          carouselRef.current?.moveTo(initialIndex)
        }, 0)
      }

      // 清除 timeout 以防止 memory leak
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    }, [initialIndex])

    // 處理索引變化
    const handleIndexChange = (index: number) => {
      setSliderIndex(index)
    }

    return (
      <>
        <View style={styles.carouselContainer}>
          <Carousel
            ref={carouselRef}
            centerSlidePercentage={100}
            showArrows={true}
            showIndicators={false}
            swipeable={true}
            emulateTouch={true}
            selectedItem={sliderIndex}
            onChange={handleIndexChange}
            width={sliderItemWidth}
            showStatus={false}
            autoPlay={autoPlay}
            infiniteLoop={infiniteLoop}
            showThumbs={false}
            stopOnHover={false}
          >
            {data?.map((item: AdvertiseItem, index: number) => {
              return (
                <View style={[styles.carouselItem]} key={`${item.id}-${index}`}>
                  <AdvertiseComponent
                    data={item}
                    space={space}
                    customImageStyle={{ borderRadius: 8 }}
                    customImageContainerStyle={{ borderRadius: 8 }}
                  />
                </View>
              )
            })}
          </Carousel>
        </View>
        <View ref={containerRef} style={[styles.sliderDotsContainer]}>
          {new Array(sliderNumber).fill(0).map((_: number, index: number) => {
            const isActive = index === sliderIndex
            return (
              <View
                key={`${index}`}
                style={[isActive ? styles.sliderDotActive : styles.sliderDot]}
              />
            )
          })}
        </View>
      </>
    )
  },
)

const style = (sliderItemWidth: number) =>
  StyleSheet.create({
    carouselContainer: {
      width: '100%',
      height: IMAGE_SIZE.height,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 24,
      marginTop: 8,
    },
    carouselItem: {
      width: sliderItemWidth,
      height: IMAGE_SIZE.height,
    },
    sliderDotsContainer: {
      width: '100%',
      height: 40,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      marginBottom: 8,
    },
    sliderDot: {
      width: 5,
      height: 5,
      borderRadius: 2.5,
      backgroundColor: '#545454',
    },
    sliderDotActive: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: Colors.primary,
    },
  })

export default AdvertiseCarousel
