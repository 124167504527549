import { api, prefixProxyEndpoint } from '@/Services/api'
import {
  CommentTemplateResponse,
  GetFollowingNotesRequest,
  GetFollowingNotesResponse,
  GetNestedSetRequest,
  GetNotesDropdownsOptionsRequest,
  GetNotesDropdownsOptionsResponse,
  GetUserNotesResponse,
  NoteCommentResponse,
  NoteDetailResponse,
  PatchCommentTemplateRequest,
  PostCommentTemplateRequest,
  PostNoteImageRequest,
  GetLatestNotesRequest,
  GetLatestNotesResponse,
  UserType,
  CommentTemplateListResponse,
} from './note.type'
import { globalActions } from '@/Store/Global'
import i18next from 'i18next'
import { spiritActions } from '@/Store/Spirit'

export const noteApi: any = api.injectEndpoints({
  endpoints: build => ({
    // 對評論點贊
    postToggleLike: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/notes/${id}:toggle-like`),
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(noteApi.util.invalidateTags(['spirits', 'note']))
        }
      },
    }),
    // 取得該 note 的資訊
    getNoteDetail: build.query<NoteDetailResponse, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/notes/${id}`),
        method: 'GET',
      }),
      providesTags: ['note'],
    }),
    // 取得該 note 的回覆資訊
    getNoteComments: build.query<
      NoteCommentResponse,
      { id: string; cursor?: string }
    >({
      query: ({ id, cursor }) => ({
        url: prefixProxyEndpoint(`/v1/notes/${id}/comments`),
        method: 'GET',
        params: { cursor, perPage: 10 },
      }),
      providesTags: ['note'],
    }),
    // 新增該 note 的回覆
    postNoteComments: build.mutation<void, { id: string; content: string }>({
      query: ({ id, ...body }) => ({
        url: prefixProxyEndpoint(`/v1/notes/${id}/comments`),
        method: 'POST',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(noteApi.util.invalidateTags(['note']))
        }
      },
    }),
    // 刪除該 note 的回覆
    deleteNoteComments: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/my-comments/${id}`),
        method: 'DELETE',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(noteApi.util.invalidateTags(['note']))
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '成功移除留言',
              type: 'default',
              showIcon: false,
            }),
          )
        }
      },
    }),
    // 取得使用者的評論文字模板列表
    getCommentTemplateList: build.query<CommentTemplateListResponse, void>({
      query: () => ({
        url: prefixProxyEndpoint('/v1/my-note-templates'),
        method: 'GET',
      }),
      providesTags: [{ type: 'note', id: 'template' }],
    }),
    // 取得使用者的評論文字模板
    getCommentTemplate: build.query<CommentTemplateResponse, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/my-note-templates/${id}`),
        method: 'GET',
      }),
      providesTags: [{ type: 'note', id: 'template' }],
    }),
    // 新增評論文字模板
    postCommentTemplate: build.mutation<void, PostCommentTemplateRequest>({
      query: ({ ...body }) => ({
        url: prefixProxyEndpoint('/v1/my-note-templates'),
        method: 'POST',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(
            noteApi.util.invalidateTags([{ type: 'note', id: 'template' }]),
          )
        }
      },
    }),
    // 更新評論文字模板
    patchCommentTemplate: build.mutation<void, PatchCommentTemplateRequest>({
      query: ({ id, ...body }) => ({
        url: prefixProxyEndpoint(`/v1/my-note-templates/${id}`),
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(
            noteApi.util.invalidateTags([{ type: 'note', id: 'template' }]),
          )
        }
      },
    }),
    // 取得筆記中，下拉選單的值
    getNoteDropdownsOptions: build.query<
      GetNotesDropdownsOptionsResponse,
      GetNotesDropdownsOptionsRequest
    >({
      query: ({ ...params }) => ({
        url: prefixProxyEndpoint('/v1/dropdowns'),
        method: 'GET',
        params,
        headers: {
          'Accept-Language': params?.locale,
        },
      }),
      async onQueryStarted(props, { queryFulfilled, getState }: any) {
        const result = await queryFulfilled
        if (result) {
          // 將取回來的 dropdowns 資料，放到 i18 中
          const { data } = result.data
          const { language } = getState()?.theme
          const { type: dropdownType } = props
          i18next.addResourceBundle(language, dropdownType, data)
        }
      },
    }),
    // 上傳圖片至筆記 (id 為筆記的 id)
    postNoteImage: build.mutation<void, PostNoteImageRequest>({
      query: data => {
        const { id, ...body } = data
        return {
          url: prefixProxyEndpoint(`/v1/my-notes/${id}:associate-image`),
          method: 'POST',
          body,
        }
      },
    }),
    // 刪除筆記圖片 (id 為筆記的 id)
    deleteNoteImage: build.mutation<void, { id: string }>({
      query: data => {
        const { id } = data
        return {
          url: prefixProxyEndpoint(`/v1/my-notes/${id}:dissociate-image`),
          method: 'POST',
        }
      },
    }),
    // 取得 nest-set 的資訊（風味輪）
    getNestedSet: build.query<any, GetNestedSetRequest>({
      query: params => ({
        url: prefixProxyEndpoint('/v1/nested-sets'),
        method: 'GET',
        params,
      }),
      async onQueryStarted(props, { queryFulfilled, getState }: any) {
        const result = await queryFulfilled
        if (result) {
          // 將取回來的 nested set 風味資料，放到 i18 中
          const { data } = result.data
          const { language } = getState()?.theme
          const { type: nestedSetsType } = props
          let flavorList = data?.roots
          for (const child of data?.children) {
            flavorList = {
              ...flavorList,
              ...child.data,
            }
          }
          i18next.addResourceBundle(language, nestedSetsType, flavorList)
        }
      },
    }),
    // 取得特定用戶的 notes
    getUserNotes: build.query<
      GetUserNotesResponse,
      { id: string; cursor?: string }
    >({
      query: ({ id, cursor }) => ({
        url: prefixProxyEndpoint(`/v1/users/${id}/notes`),
        method: 'GET',
        params: { cursor, perPage: 10 },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          const payload = {
            ...result.data,
            userId: props.id,
          }
          dispatch(spiritActions.setProfileUserNoteList(payload))
        }
      },
    }),
    // 首頁追蹤中 feed
    getFollowingNote: build.query<
      GetFollowingNotesResponse,
      GetFollowingNotesRequest
    >({
      query: params => ({
        url: prefixProxyEndpoint('v1/following-notes'),
        method: 'GET',
        params: {
          ...params,
          perPage: params?.perPage || 15,
        },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          dispatch(
            spiritActions.setHomePagePostFeedsData({
              type: 'followingNotes',
              data: result.data,
            }),
          )
        }
      },
    }),
    // 首頁最新筆記 feeds
    getLatestNote: build.query<
      GetLatestNotesResponse,
      Partial<GetLatestNotesRequest>
    >({
      query: params => ({
        url: prefixProxyEndpoint('v1/notes'),
        method: 'GET',
        params: {
          ...params,
          perPage: params?.perPage || 15,
        },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 如果是達人筆記，就放到達人筆記的 state
          if (props.userType === UserType.EXPERT) {
            dispatch(
              spiritActions.setHomePagePostFeedsData({
                type: 'expert_notes',
                data: result.data,
              }),
            )
          } else {
            // 因為是列表資料，所以先存到 store 裏面
            dispatch(
              spiritActions.setHomePagePostFeedsData({
                type: 'latest_notes',
                data: result.data,
              }),
            )
          }
        }
      },
    }),
  }),
  overrideExisting: false,
})

noteApi.enhanceEndpoints({
  addTagTypes: ['note'],
  endpoints: {},
})

export default noteApi
