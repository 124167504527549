import ReactGA from 'react-ga4'

import { api, prefixProxyEndpoint, TagTypes } from '@/Services/api'
import {
  SignInRequest,
  Token,
  GetMeResponse,
  SocialLoginRequest,
  SignUpRequest,
  SignUpResponse,
  GetOTPCodeRequest,
  SignUpBasketResponse,
  SignUpBasketRequest,
  GetInviteFriendListResponse,
  GetInviteFriendListRequest,
  GetUserDataResponse,
  PatchMeRequest,
  GetUserFollowRequest,
  GetUserFollowResponse,
  GetRecommendationFeedsResponse,
  GetRecommendationFeedsRequest,
  UploadFileResponse,
  UploadFileRequest,
  GetSettingsResponse,
  PatchSettingsRequest,
} from './user.type'
import { userActions } from '@/Store/User'
import { spiritActions } from '@/Store/Spirit'
import { authActions } from '@/Store/Auth'
import { handlePendingRedirect, navigateToHome } from '@/Navigators/utils'
import { getConfig } from '@/Util/global'
import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

const SETTING_TAG: FullTagDescription<TagTypes> = {
  type: 'userInfo',
  id: 'settings',
}

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    fetchOne: build.query<any, string>({
      query: id => `/users/${id}`,
    }),
    signIn: build.mutation<Token, SignInRequest>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/tokens'),
        method: 'POST',
        // TODO: 因為 SPA 先不用 token，透過 cookie 來驗證，所以先註解掉，等到改回 mobile 拿掉
        headers: {
          'X-Requested-With': 'LeadBest-SPA',
        },
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result.data) {
          dispatch(userApi.util.invalidateTags(['userInfo']))
          dispatch(authActions.setAuth())
          if (!handlePendingRedirect()) {
            navigateToHome()
          }
        }
      },
    }),
    getMe: build.query<GetMeResponse, void>({
      query: () => ({
        url: prefixProxyEndpoint('/v1/me'),
      }),
      providesTags: ['userInfo'],
      transformResponse: (response: { data: GetMeResponse }) => response.data,
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result.data) {
          dispatch(authActions.setAuth())
          if (['beta', 'gamma', 'production'].includes(getConfig().ENV)) {
            ReactGA.set({ userId: result.data.id.toString() })
          }
        }
      },
    }),
    signInWithSocial: build.mutation<Token, SocialLoginRequest>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/tokens:with-social-login'),
        method: 'POST',
        // TODO: 因為 SPA 先不用 token，透過 cookie 來驗證，所以先註解掉，等到改回 mobile 拿掉
        headers: {
          'X-Requested-With': 'LeadBest-SPA',
        },
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result.data) {
          dispatch(userApi.util.invalidateTags(['userInfo']))
          dispatch(authActions.setAuth())
          if (!handlePendingRedirect()) {
            navigateToHome()
          }
        }
      },
    }),
    // 登出
    deleteLogout: build.mutation<void, { token: string }>({
      query: ({ token }) => ({
        url: prefixProxyEndpoint(`/v1/tokens/${token}`),
        method: 'DELETE',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userApi.util.invalidateTags(['userInfo']))
          // 登出時清除 store 資料
          dispatch(spiritActions.resetSpiritStore())
          dispatch(userActions.resetUserStore())
          dispatch(authActions.clearAuth())
          if (['beta', 'gamma', 'production'].includes(getConfig().ENV)) {
            ReactGA.set({ userId: '' })
          }
        }
      },
    }),
    // 註冊
    signUp: build.mutation<SignUpResponse, SignUpRequest>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/users'),
        method: 'POST',
        body,
        // TODO: 因為 SPA 先不用 token，透過 cookie 來驗證，所以先註解掉，等到改回 mobile 拿掉
        headers: {
          'X-Requested-With': 'LeadBest-SPA',
        },
      }),
    }),
    // 取得 otp code
    getOtpCode: build.mutation<void, GetOTPCodeRequest>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/users:send-verification'),
        method: 'POST',
        body,
      }),
    }),
    // 註冊 basket 流程
    signUpBasket: build.mutation<
      SignUpBasketResponse,
      Partial<SignUpBasketRequest>
    >({
      query: body => ({
        url: prefixProxyEndpoint('/v1/register-baskets:upsert'),
        method: 'POST',
        body,
      }),
    }),
    // 重設密碼
    resetPassword: build.mutation<
      void,
      { account: string; password: string; code: string }
    >({
      query: body => ({
        url: prefixProxyEndpoint('/v1/users:reset-password'),
        method: 'POST',
        body,
      }),
    }),
    // 取得個人邀請好友的列表
    getInviteFriendList: build.query<
      GetInviteFriendListResponse,
      Partial<GetInviteFriendListRequest>
    >({
      query: params => ({
        url: prefixProxyEndpoint('/v1/my-invitees'),
        method: 'GET',
        params,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userActions.setInviteFriendList(result.data))
        }
      },
    }),
    // 取得 user 資訊（自己與別人）
    getUserData: build.query<GetUserDataResponse, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/users/${id}`),
        method: 'GET',
      }),
      providesTags: ['userInfo'],
    }),
    // 更新自己的 user profile 資訊
    patchUserData: build.mutation<void, Partial<PatchMeRequest>>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/me'),
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userApi.util.invalidateTags(['userInfo']))
        }
      },
    }),
    // 刪除自己帳號
    deleteAccount: build.mutation<void, void>({
      query: () => ({
        url: prefixProxyEndpoint('/v1/me:request-deletion'),
        method: 'POST',
      }),
    }),
    // 取得 follower
    getUserFollowers: build.query<
      GetUserFollowResponse,
      Partial<GetUserFollowRequest>
    >({
      query: ({ id, ...params }) => ({
        url: prefixProxyEndpoint(`/v1/users/${id}/followers`),
        method: 'GET',
        params: {
          ...params,
          perPage: params.perPage || 10,
        },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userActions.setFollowers(result.data))
        }
      },
    }),
    // 取得 followings
    getUserFollowings: build.query<
      GetUserFollowResponse,
      Partial<GetUserFollowRequest>
    >({
      query: ({ id, ...params }) => ({
        url: prefixProxyEndpoint(`/v1/users/${id}/followings`),
        method: 'GET',
        params: {
          ...params,
          perPage: params.perPage || 10,
        },
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userActions.setFollowings(result.data))
        }
      },
    }),
    // 追蹤或是退追
    postFollowUser: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/users/${id}:toggle-following`),
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userApi.util.invalidateTags(['userInfo']))

          dispatch(userActions.toggleFollow({ id: props?.id }))
        }
      },
    }),
    // 首頁下方熱門的酒、最新筆記
    getRecommendationFeeds: build.query<
      GetRecommendationFeedsResponse,
      GetRecommendationFeedsRequest
    >({
      query: ({ x_feed_version, ...params }) => ({
        url: prefixProxyEndpoint('/v1/recommendation-feeds'),
        method: 'GET',
        params: {
          ...params,
          perPage: params.perPage || 15,
        },
        headers: {
          'X-Feed-Version': x_feed_version,
        },
      }),
      transformResponse: (
        response: GetRecommendationFeedsResponse,
        meta: any,
      ) => {
        return {
          ...response,
          x_feed_version: meta?.response?.headers.get('X-Feed-Version'),
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          dispatch(
            spiritActions.setHomePagePostFeedsData({
              type: props?.type,
              data: result.data,
            }),
          )
        }
      },
    }),
    // 上傳檔案
    uploadFile: build.mutation<UploadFileResponse, UploadFileRequest>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/upload-urls'),
        method: 'POST',
        body,
      }),
    }),
    // 取得設定
    getSettings: build.query<GetSettingsResponse, void>({
      query: () => ({
        url: prefixProxyEndpoint('/v1/settings'),
        method: 'GET',
      }),
      providesTags: [SETTING_TAG],
    }),
    // 更新設定
    putSettings: build.mutation<void, Array<PatchSettingsRequest>>({
      query: body => ({
        url: prefixProxyEndpoint('/v1/settings'),
        method: 'PUT',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(userApi.util.invalidateTags([SETTING_TAG]))
        }
      },
    }),
  }),
  overrideExisting: false,
})

userApi.enhanceEndpoints({
  addTagTypes: ['userInfo'],
  endpoints: {},
})

export default userApi
