import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, TouchableOpacity, Image, Text, StyleSheet } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { capitalizeFirstLetter } from '@/Util/global'

interface FlavorMainCardProps {
  data: any
  selectedFlavorList: string[]
  handleGetFlavorColor: (key: string) => string
  handleSelectFlavorItem: (key: string) => void
  handleRemoveSelectFlavorItem: (key: string) => void
  Images: any
}

const FlavorSelectCard = ({
  data,
  selectedFlavorList,
  handleGetFlavorColor,
  handleSelectFlavorItem,
  handleRemoveSelectFlavorItem,
  Images,
}: FlavorMainCardProps) => {
  const { Layout, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const styles = getStyle()

  return (
    <View style={styles.flavorMainCard}>
      <LinearGradient
        style={styles.linearGradientContainer}
        colors={data.linearGradient}
        end={{ x: 90, y: 80 }}
      >
        <Text
          style={[
            Fonts.size20,
            Fonts.weight500,
            Gutters.miniRMargin,
            styles.flavorTitle,
            {
              color: Colors.white,
            },
          ]}
        >
          {capitalizeFirstLetter(data?.enName)}
        </Text>
        <Text
          style={[
            Fonts.size14,
            Fonts.weight400,
            {
              color: Colors.white,
            },
          ]}
        >
          {`${data?.zhName}`}
        </Text>
        <Image
          source={Images[data.icon]}
          resizeMode="contain"
          style={styles.dataIcon}
        />
      </LinearGradient>
      <View
        style={[Gutters.smallVMargin, Layout.row, styles.flavorItemContainer]}
      >
        {data.flavor.map((flavor: any) => {
          const isSelected = selectedFlavorList?.includes(flavor.key)
          const color = handleGetFlavorColor(flavor?.key)
          return (
            <TouchableOpacity
              key={flavor.key}
              style={[
                Layout.row,
                Layout.alignItemsCenter,
                styles.flavorItem,
                {
                  borderWidth: isSelected ? 1 : 0,
                  borderColor: isSelected ? Colors.primary : Colors.transparent,
                },
              ]}
              onPress={() => {
                if (isSelected) {
                  handleRemoveSelectFlavorItem(flavor.key)
                } else {
                  handleSelectFlavorItem(flavor.key)
                }
              }}
            >
              <View
                style={[
                  Gutters.miniRMargin,
                  styles.flavorDot,
                  { backgroundColor: color },
                ]}
              />
              <Text
                style={[
                  Fonts.bold,
                  Fonts.size14,
                  {
                    color: Colors.fontText.light.primary2,
                  },
                ]}
              >
                {t(flavor.i18n)}
              </Text>
              <Image
                source={
                  isSelected ? Images.close : Images.rating_comment_add_flavor
                }
                resizeMode="contain"
                style={isSelected ? Layout.iconSize14 : Layout.iconSize20}
              />
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    flavorMainCard: {
      width: '100%',
      borderRadius: 10,
      borderWidth: 1,
      borderColor: Colors.background.top,
      marginBottom: 16,
    },
    flavorTitle: {
      lineHeight: 24,
      fontFamily: 'Poppins',
    },
    linearGradientContainer: {
      width: '100%',
      height: 48,
      padding: 16,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    dataIcon: {
      width: 48,
      height: 48,
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    flavorItemContainer: {
      flexWrap: 'wrap',
    },
    flavorItem: {
      marginLeft: 16,
      marginBottom: 16,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 80,
      backgroundColor: Colors.darkLight,
    },
    flavorDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
  })

export default FlavorSelectCard
