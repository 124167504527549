import React, { useCallback, useEffect } from 'react'
import {
  View,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
} from 'react-native'
import Barcode from 'react-barcode'
import QRCode from 'react-qr-code'
import { useDispatch } from 'react-redux'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header, WarningSignComponent } from '@/Components'
import { navigate } from '@/Navigators/utils'
import { PagesEnum } from '@/Navigators/Application'
import loyaltyProgramsApi from '@/Services/modules/loyaltyPrograms'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { globalActions } from '@/Store/Global'

const CouponRedeemContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()
  const dispatch = useDispatch()

  const { couponName } = route.params
  const [
    ,
    { isError: isRedeemError, reset: resetRedeem, data: redeemCouponData },
  ] = loyaltyProgramsApi.useRedeemCouponMutation({
    fixedCacheKey: 'shared-redeem-coupon',
  })

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  useEffect(() => {
    if (isRedeemError) {
      navigation.navigate(AuthPagesEnum.CouponResultContainer, {
        type: 'failed',
      })
      resetRedeem()
    }
  }, [isRedeemError, navigation, resetRedeem])

  const renderCTAButton = () => {
    return (
      <TouchableOpacity
        style={[
          styles.ctaButton,
          {
            backgroundColor: Colors.primary,
          },
        ]}
        onPress={() => {
          navigation.navigate(AuthPagesEnum.CouponResultContainer, {
            type: 'success',
          })
          resetRedeem()
          dispatch(globalActions.closeBottomDialog())
        }}
      >
        <Text
          style={[
            Fonts.size16,
            Fonts.weight500,
            { color: Colors.fontText.dark.primary2 },
          ]}
        >
          兌領完成
        </Text>
      </TouchableOpacity>
    )
  }

  // TODO: 需要處理 type 為 null 的問題
  const renderCode = (type: 'barcode' | 'qrcode', value: string) => {
    return (
      <View style={[Layout.center]}>
        {type === 'barcode' ? (
          <View style={[Layout.center, Layout.fullWidth]}>
            <Barcode value={value} width={3} height={100} />
            <Text
              style={[
                Fonts.size12,
                Fonts.weight400,
                Gutters.regularBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {value}
            </Text>
          </View>
        ) : (
          <View style={[Layout.center, { backgroundColor: 'white' }]}>
            <QRCode bgColor={'#FFFFFF'} value={value} />
          </View>
        )}
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'優惠兌領'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View
          style={[Layout.fill, Gutters.regularHPadding, Gutters.largeTPadding]}
        >
          <Text
            style={[
              Fonts.size20,
              Fonts.weight700,
              Fonts.textCenter,
              Gutters.regularBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            掃碼核銷
          </Text>
          <View
            style={[
              Gutters.smallBMargin,
              Gutters.smallVPadding,
              Gutters.smallHPadding,
              { backgroundColor: Colors.background.surface, borderRadius: 8 },
            ]}
          >
            <View style={[Layout.row, Layout.justifyContentBetween]}>
              <Text
                style={[
                  Fonts.size20,
                  Fonts.weight500,
                  {
                    color: Colors.fontText.light.primary2,
                  },
                ]}
              >
                {couponName}
              </Text>
              <Image
                source={Images.coupon_list_icon}
                style={[Gutters.regularLMargin, Layout.iconSize56]}
                resizeMode="contain"
              />
            </View>
          </View>
          {renderCode(
            redeemCouponData?.data.vendorData.displayFormat as
              | 'barcode'
              | 'qrcode',
            redeemCouponData?.data.vendorData.content || '',
          )}
          {renderCTAButton()}
        </View>
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    ctaButtonContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    ctaButton: {
      marginTop: 'auto',
      backgroundColor: Colors.primary,
      borderRadius: 8,
      padding: 16,
      alignItems: 'center',
      marginBottom: 16,
    },
  })

export default CouponRedeemContainer
