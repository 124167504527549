import { Linking } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

// 邏輯：
// 使用者從廣告或是其他地方，點擊帶有 KOL 的連結，連結會帶有 referral 參數
// 使用者進入 APP 後，會檢查是否有 referral 參數，如果有則將其儲存到 AsyncStorage
// 如果沒有則不進行任何操作
// 當使用者兌換 coupon 時，會檢查 AsyncStorage 是否有 referral 參數
// 如果在三十分鐘內就將 referral code 帶入 api 呼叫
// 如果超過三十分鐘就清除 referral code 並且不帶入 api 呼叫

interface ReferralData {
  referralCode: string
  createdAt: number
}

const REFERRAL_PARAMS = 'referral'
const REFERRAL_DATA_STORAGE_KEY = 'referralData'
const REFERRAL_CODE_EXPIRATION_TIME = 1000 * 60 * 30 // 30 minutes

const getReferralCode = async () => {
  try {
    const url = await Linking.getInitialURL()
    if (url) {
      const urlObj = new URL(url)
      const referralCode = urlObj.searchParams.get(REFERRAL_PARAMS)
      if (referralCode) {
        const nowTime = new Date().getTime()
        const referralData: ReferralData = {
          referralCode,
          createdAt: nowTime,
        }
        await AsyncStorage.setItem(
          REFERRAL_DATA_STORAGE_KEY,
          JSON.stringify(referralData),
        )

        if (typeof window !== 'undefined') {
          // @ts-ignore
          const newUrl = new URL(window.location.href)
          newUrl.searchParams.delete(REFERRAL_PARAMS)

          // @ts-ignore
          window.history.replaceState({}, '', newUrl.toString())
        }
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}

const validateReferralCode = async (): Promise<string> => {
  try {
    const referralData = await AsyncStorage.getItem(REFERRAL_DATA_STORAGE_KEY)
    if (!referralData) {
      return ''
    }

    const referralDataObj = JSON.parse(referralData) as ReferralData
    const nowTime = new Date().getTime()
    const isValid =
      nowTime - referralDataObj.createdAt < REFERRAL_CODE_EXPIRATION_TIME

    // 如果無效了清除存儲的推薦碼
    if (!isValid) {
      await clearReferralCode()
    }

    return isValid ? referralDataObj.referralCode : ''
  } catch (error) {
    console.error('Error validating referral code:', error)
    return ''
  }
}

const clearReferralCode = async () => {
  try {
    await AsyncStorage.removeItem(REFERRAL_DATA_STORAGE_KEY)
  } catch (error) {
    console.log('error', error)
  }
}

export const couponReferral = {
  getReferralCode,
  validateReferralCode,
  clearReferralCode,
}
