import { StyleSheet } from 'react-native'
import { ThemeVariables } from './theme'

export default function ({ }: ThemeVariables) {
  return StyleSheet.create({
    /* Column Layouts */
    column: {
      flexDirection: 'column',
    },
    columnReverse: {
      flexDirection: 'column-reverse',
    },
    colCenter: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    colVCenter: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    colHCenter: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    /* Row Layouts */
    row: {
      flexDirection: 'row',
    },
    rowReverse: {
      flexDirection: 'row-reverse',
    },
    rowCenter: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rowVCenter: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    rowHCenter: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    /* Default Layouts */
    center: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    alignItemsStart: {
      alignItems: 'flex-start',
    },
    alignItemsStretch: {
      alignItems: 'stretch',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    justifyContentAround: {
      justifyContent: 'space-around',
    },
    justifyContentBetween: {
      justifyContent: 'space-between',
    },
    scrollSpaceAround: {
      flexGrow: 1,
      justifyContent: 'space-around',
    },
    scrollSpaceBetween: {
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    selfStretch: {
      alignSelf: 'stretch',
    },
    flexWrap: {
      flexWrap: 'wrap',
    },
    /* Sizes Layouts */
    fill: {
      flex: 1,
    },
    fullSize: {
      height: '100%',
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    fullHeight: {
      height: '100%',
    },
    /* Operation Layout */
    mirror: {
      transform: [{ scaleX: -1 }],
    },
    rotate90: {
      transform: [{ rotate: '90deg' }],
    },
    rotate90Inverse: {
      transform: [{ rotate: '-90deg' }],
    },
    // icon size
    iconSize14: {
      width: 14,
      height: 14,
    },
    iconSize16: {
      width: 16,
      height: 16,
    },
    iconSize20: {
      width: 20,
      height: 20,
    },
    iconSize24: {
      width: 24,
      height: 24,
    },
    iconSize26: {
      width: 26,
      height: 26,
    },
    iconSize28: {
      width: 28,
      height: 28,
    },
    iconSize32: {
      width: 32,
      height: 32,
    },
    iconSize40: {
      width: 40,
      height: 40,
    },
    iconSize46: {
      width: 46,
      height: 46,
    },
    iconSize48: {
      width: 48,
      height: 48,
    },
    iconSize50: {
      width: 50,
      height: 50,
    },
    iconSize56: {
      width: 56,
      height: 56,
    },
    iconSize60: {
      width: 60,
      height: 60,
    },
    iconSize64: {
      width: 64,
      height: 64,
    },
    iconSize80: {
      width: 80,
      height: 80,
    },
    iconSize96: {
      width: 96,
      height: 96,
    },
    iconSize100: {
      width: 100,
      height: 100,
    },
    iconSize120: {
      width: 120,
      height: 120,
    },
    iconSize150: {
      width: 150,
      height: 150,
    },
    iconSize160: {
      width: 160,
      height: 160,
    },
    iconSize180: {
      width: 180,
      height: 180,
    },
  })
}
