import React, { useCallback } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  FlatList,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header, ProgressBar } from '@/Components'
import { getGlobal } from '@/Store'
import { useSelector } from 'react-redux'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { navigate } from '@/Navigators/utils'
import achievementsApi from '@/Services/modules/achievements'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'

// 如果沒有 100% 則隱藏（拿 api 中的 group 來比對）
const HIDDEN_ACHIEVEMENT_LIST = ['骨灰級玩家', '品酒職人']

const AchievementContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState

  const { data: getMyAchievementsData, isLoading: getMyAchievementsIsLoading } =
    achievementsApi.useGetMyAchievementsQuery()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const handleClickCard = async (group: string) => {
    navigation.navigate(AuthPagesEnum.AchievementSingleModal, {
      group,
    })
  }

  const renderListLoading = () => {
    return (
      <View>
        {getMyAchievementsIsLoading && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    )
  }

  // 如果沒有 inProgress 都是 false 代表全部都完成了，即可找最後一筆 data?.at(-1)
  const handleGetInProgressAchievements = (data: any[]) => {
    const inProgressData =
      data?.filter(item => item?.inProgress)[0] || data?.at(-1)
    const isInProgress = inProgressData?.progress !== 0 || false

    return { inProgressData, isInProgress }
  }

  const convertProgressText = (progress: number) => {
    if (progress === 0) return '0%'
    if (progress === 100) return '已達成'
    return `${progress}%`
  }

  const renderCardListItem = ({ item }: any) => {
    const { inProgressData, isInProgress } = handleGetInProgressAchievements(
      item?.data,
    )

    if (
      HIDDEN_ACHIEVEMENT_LIST.includes(item?.group) &&
      item.data?.[0]?.progress !== 100
    ) {
      return null
    }

    return (
      <TouchableOpacity
        onPress={() => handleClickCard(item?.group)}
        style={[
          Layout.fullWidth,
          Layout.row,
          Gutters.largeBMargin,
          Gutters.regularHPadding,
          Layout.alignItemsCenter,
        ]}
      >
        <Image
          source={{ uri: inProgressData?.imageUrl }}
          resizeMode="contain"
          style={[
            Gutters.smallRMargin,
            { width: 70, height: 120, opacity: isInProgress ? 1 : 0.2 },
          ]}
        />
        <View style={[{ width: '75%' }]}>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {item?.group}
          </Text>
          <View
            style={[
              Layout.row,
              Layout.justifyContentBetween,
              Layout.fullWidth,
              Gutters.smallBMargin,
            ]}
          >
            <Text
              style={[
                Fonts.size16,
                Fonts.weight700,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {inProgressData?.name}
            </Text>
            <Image
              source={{ uri: Images.arrowRight }}
              resizeMode="contain"
              style={[Layout.iconSize24]}
            />
          </View>
          <ProgressBar
            progress={inProgressData?.progress}
            text={convertProgressText(inProgressData?.progress)}
            completeTextColor={Colors.primary}
          />
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'成就徽章'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          {getMyAchievementsIsLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View style={[Layout.fill]}>
              <FlatList
                contentContainerStyle={[Layout.fill]}
                data={getMyAchievementsData?.data || []}
                keyExtractor={item => item?.group?.toString()}
                renderItem={renderCardListItem}
                refreshing
                scrollEnabled={!productImageLightBox?.visible}
                onEndReachedThreshold={3}
                getItemLayout={(data, index) => {
                  // SpiritCard_HEIGHT 是每個 SpiritCard 的高度，必免返回此頁面時，位置跑掉
                  return {
                    length: SpiritCardHeight,
                    offset: SpiritCardHeight * index,
                    index,
                  }
                }}
                ListFooterComponent={renderListLoading}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default AchievementContainer
