import React, { useCallback, useEffect, useMemo } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  FlatList,
  Dimensions,
  Linking,
  StyleSheet,
} from 'react-native'
import { useFullScreen, useShare, useTheme } from '@/Hooks'
import {
  SpiritCardWithContent,
  SpiritCard,
  WarningSignComponent,
} from '@/Components'
import dayjs from 'dayjs'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Appbar, Avatar } from 'react-native-paper'
import achievementsApi from '@/Services/modules/achievements'
import noteApi from '@/Services/modules/note'
import { RootState, getGlobal } from '@/Store'
import { NestedSetType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'
import { spiritActions } from '@/Store/Spirit'
import { Helmet } from 'react-helmet-async'
import { getConfig } from '@/Util/global'
import { globalActions } from '@/Store/Global'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { useFocusEffect } from '@react-navigation/native'

type SocialAccount =
  | 'facebook'
  | 'youtube'
  | 'instagram'
  | 'threads'
  | 'website'

const ProfileMainContainer = ({ navigation, route }: any) => {
  const { Images, Layout, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFullScreen = useFullScreen()
  const globalState = useSelector(getGlobal)
  const styles = stylesSheet()
  const { productImageLightBox } = globalState
  const { language } = useSelector((state: RootState) => state.theme)
  const {
    profileUserNoteList: profileUserNoteListData,
    profileUserWishlistList: profileUserWishlistListData,
    addRatingLoading: updateNoteLoading,
  } = useSelector((state: RootState) => state.spirit)
  const NOT_EXIST_USER = route?.params?.id === 'undefined'

  const { data: getMeData, isLoading: getMeIsLoading } = userApi.useGetMeQuery()
  const isMyProfile = getMeData?.id === route?.params?.id

  const { data: getUserData, isLoading: getUserDataIsLoading } =
    userApi.useGetUserDataQuery(
      {
        id: route?.params?.id,
      },
      { skip: NOT_EXIST_USER },
    )

  const [postFollowUserRequest] = userApi.usePostFollowUserMutation()

  // 取得風味資訊
  noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const {
    data: getUserAchievementData,
    isSuccess: getUserAchievementIsSuccess,
  } = achievementsApi.useGetUserAchievementsQuery(
    {
      id: route?.params?.id,
    },
    { skip: NOT_EXIST_USER },
  )

  const [
    getUserNotesRequest,
    {
      isLoading: getUserNotesDataIsLoading,
      isFetching: getUserNotesDataIsFetching,
    },
  ] = noteApi.useLazyGetUserNotesQuery()

  const [getUserWishlistRequest] = spiritsApi.useLazyGetUserWishlistDataQuery()

  const [activeTab, setActiveTab] = React.useState<'note' | 'wishlist'>('note')
  const [wishlistPage, setWishlistPage] = React.useState(1)

  const shareData = useShare({
    url: `${getConfig()?.DOMAIN}/profile/${route?.params?.id}`,
    title: '',
    text: '',
    snackbarContent: '成功複製分享連結！',
  })

  useFocusEffect(
    useCallback(() => {
      if (NOT_EXIST_USER) {
        return
      }

      // 重置狀態
      setActiveTab('note')

      getUserNotesRequest({
        id: route?.params?.id,
      })

      getUserWishlistRequest({
        userId: route?.params?.id,
        collectId: 'default',
        page: 1,
      })

      return () => {
        dispatch(spiritActions.clearProfileUserNoteList())
      }
    }, [
      NOT_EXIST_USER,
      getUserNotesRequest,
      route?.params?.id,
      getUserWishlistRequest,
      dispatch,
    ]),
  )

  // 切換 tab 進行的動作 note: 清空願望清單, wishlist: 重新取得願望清單
  useEffect(() => {
    if (activeTab === 'note') {
      dispatch(spiritActions.removeProfileUserWishlistUnCollected())
      dispatch(spiritActions.clearProfileUserWishlistList())
    }
    if (activeTab === 'wishlist') {
      getUserWishlistRequest({
        userId: route?.params?.id,
        collectId: 'default',
        page: 1,
      })
      setWishlistPage(0)
    }

    return () => setWishlistPage(0)
  }, [dispatch, activeTab, route?.params?.id, getUserWishlistRequest])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigation.navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const handleClickCard = useCallback(
    async (id: string, titleUrlSlug: string) => {
      navigation.navigate(PagesEnum.ProductDetail, {
        lang: language,
        titleUrlSlug: titleUrlSlug,
        id,
      })
    },
    [language, navigation],
  )

  // 將使用者的成就徽章按照取得的時間排序（前五筆）
  const convertUserAchievement = useMemo(() => {
    if (getUserAchievementIsSuccess) {
      return getUserAchievementData?.data
        ?.slice(0, 5)
        ?.sort(
          (a, b) => dayjs(b.finishedAt).unix() - dayjs(a.finishedAt).unix(),
        )
    }
    return []
  }, [getUserAchievementData, getUserAchievementIsSuccess])

  const handleFetchMoreData = useCallback(() => {
    // 筆記 tab
    if (activeTab === 'note' && profileUserNoteListData?.meta?.nextCursor) {
      getUserNotesRequest({
        id: route?.params?.id,
        cursor: profileUserNoteListData?.meta?.nextCursor,
      })
    }

    // 願望清單 tab
    if (
      activeTab === 'wishlist' &&
      profileUserWishlistListData?.meta?.currentPage &&
      wishlistPage < profileUserWishlistListData?.meta?.totalPages
    ) {
      const nextPage = profileUserWishlistListData?.meta?.currentPage + 1

      getUserWishlistRequest({
        userId: route?.params?.id,
        collectId: 'default',
        page: nextPage,
      })
      setWishlistPage(wishlistPage + 1)
    }
  }, [
    activeTab,
    profileUserNoteListData?.meta?.nextCursor,
    profileUserWishlistListData?.meta?.currentPage,
    profileUserWishlistListData?.meta?.totalPages,
    getUserNotesRequest,
    wishlistPage,
    route?.params?.id,
    getUserWishlistRequest,
  ])

  // SEO Metadata
  const renderSEOMetadata = () => {
    const og_url = `${getConfig()?.DOMAIN}/profile/${route?.params?.id}`

    const titleString = `${getUserData?.data?.name}的品飲筆記 | 烈酒探索家`

    const descriptionString =
      '在烈酒探索家上發現新的烈酒、輕鬆入門品飲筆記品酒技巧'

    return (
      <Helmet>
        <meta name="title" content={titleString} />
        <meta name="description" content={descriptionString} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:url" content={og_url} />
        <meta property="og:locale" content={getMeData?.locale || 'zh'} />
        <meta
          property="og:image"
          content={`${getConfig()?.DOMAIN}/assets/noteMetaDataImage.png`}
        />
      </Helmet>
    )
  }

  const renderListLoading = useCallback(() => {
    return (
      <View>
        {getUserNotesDataIsFetching &&
          profileUserNoteListData?.data?.length > 0 && (
            <ActivityIndicator size="large" color={Colors.primary} />
          )}
      </View>
    )
  }, [getUserNotesDataIsFetching, profileUserNoteListData?.data?.length])

  const renderConvertUserFollowNum = (num: number) => {
    if (num >= 10000) {
      return `${(num / 10000).toFixed(1)}萬`
    }
    return num
  }

  const renderSocialAccount = useCallback(() => {
    const checkSocialAccount = (socialAccount: SocialAccount) => {
      return !!getUserData?.data?.socialLinks[socialAccount]
    }

    const handleClickSocialAccount = (socialAccount: SocialAccount) => {
      if (
        checkSocialAccount(socialAccount) &&
        getUserData?.data?.socialLinks[socialAccount]
      ) {
        Linking.openURL(getUserData?.data?.socialLinks[socialAccount])
      }
    }

    return (
      <View style={[Layout.row, Gutters.smallBMargin, Gutters.tinyTMargin]}>
        {checkSocialAccount('facebook') && (
          <TouchableOpacity
            style={[Gutters.smallRMargin]}
            onPress={() => handleClickSocialAccount('facebook')}
          >
            <Image
              source={Images.profile_main_facebook_icon}
              resizeMode="contain"
              style={[Layout.iconSize24]}
            />
          </TouchableOpacity>
        )}
        {checkSocialAccount('instagram') && (
          <TouchableOpacity
            style={[Gutters.smallRMargin]}
            onPress={() => handleClickSocialAccount('instagram')}
          >
            <Image
              source={Images.profile_main_instagram_icon}
              resizeMode="contain"
              style={[Layout.iconSize24]}
            />
          </TouchableOpacity>
        )}
        {checkSocialAccount('threads') && (
          <TouchableOpacity
            style={[Gutters.smallRMargin]}
            onPress={() => handleClickSocialAccount('threads')}
          >
            <Image
              source={Images.profile_main_threads_icon}
              resizeMode="contain"
              style={[Layout.iconSize24]}
            />
          </TouchableOpacity>
        )}
        {checkSocialAccount('youtube') && (
          <TouchableOpacity
            style={[Gutters.smallRMargin]}
            onPress={() => handleClickSocialAccount('youtube')}
          >
            <Image
              source={Images.profile_main_youtube_icon}
              resizeMode="contain"
              style={[Layout.iconSize24]}
            />
          </TouchableOpacity>
        )}
        {checkSocialAccount('website') && (
          <TouchableOpacity
            style={[Gutters.smallRMargin]}
            onPress={() => handleClickSocialAccount('website')}
          >
            <Image
              source={Images.profile_main_website_icon}
              resizeMode="contain"
              style={[Layout.iconSize24]}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }, [
    Layout.row,
    Layout.iconSize24,
    Gutters,
    Images,
    getUserData?.data?.socialLinks,
  ])

  // 點擊追蹤者或追蹤中
  const handleClickFollow = useCallback(
    (type: string) => {
      navigation.navigate(PagesEnum.FollowerContainer, {
        id: route?.params?.id,
        type,
      })
    },
    [navigation, route?.params?.id],
  )

  // 確定要取消追蹤 dialog
  const openUnFollowDialog = useCallback(() => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        disabledBackgroundClose: false,
        content: (
          <View
            style={[
              Layout.rowCenter,
              Gutters.regularVPadding,
              Gutters.regularHPadding,
            ]}
          >
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                {
                  borderRadius: BorderRadius.radius8,
                  borderWidth: 1,
                  borderColor: Colors.background.top,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                postFollowUserRequest({ id: route?.params?.id })
              }}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                取消追蹤
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }, [
    Fonts,
    Gutters,
    Layout,
    dispatch,
    postFollowUserRequest,
    route?.params?.id,
  ])

  // 追隨或取消追嘴按鈕
  const handleFollowBtn = useCallback(() => {
    if (!getUserData?.data?.followed) {
      postFollowUserRequest({ id: route?.params?.id })
      return
    }
    // 如果要取消追蹤需要開啟彈窗
    openUnFollowDialog()
  }, [
    getUserData?.data?.followed,
    openUnFollowDialog,
    postFollowUserRequest,
    route?.params?.id,
  ])

  // flatList header
  const renderHeader = useCallback(() => {
    return (
      <>
        <View style={[Gutters.smallVPadding, Gutters.smallHPadding]}>
          <View
            style={[
              Layout.row,
              Layout.justifyContentBetween,
              Layout.alignItemsCenter,
            ]}
          >
            <Avatar.Image
              source={{
                uri: getUserData?.data?.avatar || Images.profile_profile,
              }}
              style={[Gutters.smallBMargin, Layout.iconSize56]}
              size={56}
            />
            <View
              style={[
                Layout.row,
                Layout.justifyContentBetween,
                Layout.alignItemsCenter,
                Gutters.regularRPadding,
                { width: 130 },
              ]}
            >
              <TouchableOpacity
                style={[Layout.colCenter]}
                onPress={() => handleClickFollow('followers')}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight700,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  {renderConvertUserFollowNum(
                    getUserData?.data?.counters?.followers || 0,
                  )}
                </Text>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  粉絲
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[Layout.colCenter]}
                onPress={() => handleClickFollow('followings')}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight700,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  {renderConvertUserFollowNum(
                    getUserData?.data?.counters?.followings || 0,
                  )}
                </Text>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  追蹤中
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[Layout.row]}>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size20,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              {getUserData?.data?.name && getUserData?.data?.name}
            </Text>
          </View>
          {getUserData?.data?.title?.name && (
            <View style={[Gutters.tinyBMargin, styles.titleContainer]}>
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {getUserData?.data?.title?.name}
              </Text>
            </View>
          )}
          {getUserData?.data?.bio && (
            <Text
              style={[
                Gutters.tinyBMargin,
                Fonts.size13,
                Fonts.weight400,
                { color: Colors.fontText.light.primary3 },
              ]}
            >
              {getUserData?.data?.bio}
            </Text>
          )}
          {renderSocialAccount()}
          {isMyProfile ? (
            <TouchableOpacity
              style={[
                Layout.colCenter,
                Gutters.regularBMargin,
                {
                  width: 132,
                  height: 32,
                  borderWidth: 1,
                  borderColor: Colors.primary,
                  borderRadius: 8,
                },
              ]}
              onPress={() => {
                navigation.push(AuthPagesEnum.EditProfileContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                編輯個人資料
              </Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[
                Layout.colCenter,
                Gutters.regularBMargin,
                {
                  width: 132,
                  height: 32,
                  borderWidth: 1,
                  borderColor: Colors.primary,
                  borderRadius: 8,
                  backgroundColor: getUserData?.data?.followed
                    ? 'transparent'
                    : Colors.primary,
                },
              ]}
              onPress={handleFollowBtn}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  {
                    color: getUserData?.data?.followed
                      ? Colors.fontText.light.primary2
                      : Colors.fontText.dark.primary2,
                  },
                ]}
              >
                {getUserData?.data?.followed ? '追蹤中' : '追蹤'}
              </Text>
            </TouchableOpacity>
          )}
          {/* 成就徽章 */}
          {!!convertUserAchievement?.length && (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(PagesEnum.UserAchievementContainer, {
                  id: route?.params?.id,
                })
              }
            >
              <View
                style={[
                  Layout.fullWidth,
                  Layout.row,
                  Layout.justifyContentBetween,
                ]}
              >
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Gutters.tinyBMargin,
                  ]}
                >
                  <Image
                    source={Images.profile_main_achievement}
                    resizeMode="contain"
                    style={[Layout.iconSize24, Gutters.tinyRMargin]}
                  />
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size14,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    成就徽章
                  </Text>
                </View>
                <Image
                  source={Images.arrowRight}
                  resizeMode="contain"
                  style={[Layout.iconSize24]}
                />
              </View>
              <View style={[Layout.row]}>
                {convertUserAchievement?.map(item => {
                  return (
                    <Image
                      source={{ uri: item?.imageUrl || '' }}
                      resizeMode="contain"
                      style={[{ width: 70, height: 80 }]}
                    />
                  )
                })}
              </View>
            </TouchableOpacity>
          )}
        </View>
        <View style={[Layout.row, Layout.fullWidth]}>
          <TouchableOpacity
            onPress={() => setActiveTab('note')}
            style={[
              Gutters.smallVPadding,
              {
                width: '50%',
                borderBottomColor:
                  activeTab === 'note' ? Colors.primary : 'transparent',
                borderBottomWidth: 4,
              },
            ]}
          >
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                Fonts.textCenter,
                {
                  color:
                    activeTab === 'note'
                      ? Colors.fontText.light.primary2
                      : Colors.fontText.light.primary3,
                },
              ]}
            >
              {`品飲筆記(${getUserData?.data?.counters?.notes})`}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setActiveTab('wishlist')}
            style={[
              Gutters.smallVPadding,
              {
                width: '50%',
                borderBottomColor:
                  activeTab === 'wishlist' ? Colors.primary : 'transparent',
                borderBottomWidth: 4,
              },
            ]}
          >
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                Fonts.textCenter,
                {
                  color:
                    activeTab === 'wishlist'
                      ? Colors.fontText.light.primary2
                      : Colors.fontText.light.primary3,
                },
              ]}
            >
              {`願望清單(${getUserData?.data?.counters?.collectedSpirits})`}
            </Text>
          </TouchableOpacity>
        </View>
      </>
    )
  }, [
    Fonts,
    Gutters,
    Images,
    Layout,
    activeTab,
    convertUserAchievement,
    getUserData?.data,
    handleClickFollow,
    handleFollowBtn,
    isMyProfile,
    navigation,
    route?.params?.id,
  ])

  // 筆記清單
  const NotesListTab = useCallback(() => {
    return (
      <View style={[Gutters.smallVPadding, Layout.fill]}>
        <FlatList
          data={profileUserNoteListData?.data || []}
          contentContainerStyle={[Layout.fill]}
          keyExtractor={item => item?.id?.toString()}
          scrollEnabled={!productImageLightBox?.visible}
          renderItem={({ item: element }) => {
            const item = {
              spirit: element?.spirit,
              id: element?.id,
              createdAt: element?.createdAt,
              updatedAt: element?.updatedAt,
              content: element?.content,
              flavors: element?.flavors,
              rating: element?.rating,
              images: element?.images,
              scannedImageUrl: '',
            }

            return (
              <SpiritCardWithContent
                item={item}
                navigation={navigation}
                showMoreBtn
                showDivider
                isMyProfile={isMyProfile}
                flavorsRowWrap
                openFlavors
              />
            )
          }}
          refreshing
          onEndReached={() => {
            handleFetchMoreData()
          }}
          onEndReachedThreshold={1}
          ListFooterComponent={renderListLoading}
          ListHeaderComponent={renderHeader}
          ListEmptyComponent={() => (
            <View
              style={[Layout.fill, Layout.colCenter, Gutters.largeTPadding]}
            >
              {getUserNotesDataIsLoading || updateNoteLoading ? (
                <ActivityIndicator size="large" color={Colors.primary} />
              ) : (
                <>
                  <Image
                    source={Images.profile_main_notesEmpty}
                    resizeMode="contain"
                    style={[
                      Gutters.smallBMargin,
                      {
                        width: 170,
                        height: 160,
                      },
                    ]}
                  />
                  <Text
                    style={[
                      Fonts.weight700,
                      Fonts.size16,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    尚未加入任何酒
                  </Text>
                </>
              )}
            </View>
          )}
        />
      </View>
    )
  }, [
    Gutters.smallVPadding,
    Gutters.largeTPadding,
    Gutters.smallBMargin,
    Layout.fill,
    Layout.colCenter,
    profileUserNoteListData?.data,
    productImageLightBox?.visible,
    renderListLoading,
    renderHeader,
    navigation,
    isMyProfile,
    handleFetchMoreData,
    Images.profile_main_notesEmpty,
    Fonts.weight700,
    Fonts.size16,
  ])

  // 願望清單
  const WishListTab = useCallback(() => {
    return (
      <View style={[Gutters.smallVPadding, Layout.fill]}>
        <FlatList
          data={profileUserWishlistListData?.data || []}
          contentContainerStyle={[Layout.fill]}
          keyExtractor={item => item?.id?.toString()}
          scrollEnabled={!productImageLightBox?.visible}
          renderItem={({ item }) => {
            return (
              <SpiritCard
                id={item?.id}
                title={item?.title}
                onClick={() => handleClickCard(item?.id, item?.titleUrlSlug)}
                imageUrl={item?.imageUrl}
                rating={item?.ratings?.average}
                ratingCount={item?.ratings?.count}
                brand={item?.brand}
                collect={item?.collected}
                showWishlist
              />
            )
          }}
          refreshing
          onEndReached={() => {
            handleFetchMoreData()
          }}
          onEndReachedThreshold={100}
          ListHeaderComponent={renderHeader}
          ListFooterComponent={renderListLoading}
          ListEmptyComponent={() => (
            <View
              style={[Layout.fill, Layout.colCenter, Gutters.largeTPadding]}
            >
              <Image
                source={Images.profile_main_wishlistEmpty}
                resizeMode="contain"
                style={{
                  width: 170,
                  height: 160,
                }}
              />
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                尚未加入任何酒
              </Text>
            </View>
          )}
        />
      </View>
    )
  }, [
    Fonts,
    Gutters,
    Images.profile_main_wishlistEmpty,
    Layout,
    profileUserWishlistListData,
    handleClickCard,
    productImageLightBox?.visible,
    renderListLoading,
    handleFetchMoreData,
    renderHeader,
  ])

  if (getUserDataIsLoading || getMeIsLoading) {
    return (
      <View
        style={[
          Layout.fill,
          Layout.rowCenter,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        Layout.justifyContentBetween,
        isFullScreen && Gutters.largeTPadding,
        { backgroundColor: Colors.background.default },
      ]}
    >
      {renderSEOMetadata()}
      <Appbar.Header
        style={[
          Layout.fullWidth,
          Layout.row,
          Layout.justifyContentBetween,
          Gutters.regularHPadding,
          {
            backgroundColor: Colors.background.default,
          },
        ]}
      >
        <View
          style={[
            Layout.colCenter,
            Layout.iconSize32,
            Gutters.regularRPadding,
            {
              borderRadius: BorderRadius.radius16,
              backgroundColor: Colors.fontText.dark.primary3,
            },
          ]}
        >
          <TouchableOpacity
            onPress={handleGoBack}
            style={{
              paddingLeft: navigation.canGoBack() ? 32 : 24,
              paddingBottom: 2,
            }}
          >
            <Image
              style={[Layout.iconSize24]}
              source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
        <View>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {getUserData?.data?.enabled ? getUserData?.data?.name : ''}
          </Text>
        </View>
        {getUserData?.data?.enabled && (
          <View
            style={[
              Layout.colCenter,
              Layout.iconSize32,
              Gutters.regularRPadding,
              {
                paddingRight: 24,
                borderRadius: BorderRadius.radius16,
                backgroundColor: Colors.fontText.dark.primary3,
              },
            ]}
          >
            <TouchableOpacity
              onPress={shareData}
              style={{ paddingLeft: 24, paddingBottom: 2 }}
            >
              <Image
                style={[Layout.iconSize24]}
                source={Images.share}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
        )}
      </Appbar.Header>
      {getUserData?.data?.enabled ? (
        <View
          style={[Layout.fill, { height: Dimensions.get('window').height }]}
        >
          {/* 下方 TAB */}
          {activeTab === 'note' ? NotesListTab() : WishListTab()}
        </View>
      ) : (
        <View style={[Layout.fill, Layout.colCenter]}>
          <Image
            source={Images.defaultAvatar}
            resizeMode="contain"
            style={[Layout.iconSize80, Gutters.tinyBMargin]}
          />
          <Text
            style={[Fonts.size26, Fonts.weight700, { color: Colors.white }]}
          >
            此用戶帳號已停用
          </Text>
        </View>
      )}
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    titleContainer: {
      backgroundColor: '#6147E3',
      alignSelf: 'flex-start',
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      marginRight: 4,
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
  })

export default ProfileMainContainer
