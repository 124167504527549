import React, { useState, useEffect } from 'react'
import { Image, Animated } from 'react-native'

interface AutoHeightImageProps {
  width: number
  source: string
  onHeightChange: (height: number) => void
  maxHeight: number
  resizeMode: 'cover' | 'contain'
}

// 這個元件是用來自動調整圖片高度，因為 RN 無法設定固定寬度，自動高度，所以需要這個元件
const AutoHeightImage = ({
  width,
  source,
  onHeightChange,
  maxHeight,
  resizeMode,
}: AutoHeightImageProps) => {
  const [height, setHeight] = useState(0)
  const [currentSource, setCurrentSource] = useState(source)

  useEffect(() => {
    setCurrentSource(source)
    setHeight(0)

    // 使用 Image.getSize 獲取圖片尺寸
    if (source) {
      Image.getSize(
        source,
        (imageWidth, imageHeight) => {
          let calculatedHeight = (imageHeight / imageWidth) * width
          if (maxHeight && calculatedHeight > maxHeight) {
            calculatedHeight = maxHeight
          }
          setHeight(calculatedHeight)
          if (onHeightChange) {
            onHeightChange(calculatedHeight)
          }
        },
        error => {
          console.error('Error getting image size:', error)
        },
      )
    }
  }, [source, width, maxHeight, onHeightChange])

  return (
    <Animated.Image
      style={{ width, height }}
      source={{ uri: currentSource }}
      resizeMode={resizeMode}
    />
  )
}

export default AutoHeightImage
