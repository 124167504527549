const isEmail = (email: string) => {
  const reg = /^\S+@\S+\.\S+$/
  return reg.test(email)
}

const isConfirmPassword = (password: string, confirmPassword: string) => {
  return password === confirmPassword
}

const passwordFormat = (value: string) => {
  // 需超過 8 個字元，且包含英文與數字
  const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/
  return regex?.test(value)
}

// 檢查密碼長度是否至少八碼
const isPasswordLongEnough = (value: string): boolean => {
  return value.length >= 8
}

// 檢查密碼是否包含數字
const hasPasswordNumber = (value: string): boolean => {
  return /[0-9]/.test(value)
}

// 檢查密碼是否同時包含大小寫英文
const hasPasswordMixedCase = (value: string): boolean => {
  const hasUpperCase = /[A-Z]/.test(value)
  const hasLowerCase = /[a-z]/.test(value)
  return hasUpperCase && hasLowerCase
}

const signUpPasswordRegex = (value: string) => {
  const isLongEnough = isPasswordLongEnough(value)
  const hasNumber = hasPasswordNumber(value)
  const hasMixedCase = hasPasswordMixedCase(value)

  const isValidPassword = isLongEnough && hasNumber && hasMixedCase

  return isValidPassword
}

const imageFormat = (image: string) => {
  const allowedFormats = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/heic',
  ]
  return allowedFormats.includes(image)
}

export const Validation = {
  isEmail,
  isConfirmPassword,
  passwordFormat,
  signUpPasswordRegex,
  imageFormat,
  isPasswordLongEnough,
  hasPasswordNumber,
  hasPasswordMixedCase,
}
