import React, { useCallback, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  FlatList,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
  Platform,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import {
  FadeInView,
  Header,
  LoadingComponent,
  SpiritCardWithContent,
} from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { useTranslation } from 'react-i18next'
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { spiritActions } from '@/Store/Spirit'
import { RootState, getGlobal } from '@/Store'
import { navigate } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

const MySpiritContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const styles = getStyle()

  const { mySpiritList } = useSelector((state: RootState) => state.spirit)
  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  // workaround for first time rating will not update the rate immediately
  const addRatingLoading = useSelector(
    (state: RootState) => state.spirit.addRatingLoading,
  )

  // lazy load my spirits 資料
  const [
    getMySpiritsRequest,
    { isFetching: isLazyFetching, isLoading: isMySpiritLoading },
  ] = spiritsApi.useLazyGetMySpiritsQuery()

  // 取得風味資訊
  noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  useFocusEffect(
    useCallback(() => {
      getMySpiritsRequest({
        with: 'spirit,note',
      })

      return () => {
        dispatch(spiritActions.clearMySpiritList())
      }
    }, [getMySpiritsRequest, dispatch]),
  )

  // 清除酒評論資料
  useEffect(() => {
    if (isFocused) {
      dispatch(spiritActions.clearSpiritData())
    }
  }, [isFocused, dispatch])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Profile,
    })
  }, [navigation])

  const handleFetchMoreData = () => {
    if (isLazyFetching) {
      return
    }
    if (mySpiritList.meta?.nextCursor) {
      getMySpiritsRequest({
        cursor: mySpiritList.meta.nextCursor,
        with: 'spirit,note',
      })
    }
  }

  const renderListLoading = () => {
    return (
      <View>
        {isLazyFetching && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    )
  }

  const renderEmpty = () => {
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      >
        <Image
          source={Images.my_spirit_empty}
          resizeMode="contain"
          style={[Gutters.regularBMargin, styles.emptyIcon]}
        />
        <Text
          style={[
            Fonts.weight700,
            Fonts.size16,
            Gutters.tinyBMargin,
            { color: Colors.white },
          ]}
        >
          尚未加入任何酒
        </Text>
        <Text
          style={[
            Fonts.weight400,
            Fonts.size14,
            Gutters.regularBMargin,
            { color: Colors.secondaryText },
          ]}
        >
          立即搜尋，建立您的第一筆品飲筆記
        </Text>
        <View style={[Layout.fullWidth]}>
          <TouchableOpacity
            style={[Layout.rowCenter, styles.actionTakePicBtn]}
            onPress={() =>
              navigation.navigate(PagesEnum.Main, {
                screen: MainPagesEnum.Camera,
              })
            }
          >
            <Image
              source={Images.camera}
              resizeMode="contain"
              style={[Layout.iconSize24, Gutters.miniRMargin]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                { color: Colors.primaryText },
              ]}
            >
              拍照辨識
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[Layout.rowCenter, styles.actionSearchBtn]}
            onPress={() => navigation.navigate(PagesEnum.SearchListContainer)}
          >
            <Image
              source={Images.search}
              resizeMode="contain"
              style={[Layout.iconSize24, Gutters.miniRMargin]}
            />
            <Text
              style={[Fonts.weight500, Fonts.size16, { color: Colors.white }]}
            >
              文字搜尋
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  // TODO: 往上拉 reFresh
  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      {addRatingLoading && <LoadingComponent />}
      <Header
        title={t('mySpirit.title')}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          {isMySpiritLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View
              style={[Layout.fill, { height: Dimensions.get('window').height }]}
            >
              <FlatList
                data={mySpiritList.data || []}
                contentContainerStyle={[Layout.fill]}
                keyExtractor={item => item.id.toString()}
                scrollEnabled={!productImageLightBox?.visible}
                renderItem={item => (
                  <SpiritCardWithContent
                    {...item}
                    navigation={navigation}
                    showMoreBtn
                    isMyProfile={true}
                  />
                )}
                refreshing
                onEndReached={({ distanceFromEnd }) => {
                  if (Platform.OS === 'android') {
                    handleFetchMoreData()
                    return
                  }
                  if (distanceFromEnd >= 1) {
                    handleFetchMoreData()
                  }
                }}
                onEndReachedThreshold={1}
                ListFooterComponent={renderListLoading}
                ListEmptyComponent={renderEmpty}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    actionTakePicBtn: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      marginBottom: 16,
      backgroundColor: Colors.primary,
    },
    actionSearchBtn: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    emptyIcon: {
      width: 150,
      height: 150,
    },
  })

export default MySpiritContainer
