import { EventName } from './events'

interface NewOldClientsData {
  type: EventName.NEW_OLD_CLIENTS
  data: {
    id: 'download-button' | 'write-note-button'
    appURL: string
    webURL: string
  }
}

interface NewOldClientsProps {
  data: NewOldClientsData
  isLogin: boolean
  popupADCloseCallback?: () => void
}

export const handleNewOldClients = ({ data, isLogin }: NewOldClientsProps) => {
  if (data.data.id === 'download-button') {
    if (isLogin) {
      // @ts-ignore
      window.confirm(
        '您已註冊過\n此活動為新會員限定！若於活動期間完成註冊的會員，請至註冊信箱收取好禮兌換憑證',
      )
      return
    } else {
      // @ts-ignore
      window.location.href = data.data.webURL
    }
  }
  if (data.data.id === 'write-note-button') {
    // @ts-ignore
    const isConfirmed = window.confirm(
      '參加注意事項\n筆記內容需包含：風味標記、文字內容、照片\n\n成功發佈後即有抽獎資格，不另通知',
    )
    if (isConfirmed) {
      // @ts-ignore
      window.location.href = data.data.webURL
    }
  }
}
