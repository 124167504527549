import { CouponStatus } from '@/Containers/Coupon/CouponListContainer'
import { GetCouponListResponse } from '@/Services/modules/loyaltyPrograms/loyaltyPrograms.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CouponState {
  couponList: {
    usable: GetCouponListResponse
    redeemed: GetCouponListResponse
    invalid: GetCouponListResponse
  }
  // 加賀導購活動頁，如果沒有登入按領取時，登入結束後直接幫忙領取
  claimCoupon: {
    programId: string
    referralCode: string
    spiritId: string
  }
}

const initialState: CouponState = {
  couponList: {
    usable: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
    redeemed: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
    invalid: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
  },
  claimCoupon: {
    programId: '',
    referralCode: '',
    spiritId: '',
  },
}

const slice = createSlice({
  name: 'coupon',
  initialState: initialState as CouponState,
  reducers: {
    // 優惠券列表資料
    setCouponList: (
      state,
      action: PayloadAction<{ status: string; data: GetCouponListResponse }>,
    ) => {
      const { status, data } = action.payload
      if (status === CouponStatus.USABLE) {
        state.couponList.usable.data = state.couponList.usable.data?.concat(
          ...data.data,
        )
        state.couponList.usable.meta = data.meta
      }
      if (status === CouponStatus.REDEEMED) {
        state.couponList.redeemed.data = state.couponList.redeemed.data?.concat(
          ...data.data,
        )
        state.couponList.redeemed.meta = data.meta
      }
      if (status === CouponStatus.INVALID) {
        state.couponList.invalid.data = state.couponList.invalid.data?.concat(
          ...data.data,
        )
        state.couponList.invalid.meta = data.meta
      }
    },
    // 優惠券列表資料清空
    clearCouponList: state => {
      state.couponList = {
        usable: {
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        },
        redeemed: {
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        },
        invalid: {
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        },
      }
    },
    // 先儲存領取優惠券資料，等登入結束後再呼叫
    setClaimCoupon: (
      state,
      action: PayloadAction<{
        programId: string
        referralCode: string
        spiritId: string
      }>,
    ) => {
      state.claimCoupon = action.payload
    },
    // 清除領取優惠券資料
    clearClaimCoupon: state => {
      state.claimCoupon = {
        programId: '',
        referralCode: '',
        spiritId: '',
      }
    },
  },
})

export const couponReducer = slice.reducer
export const couponActions = slice.actions

export default slice.reducer
