/**
 * This file contains all application's style relative to fonts
 */
import { StyleSheet } from 'react-native'
import { ThemeVariables } from './theme'

export default function ({ FontSize, Colors }: ThemeVariables) {
  return StyleSheet.create({
    textSmall: {
      fontSize: FontSize.small,
      color: Colors.text,
    },
    textRegular: {
      fontSize: FontSize.regular,
      color: Colors.text,
    },
    textLarge: {
      fontSize: FontSize.large,
      color: Colors.text,
    },
    titleSmall: {
      fontSize: FontSize.small * 2,
      fontWeight: 'bold',
      color: Colors.text,
    },
    titleRegular: {
      fontSize: FontSize.regular * 2,
      fontWeight: 'bold',
      color: Colors.text,
    },
    titleLarge: {
      fontSize: FontSize.large * 2,
      fontWeight: 'bold',
      color: Colors.text,
    },
    textCenter: {
      textAlign: 'center',
    },
    textJustify: {
      textAlign: 'justify',
    },
    textLeft: {
      textAlign: 'left',
    },
    textRight: {
      textAlign: 'right',
    },
    bold: {
      fontWeight: 'bold',
    },
    weight300: {
      fontWeight: '300',
    },
    weight400: {
      fontWeight: '400',
    },
    weight500: {
      fontWeight: '500',
    },
    weight600: {
      fontWeight: '600',
    },
    weight700: {
      fontWeight: '700',
    },
    size12: {
      fontSize: 12,
    },
    size13: {
      fontSize: 13,
    },
    size14: {
      fontSize: 14,
    },
    size16: {
      fontSize: 16,
    },
    size18: {
      fontSize: 18,
    },
    size20: {
      fontSize: 20,
    },
    size24: {
      fontSize: 24,
    },
    size26: {
      fontSize: 26,
    },
    size32: {
      fontSize: 32,
    },
    size40: {
      fontSize: 40,
    },
    size46: {
      fontSize: 46,
    },
    size64: {
      fontSize: 64,
    },
  })
}
