import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { TextInput } from 'react-native-paper'
import { FadeInView, Header } from '@/Components'
import Jssha from 'jssha'
// import { getConfig } from '@/Util/global'
import { Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDispatch } from 'react-redux'
import { globalActions } from '@/Store/Global'
import {
  handlePendingRedirect,
  hasPendingRedirect,
  navigate,
} from '@/Navigators/utils'
import { Validation } from '@/Util/validation'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { useTranslation } from 'react-i18next'

const initLoginData = {
  account: '',
  password: '',
}

const EmailLoginContainer = ({ navigation }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()

  const [singIn, response] = userApi.useSignInMutation()
  const [_facebookLogin, facebookResponse] =
    userApi.useSignInWithSocialMutation()

  userApi.useGetMeQuery(undefined, {
    skip: !response.isSuccess,
  })

  const [loginData, setLoginData] = useState(initLoginData)
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true)
  const [openPassword, setOpenPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const styles = stylesSheet()

  useEffect(() => {
    if (
      !!loginData?.account?.length &&
      !!loginData?.password?.length &&
      Validation.isEmail(loginData?.account)
    ) {
      setLoginButtonDisabled(false)
    }
    if (!loginData?.account?.length || !loginData?.password?.length) {
      setLoginButtonDisabled(true)
    }
  }, [loginData])

  useEffect(() => {
    const { isSuccess, data, isError } = response
    const { isSuccess: facebookIsSuccess, data: facebookData } =
      facebookResponse

    const storeData = async (value: string) => {
      try {
        await AsyncStorage.setItem('auth-token', value)
      } catch (e) {
        console.error(e)
      }
    }

    if (isError) {
      setErrorMessage('帳號或密碼錯誤')
    }

    if (isSuccess) {
      storeData(data.data.token)
      navigation.navigate(PagesEnum.Main, {
        screen: 'Profile',
      })
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '成功登入，歡迎回來！',
          type: 'default',
          showIcon: false,
        }),
      )
    }
    if (facebookIsSuccess) {
      storeData(facebookData.data.token)
      navigation.navigate(PagesEnum.Main, {
        screen: 'Profile',
      })
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '成功登入，歡迎回來！',
          type: 'default',
          showIcon: false,
        }),
      )
    }
  }, [response, navigation, dispatch, facebookResponse])

  const handleSignIn = () => {
    if (response.isLoading) {
      return
    }
    const shaPassword = new Jssha('SHA3-512', 'TEXT')
    shaPassword.update(loginData.password)
    const signData = {
      account: loginData.account,
      password: shaPassword.getHash('HEX'),
    }
    singIn(signData)
  }

  const handleGoBack = useCallback(() => {
    if (hasPendingRedirect()) {
      handlePendingRedirect()
      return
    }
    if (navigation.canGoBack()) {
      navigation.goBack()
      setLoginData(initLoginData)
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
    setLoginData(initLoginData)
  }, [navigation])

  const loginButtonBg = loginButtonDisabled ? '#D9D9D9' : Colors.primary

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <Header
        title={'登入'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <ScrollView style={[Layout.fill]}>
        <View style={[Gutters.smallTPadding]}>
          {/* 輸入帳密欄位 */}
          <FadeInView duration={550} style={Gutters.regularHPadding}>
            <View style={[{ marginBottom: 150 }]}>
              <Text
                style={[
                  Fonts.size20,
                  Fonts.weight700,
                  Gutters.smallVMargin,
                  { color: Colors.fontText.light.primary1 },
                ]}
              >
                輸入您的電子郵件及密碼
              </Text>
              <TextInput
                label="電子郵件地址"
                value={loginData.account}
                style={[
                  styles.textInput,
                  !errorMessage && Gutters.regularBMargin,
                  { color: Colors.white },
                ]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage}
                onChangeText={value => {
                  setLoginData({
                    ...loginData,
                    account: value,
                  })
                  if (errorMessage) {
                    setErrorMessage('')
                  }
                }}
              />
              {!!errorMessage && (
                <Text
                  style={[
                    Gutters.tinyVMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage}
                </Text>
              )}
              <TextInput
                label="密碼"
                value={loginData.password}
                style={[
                  Layout.fullWidth,
                  styles.textInput,
                  { color: Colors.white },
                ]}
                secureTextEntry={!openPassword}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage}
                onChangeText={value => {
                  setLoginData({
                    ...loginData,
                    password: value,
                  })
                  if (errorMessage) {
                    setErrorMessage('')
                  }
                }}
                right={
                  <TextInput.Icon
                    icon={() => (
                      <TouchableOpacity
                        onPress={() => setOpenPassword(!openPassword)}
                      >
                        <Image
                          source={
                            openPassword ? Images.eye_open : Images.eye_off
                          }
                          resizeMode="contain"
                          style={[Layout.iconSize20, { top: 5 }]}
                        />
                      </TouchableOpacity>
                    )}
                  />
                }
              />
              {!!errorMessage && (
                <Text
                  style={[
                    Gutters.tinyVMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage}
                </Text>
              )}
            </View>
          </FadeInView>
          {/* 登入按鈕 */}
          <FadeInView duration={550} style={{ marginBottom: 30 }}>
            <View style={[Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  styles.buttonContainer,
                  {
                    backgroundColor: loginButtonBg,
                  },
                ]}
                disabled={loginButtonDisabled}
                onPress={handleSignIn}
              >
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.bold,
                    {
                      color: loginButtonDisabled
                        ? Colors.gray
                        : Colors.fontText.dark.primary2,
                    },
                  ]}
                >
                  登入
                </Text>
              </TouchableOpacity>
            </View>
          </FadeInView>
        </View>
        {/* 忘記密碼 */}
        <View style={[styles.signInContainer, Gutters.regularBMargin]}>
          <View style={[Layout.rowCenter]}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(PagesEnum.ForgotPasswordContainer)
                setLoginData(initLoginData)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  {
                    color: Colors.primary,
                  },
                ]}
              >
                忘記密碼？
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.signInContainer}>
          <View style={[Layout.rowCenter, Gutters.smallBMargin]}>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size16,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              還沒有帳號？
            </Text>
            <TouchableOpacity
              onPress={() => {
                setLoginData(initLoginData)
                navigation.navigate(PagesEnum.EmailRegisterContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  {
                    color: Colors.primary,
                  },
                ]}
              >
                建立帳號
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    buttonContainer: {
      height: 48,
      borderRadius: 8,
      backgroundColor: Colors.primary,
      paddingVertical: 9,
    },
    signInContainer: {
      alignItems: 'center',
      width: '100%',
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    textInput: {
      backgroundColor: '#1C1C20',
      height: 56,
    },
  })

export default EmailLoginContainer
