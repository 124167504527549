import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  ActivityIndicator,
  Linking,
} from 'react-native'
import { useFullScreen, useTheme } from '@/Hooks'
import { FadeInView, WarningSignComponent } from '@/Components'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useTranslation } from 'react-i18next'
import { globalActions } from '@/Store/Global'

// import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDispatch } from 'react-redux'
import { Avatar } from 'react-native-paper'
import { api } from '@/Services/api'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const OUT_APP_REVIEW_FEEDBACK_URL = 'https://lihi.cc/H6WxF'

const ProfileContainer = ({ navigation, scrollRef }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const isFullScreen = useFullScreen()

  const { data: getMeData, isLoading: getMeIsLoading } = userApi.useGetMeQuery()

  api.useGetSiteMetaQuery()

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: false,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, Layout.iconSize64]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  backgroundColor: Colors.primary,
                  borderRadius: BorderRadius.radius8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                navigation.navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  if (getMeIsLoading) {
    return (
      <View
        style={[
          Layout.fill,
          Layout.rowCenter,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        Layout.justifyContentBetween,
        isFullScreen && Gutters.largeTPadding,
        { backgroundColor: Colors.background.default },
      ]}
    >
      <ScrollView ref={scrollRef} style={[Layout.fill]}>
        {/* 個人資訊 */}
        <FadeInView
          duration={550}
          style={{ backgroundColor: Colors.background.default }}
        >
          <View
            style={[
              Layout.fullSize,
              Gutters.smallVPadding,
              Gutters.smallHPadding,
            ]}
          >
            <View style={[Layout.row, Layout.justifyContentBetween]}>
              <Avatar.Image
                source={{
                  uri: getMeData?.avatar || Images.profile_profile,
                }}
                style={[Gutters.smallBMargin, Layout.iconSize56]}
                size={56}
              />
              <TouchableOpacity
                style={[Gutters.smallTPadding]}
                onPress={() =>
                  navigation.navigate(PagesEnum.ProfileDetailContainer)
                }
              >
                <Image
                  source={Images.profile_more}
                  style={Layout.iconSize40}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size20,
                getMeData && Gutters.smallBMargin,
                { color: Colors.white },
              ]}
            >
              {getMeData?.name ? getMeData?.name : 'Hi，歡迎來到烈酒探索家'}
            </Text>
            {getMeData && (
              <TouchableOpacity
                style={[
                  Layout.colCenter,
                  {
                    width: 132,
                    height: 32,
                    borderWidth: 1,
                    borderColor: Colors.primary,
                    borderRadius: 8,
                  },
                ]}
                onPress={() => {
                  navigation.navigate('ProfileMainContainer', {
                    id: getMeData?.id,
                  })
                }}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size14,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  查看個人頁面
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </FadeInView>
        {/* 列表 */}
        <FadeInView duration={550}>
          <View style={[Gutters.regularBMargin]}>
            {/* 未登入的登入按鈕 */}
            {!getMeData && (
              <View style={[Gutters.regularHPadding, Gutters.regularVPadding]}>
                <View style={[Gutters.regularHPadding, styles.loginContainer]}>
                  <Text
                    style={[
                      Fonts.bold,
                      Gutters.smallBMargin,
                      { color: Colors.white },
                    ]}
                  >
                    註冊或登入以享受更多服務與功能
                  </Text>
                  <TouchableOpacity
                    style={[Layout.colCenter, styles.loginBtn]}
                    onPress={() =>
                      navigation.navigate(PagesEnum.LoginMainContainer)
                    }
                  >
                    <Text
                      style={[
                        Fonts.weight500,
                        Fonts.size14,
                        Fonts.textCenter,
                        {
                          color: Colors.primaryText,
                        },
                      ]}
                    >
                      登入或註冊
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {/* TODO:隱藏邀請機制 */}
            {/* 邀請好友 */}
            {/* <TouchableOpacity
              style={[
                Gutters.regularHPadding,
                Gutters.tinyBMargin,
                Layout.row,
                Layout.justifyContentBetween,
                { height: 92, backgroundColor: '#323232' },
              ]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.InviteFriend)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[
                    Gutters.smallRMargin,
                    {
                      width: 46,
                      height: 43,
                    },
                  ]}
                  source={Images.profile_inviteFriendsIcon}
                  resizeMode="contain"
                />
                <Text style={[styles.listTitle, { width: 180 }]}>
                  邀請好友來一起品酒留下獨特的心得
                </Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.primary },
                  ]}
                >
                  邀請好友
                </Text>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity> */}

            {/* 我的酒單 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.MySpirit)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_spiritIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{t('profile.mySpirit')}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Text
                  style={[
                    styles.listTitle,
                    Gutters.miniRMargin,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  {getMeData?.counters.myNotes || 0}
                </Text>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* 願望清單 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.Wishlist)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_wishlistIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{t('profile.wishlist')}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Text
                  style={[
                    styles.listTitle,
                    Gutters.miniRMargin,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  {getMeData?.counters.collectedSpirits || 0}
                </Text>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* 辨識紀錄 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.HistoryScreen)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_historyIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{t('profile.history')}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* 協助新增品項 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.UploadSpiritsContainer)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_addLiquorIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{'協助新增品項'}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* 成就徽章 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.AchievementContainer)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_achievementIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{'成就徽章'}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* 提供意見回饋 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                Linking.openURL(OUT_APP_REVIEW_FEEDBACK_URL)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_reviewFeedbackIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{'提供意見回饋'}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* 優惠券 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                if (!getMeData) {
                  openLoginDialog()
                  return
                }
                navigation.navigate(AuthPagesEnum.CouponListContainer)
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_couponIcon}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>{'優惠券'}</Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
          </View>
        </FadeInView>
      </ScrollView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign bottom={55} />
    </View>
  )
}

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    height: 73,
    backgroundColor: Colors.background.surface,
  },
  listIcon: {
    width: 24,
    height: 24,
  },
  listTitle: {
    color: Colors.white,
    fontWeight: '500',
  },
  loginContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: Colors.primary,
    backgroundColor: Colors.background.default,
    borderRadius: 10,
    paddingVertical: 24,
    alignItems: 'center',
  },
  loginBtn: {
    backgroundColor: Colors.primary,
    width: '100%',
    height: 40,
    borderRadius: 8,
    paddingVertical: 8,
  },
})

export default ProfileContainer
