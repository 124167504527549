import React from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'

import { useTheme } from '@/Hooks'
import { PagesEnum } from '@/Navigators/Application'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import useLoginDialog from '@/Hooks/useLoginDialog'

interface BannerProps {
  navigation: any
  getMeData: any
  isAssistantEnabled: boolean
}

const Banner = ({ navigation, getMeData, isAssistantEnabled }: BannerProps) => {
  const { Images, Layout, Gutters } = useTheme()
  const openLoginDialog = useLoginDialog()

  const handlePressAIChatRoom = () => {
    if (!isAssistantEnabled) {
      return
    }

    if (getMeData) {
      navigation.navigate(AuthPagesEnum.AIChatContainer)
    } else {
      openLoginDialog()
    }
  }

  // 如果助理功能未啟用（from api），則只顯示大橫幅
  if (!isAssistantEnabled) {
    return (
      <View style={[styles.container, Gutters.regularHPadding]}>
        <TouchableOpacity
          style={[styles.bigBanner]}
          onPress={() =>
            navigation.navigate(PagesEnum.SpiritsCategoriesContainer)
          }
        >
          <Image
            source={Images.home_category_banner_big}
            defaultSource={Images.home_category_banner_big}
            resizeMode="cover"
            style={[Layout.fullWidth, styles.bannerImage]}
          />
          <Image
            source={Images.home_new_icon}
            defaultSource={Images.home_new_icon}
            resizeMode="cover"
            style={[styles.newIcon]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View style={[styles.container, Gutters.regularHPadding]}>
      <TouchableOpacity
        style={[styles.banner]}
        onPress={() => navigation.push(PagesEnum.SpiritsCategoriesContainer)}
      >
        <Image
          source={Images.home_category_banner}
          defaultSource={Images.home_category_banner}
          resizeMode="cover"
          style={[Layout.fullWidth, styles.bannerImage]}
        />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.banner]} onPress={handlePressAIChatRoom}>
        <Image
          source={Images.home_ai_chat_banner}
          defaultSource={Images.home_ai_chat_banner}
          resizeMode="cover"
          style={[Layout.fullWidth, styles.bannerImage]}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    marginBottom: 14,
  },
  bigBanner: {
    width: '100%',
  },
  banner: {
    width: '48%',
  },
  bannerImage: {
    borderRadius: 8,
    height: 80,
  },
  newIcon: {
    width: 37,
    height: 18,
    position: 'absolute',
    top: 0,
    left: 0,
  },
})

export default React.memo(Banner)
