export interface SignInRequest {
  account: string
  password: string
}

export type Token = {
  data: {
    token: string
  }
}

export interface GetMeResponse {
  id: number
  name: string
  avatar: string
  counters: {
    myNotes: number
    collectedSpirits: number
  }
  bio: string
  locale: 'zh' | 'en'
  birthday: string
  country: string
  gender: string
  inviteAccount: {
    code: string
    maxUsages: number
    uses: number
  }
  title: {
    imageUrl: string
    name: string
  }
  socialLinks: {
    facebook: string
    youtube: string
    instagram: string
    threads: string
    website: string
  }
}

export interface SocialLoginRequest {
  provider: string
  token: string
  name: string
}

export interface SignUpRequest {
  basket: string
}

export interface SignUpResponse extends Token { }

export interface GetOTPCodeRequest {
  type: string
  account: string
}

export interface SignUpBasketRequestFields {
  social: {
    provider: string
    token: string
  }
  account: string
  password: string
  inviteCode: string
  accountOtpCode: string
  birthday: string
  name: string
}

export interface SignUpBasketRequest {
  oldBasket: string
  fields: Partial<SignUpBasketRequestFields>
}

export interface SignUpBasketResponse {
  data: {
    basket: string
    account: string
    name: string
  }
}

export interface GetInviteFriendListRequest {
  cursor: string
  perPage: number
}

export interface InviteUser {
  id: string
  name: string
  avatar: string
  createdAt: string
  followed: boolean
  accounts: {
    type: string
    account: string
  }[]
}

export interface GetInviteFriendListResponse {
  data: InviteUser[]
  meta: {
    nextCursor: string
    perPage: number
  }
}

export interface GetUserDataResponse {
  data: {
    name: string
    avatar: string
    counters: {
      notes: number
      collectedSpirits: number
      followings: number
      followers: number
    }
    title: {
      name: string
      imageUrl: string
    }
    enabled: boolean
    followed: boolean
    socialLinks: {
      facebook: string
      youtube: string
      instagram: string
      threads: string
      website: string
    }
    bio: string
  }
}

export interface PatchMeRequest {
  name: string
  gender: string
  birthday: string
  avatar: string
  bio: string
  country: string
  locale: string
  titleSlug: string
  socialLinks: {
    facebook: string
    youtube: string
    instagram: string
    threads: string
    website: string
  }
}

export interface GetUserFollowRequest {
  id: string
  perPage: number
  page: number
}

export interface GetUserFollowResponse {
  data: {
    id: string
    name: string
    avatar: string
    titleSlug: string
    followed: boolean
  }[]
  meta: {
    totalRows: number
    totalPages: number
    currentPage: number
    perPage: number
  }
}

export interface GetRecommendationFeedsRequest {
  type: 'popular_spirits' | 'popular_notes'
  x_feed_version?: string
  perPage?: number
  cursor?: string
}

export interface GetRecommendationFeedsResponse {
  data: Array<{
    spirit: {
      id: string
      title: string
      titleUrlSlug: string
      imageUrl: string
      brand: string
      country: string
      ratings: {
        average: number
        count: number
      }
    }
    note: {
      id: string
      content: string
      flavors: string[]
      rating: number
      image: {
        kind: string
        url: string
      }
      user: {
        id: string
        name: string
        avatar: string
        notesCount: number
        title: {
          name: string
          imageUrl: string
        }
      }
      counters: {
        comments: number
        likers: number
      }
      likeClicked: boolean
      createdAt: string
      updatedAt: string
    }
  }>
  meta: {
    nextCursor: string
    perPage: number
  }
  x_feed_version: string
}

export interface UploadFileRequest {
  contentType: string
  contentMd5: string
  // 單位 mb
  limitSize: number
}

export interface UploadFileResponse {
  data: {
    path: string
    signedUrl: string
    headers: any
  }
}

export enum SettingKey {
  NOTIFICATIONS = 'notifications',
}
export interface GetSettingsResponse {
  data: {
    key: SettingKey
    value: boolean
  }[]
}

export interface PatchSettingsRequest {
  key: SettingKey
  value: boolean
}
