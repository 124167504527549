import React, { useCallback, useEffect } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  ActivityIndicator,
} from 'react-native'
import { launchImageLibrary } from 'react-native-image-picker'
import { manipulateAsync } from 'expo-image-manipulator'
import SelectDropdown from 'react-native-select-dropdown'
import { useDispatch } from 'react-redux'
import { Appbar, Avatar } from 'react-native-paper'
import { TextInput } from 'react-native-paper'

import { useFullScreen, useTheme } from '@/Hooks'
import { FadeInView, WarningSignComponent } from '@/Components'
import { BorderRadius, Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import achievementsApi from '@/Services/modules/achievements'
import { dataUriToFile, removeEmptyKeys } from '@/Util/global'
import { globalActions } from '@/Store/Global'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import useUploadFile from '@/Hooks/useUploadFile'

const initSocialErrors = {
  facebook: '',
  youtube: '',
  website: '',
}

const EditProfileContainer = ({ navigation }: any) => {
  const { Images, Layout, Gutters, Fonts } = useTheme()
  const dispatch = useDispatch()
  const isFullScreen = useFullScreen()
  const { uploadFile: uploadFileRequest, isLoading: uploadFileIsLoading } =
    useUploadFile()
  const styles = stylesSheet()

  const {
    data: getMeData,
    isLoading: getMeIsLoading,
    isSuccess: getMeDataIsSuccess,
  } = userApi.useGetMeQuery(undefined, { refetchOnMountOrArgChange: true })

  const {
    data: getMyAchievementsData,
    isSuccess: getMyAchievementsIsSuccess,
    isLoading: getMyAchievementsIsLoading,
  } = achievementsApi.useGetMyAchievementsQuery()

  const [
    patchMeRequest,
    { isSuccess: patchMeIsSuccess, isLoading: patchMeIsLoading },
  ] = userApi.usePatchUserDataMutation()

  const [step, setStep] = React.useState<'editProfile' | 'editImage'>(
    'editProfile',
  )
  const [nickName, setNickName] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [bio, setBio] = React.useState('')
  const [titleIndex, setTitleIndex] = React.useState<number>()
  const [titleGroup, setTitleGroup] = React.useState<
    {
      slug: string
      title: string
    }[]
  >([])
  const [socialAccount, setSocialAccount] = React.useState({
    facebook: '',
    instagram: '',
    threads: '',
    youtube: '',
    website: '',
  })
  const [socialErrors, setSocialErrors] = React.useState(initSocialErrors)
  const [editAvatar, setEditAvatar] = React.useState('')
  const [avatar, setAvatar] = React.useState('')

  const convertSocialAccount = (
    type: 'instagram' | 'threads',
    value: string,
  ) => {
    if (type === 'instagram') {
      return value.replace('https://www.instagram.com/', '@')
    }

    if (type === 'threads') {
      return value.replace('https://www.threads.net/', '@')
    }

    return value
  }

  const initSocialAccount = useCallback(() => {
    if (getMeDataIsSuccess) {
      setSocialAccount({
        ...getMeData?.socialLinks,
        instagram: convertSocialAccount(
          'instagram',
          getMeData?.socialLinks?.instagram || '',
        ),
        threads: convertSocialAccount(
          'threads',
          getMeData?.socialLinks?.threads || '',
        ),
      })
    }
  }, [getMeData?.socialLinks, getMeDataIsSuccess])

  useEffect(() => {
    if (getMeDataIsSuccess) {
      setNickName(getMeData?.name)
      setTitle(getMeData?.title?.name)
      setAvatar(getMeData?.avatar)
      setBio(getMeData?.bio)
      initSocialAccount()
    }
  }, [getMeDataIsSuccess, getMeData, initSocialAccount])

  // 取得可以選取的稱號 asUserTitle = true && progress = 100
  useEffect(() => {
    if (getMyAchievementsIsSuccess && getMeDataIsSuccess) {
      const result = [{ slug: 'null', title: '不顯示' }]
      for (const item of getMyAchievementsData?.data) {
        for (const subItem of item?.data) {
          if (subItem?.asUserTitle && subItem?.progress === 100) {
            result.push({
              slug: subItem?.slug,
              title: subItem?.name,
            })
          }
        }
      }
      const index =
        result.findIndex(item => item.title === getMeData?.title?.name) || 0

      // 如果都沒有稱號可以選擇，不顯示則改成尚未取得可顯示稱號
      if (result.length === 1) {
        result[0].title = '尚未取得可顯示稱號'
      }

      setTitleIndex(index)
      setTitleGroup(result)
    }
  }, [
    getMyAchievementsIsSuccess,
    getMyAchievementsData,
    getMeData,
    getMeDataIsSuccess,
  ])

  useEffect(() => {
    if (patchMeIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '儲存成功',
          type: 'success',
          showIcon: false,
        }),
      )
      navigation.navigate('ProfileMainContainer', {
        id: getMeData?.id,
      })
    }
  }, [dispatch, getMeData?.id, navigation, patchMeIsSuccess])

  const handleGoBack = useCallback(() => {
    initSocialAccount()
    setSocialErrors(initSocialErrors)
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigation.navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation, initSocialAccount])

  // ig 與 threads 的網址轉換。設計只給使用者填入帳號，不填網址，但是帶 api 時要帶網址
  const convertSocialAccountIGAndThreads = (
    type: 'instagram' | 'threads',
    value: string,
  ) => {
    if (!value) {
      return null
    }

    if (value.includes('https://')) {
      return value
    }

    const convertValue = value.replace('@', '')
    if (type === 'instagram') {
      return `https://www.instagram.com/${convertValue}`
    }

    if (type === 'threads') {
      return `https://www.threads.net/${convertValue}`
    }

    return value
  }

  const validateUrl = (
    url: string,
    type: 'facebook' | 'youtube' | 'website',
  ) => {
    if (!url) return ''

    switch (type) {
      case 'facebook':
        if (!url.startsWith('https://www.facebook.com/')) {
          return '請輸入正確的 Facebook 網址'
        }
        break
      case 'youtube':
        if (
          !url.startsWith('https://www.youtube.com/channel/') &&
          !url.startsWith('https://www.youtube.com/@')
        ) {
          return '請輸入正確的 YouTube 網址'
        }
        break
      case 'website':
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return '請輸入正確的網址格式'
        }
        break
    }

    return ''
  }

  const handleSaveProfile = async () => {
    const fbError = validateUrl(socialAccount.facebook, 'facebook')
    const ytError = validateUrl(socialAccount.youtube, 'youtube')
    const webError = validateUrl(socialAccount.website, 'website')

    setSocialErrors({
      facebook: fbError,
      youtube: ytError,
      website: webError,
    })

    if (fbError || ytError || webError) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '請檢查社群帳號網址格式是否正確',
          type: 'error',
        }),
      )
      return
    }

    if (bio?.length > 150) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '字數超過限制',
          type: 'error',
        }),
      )
      return
    }

    let avatarImagePath = ''
    const titleSlug = titleGroup?.find(item => item.slug === title)?.slug

    // 如果有更換大頭貼
    if (editAvatar) {
      const avatarFile = dataUriToFile(editAvatar, nickName)
      const fileSizeInMB = avatarFile?.size / 1024 / 1024
      if (fileSizeInMB >= 5) {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '檔案大小超過 5MB，請重新選擇照片',
            type: 'default',
          }),
        )
        return
      }

      avatarImagePath = (await uploadFileRequest(avatarFile)) || ''
    }

    const payload = removeEmptyKeys({
      name: nickName,
      titleSlug: titleSlug,
      avatar: avatarImagePath,
      bio: bio,
    })

    patchMeRequest({
      ...payload,
      titleSlug: titleSlug === 'null' ? null : titleSlug,
      bio: bio || null,
      socialLinks: {
        facebook: socialAccount.facebook || null,
        youtube: socialAccount.youtube || null,
        website: socialAccount.website || null,
        instagram:
          convertSocialAccountIGAndThreads(
            'instagram',
            socialAccount.instagram,
          ) || null,
        threads:
          convertSocialAccountIGAndThreads('threads', socialAccount.threads) ||
          null,
      },
    })
  }

  //   開啟相簿
  const handleOpenGallery = async () => {
    const result = await launchImageLibrary({
      mediaType: 'photo',
      selectionLimit: 1,
    })
    if (result?.assets) {
      const dataURI = result?.assets[0]?.uri
      handleApplyCrop(dataURI || '')
    }
  }

  const handleApplyCrop = async (imageUri: any) => {
    const image = await manipulateAsync(imageUri, [
      {
        resize: {
          width: 200,
          height: 200,
        },
      },
    ])

    setEditAvatar(image.uri)
    setStep('editProfile')
  }

  if (
    getMeIsLoading ||
    getMyAchievementsIsLoading ||
    patchMeIsLoading ||
    uploadFileIsLoading
  ) {
    return (
      <View
        style={[
          Layout.fill,
          Layout.rowCenter,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  const renderEditProfile = () => {
    return (
      <View style={[Layout.fill]}>
        <Appbar.Header
          style={[
            Layout.fullWidth,
            Layout.row,
            Layout.justifyContentBetween,
            Gutters.regularHPadding,
            {
              backgroundColor: Colors.background.default,
            },
          ]}
        >
          <View
            style={[
              Layout.colCenter,
              Layout.iconSize32,
              Gutters.regularRPadding,
              {
                borderRadius: BorderRadius.radius16,
                backgroundColor: Colors.fontText.dark.primary3,
              },
            ]}
          >
            <TouchableOpacity
              onPress={handleGoBack}
              style={{
                paddingLeft: navigation.canGoBack() ? 32 : 24,
                paddingBottom: 2,
              }}
            >
              <Image
                style={[Layout.iconSize24]}
                source={
                  navigation.canGoBack() ? Images.arrowLeft : Images.close
                }
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              編輯個人資料
            </Text>
          </View>
          <TouchableOpacity
            onPress={handleSaveProfile}
            style={{ paddingLeft: 24, paddingBottom: 2 }}
          >
            <Text
              style={[Fonts.size16, Fonts.weight400, { color: Colors.primary }]}
            >
              儲存
            </Text>
          </TouchableOpacity>
        </Appbar.Header>
        <ScrollView style={[Layout.fill]}>
          {/* 個人資訊 */}
          <FadeInView
            duration={550}
            style={{ backgroundColor: Colors.background.default }}
          >
            <View style={[Gutters.smallVPadding, Gutters.smallHPadding]}>
              <View style={[Layout.row, Layout.iconSize56]}>
                <Avatar.Image
                  source={{
                    uri: editAvatar || avatar,
                  }}
                  style={[Gutters.smallBMargin, Layout.iconSize56]}
                  size={56}
                />
                <TouchableOpacity onPress={() => handleOpenGallery()}>
                  <Image
                    style={[
                      Layout.iconSize24,
                      { position: 'absolute', right: -5, bottom: 0 },
                    ]}
                    source={Images.profile_main_edit}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </FadeInView>
          <View style={[Gutters.smallHPadding]}>
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                label="暱稱"
                value={nickName}
                autoFocus={true}
                style={[{ color: Colors.white }, styles.textInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                //   error={!!errorMessage?.account}
                onChangeText={value => setNickName(value)}
              />
            </View>
            <View style={[Gutters.smallBMargin]}>
              {!!titleGroup?.length && (
                <SelectDropdown
                  data={titleGroup}
                  defaultValueByIndex={titleIndex || 0}
                  defaultButtonText="顯示頭銜"
                  disabledIndexs={titleGroup?.length === 1 ? [0] : []}
                  onSelect={selectedItem => {
                    setTitle(selectedItem.slug)
                  }}
                  buttonTextAfterSelection={selectedItem => {
                    // text represented after item is selected
                    // if data array is an array of objects then return selectedItem.property to render after item is selected
                    return selectedItem.title
                  }}
                  rowTextForSelection={item => {
                    // text represented for each item in dropdown
                    // if data array is an array of objects then return item.property to represent item in dropdown
                    return item.title
                  }}
                  dropdownStyle={{
                    backgroundColor: Colors.background.onSurface,
                  }}
                  rowTextStyle={{
                    color: Colors.fontText.light.primary2,
                    textAlign: 'left',
                    paddingLeft: 10,
                    paddingVertical: 8,
                  }}
                  buttonStyle={{
                    width: '100%',
                    borderWidth: 1,
                    borderColor: Colors.background.top,
                    borderRadius: 10,
                    height: 56,
                    backgroundColor: Colors.background.default,
                  }}
                  buttonTextStyle={{
                    color: Colors.fontText.light.primary2,
                    textAlign: 'left',
                  }}
                  renderDropdownIcon={() => (
                    <Image
                      source={Images.arrowRight}
                      resizeMode="contain"
                      style={[
                        Layout.iconSize20,
                        Gutters.smallRPadding,
                        {
                          transform: [{ rotate: '90deg' }],
                        },
                      ]}
                    />
                  )}
                />
              )}
            </View>
            <View style={[Layout.row]}>
              <Image
                source={Images.profile_main_info}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                僅獲取特定徽章才可顯示該稱號。請前往成就徽章頁面了解如何獲取。
              </Text>
            </View>
            <View
              style={[Layout.row, Gutters.regularTMargin, Gutters.tinyBMargin]}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                簡介
              </Text>
            </View>
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                value={bio || ''}
                label="輸入簡介 (150字)"
                placeholder="輸入簡介 (150字)"
                multiline={true}
                numberOfLines={30}
                style={[styles.textInputBio]}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                maxLength={150}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                textColor="#fff"
                mode="outlined"
                onChangeText={text => {
                  if (text.length > 150) {
                    const newFirst150Bio = text.slice(0, 150)
                    setBio(newFirst150Bio)
                    return
                  }
                  setBio(text)
                }}
                error={bio?.length > 150}
              />
              {bio?.length > 150 && (
                <Text
                  style={[
                    Gutters.tinyVMargin,
                    { color: Colors.snackbar.error },
                  ]}
                >
                  字數超過限制
                </Text>
              )}
            </View>
            <View
              style={[Layout.row, Gutters.regularTMargin, Gutters.tinyBMargin]}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                社群帳號
              </Text>
            </View>
            <View
              style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBMargin]}
            >
              <Image
                source={Images.profile_main_facebook_icon}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <View style={[Layout.fullWidth]}>
                <TextInput
                  label="Facebook"
                  placeholder="https://www.facebook.com/"
                  value={socialAccount.facebook}
                  autoFocus={true}
                  style={[{ color: Colors.white }, styles.socialInput]}
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  activeOutlineColor={Colors.primary}
                  theme={{
                    colors: {
                      onSurfaceVariant: Colors.fontText.light.primary3,
                      error: Colors.snackbar.error,
                      outline: Colors.background.top,
                    },
                    roundness: 10,
                  }}
                  textColor="#fff"
                  mode="outlined"
                  onChangeText={value => {
                    setSocialAccount({ ...socialAccount, facebook: value })
                    setSocialErrors({
                      ...socialErrors,
                      facebook: validateUrl(value, 'facebook'),
                    })
                  }}
                  error={!!socialErrors.facebook}
                />
                {!!socialErrors.facebook && (
                  <Text
                    style={[
                      Gutters.tinyVMargin,
                      { color: Colors.snackbar.error },
                    ]}
                  >
                    {socialErrors.facebook}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBMargin]}
            >
              <Image
                source={Images.profile_main_instagram_icon}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <TextInput
                label="Instagram"
                placeholder="@username"
                value={socialAccount.instagram}
                autoFocus={true}
                style={[{ color: Colors.white }, styles.socialInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                onChangeText={value => {
                  setSocialAccount({ ...socialAccount, instagram: value })
                }}
              />
            </View>
            <View
              style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBMargin]}
            >
              <Image
                source={Images.profile_main_threads_icon}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <TextInput
                label="Threads"
                placeholder="@username"
                value={socialAccount.threads}
                autoFocus={true}
                style={[{ color: Colors.white }, styles.socialInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                onChangeText={value =>
                  setSocialAccount({ ...socialAccount, threads: value })
                }
              />
            </View>
            <View
              style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBMargin]}
            >
              <Image
                source={Images.profile_main_youtube_icon}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <View style={[Layout.fullWidth]}>
                <TextInput
                  label="Youtube"
                  placeholder="https://www.youtube.com/channel/"
                  value={socialAccount.youtube}
                  autoFocus={true}
                  style={[{ color: Colors.white }, styles.socialInput]}
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  activeOutlineColor={Colors.primary}
                  theme={{
                    colors: {
                      onSurfaceVariant: Colors.fontText.light.primary3,
                      error: Colors.snackbar.error,
                      outline: Colors.background.top,
                    },
                    roundness: 10,
                  }}
                  textColor="#fff"
                  mode="outlined"
                  onChangeText={value => {
                    setSocialAccount({ ...socialAccount, youtube: value })
                    setSocialErrors({
                      ...socialErrors,
                      youtube: validateUrl(value, 'youtube'),
                    })
                  }}
                  error={!!socialErrors.youtube}
                />
                {!!socialErrors.youtube && (
                  <Text
                    style={[
                      Gutters.tinyVMargin,
                      { color: Colors.snackbar.error },
                    ]}
                  >
                    {socialErrors.youtube}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBMargin]}
            >
              <Image
                source={Images.profile_main_website_icon}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <View style={[Layout.fullWidth]}>
                <TextInput
                  label="Website"
                  placeholder="https://www.example.com/"
                  value={socialAccount.website}
                  autoFocus={true}
                  style={[{ color: Colors.white }, styles.socialInput]}
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  activeOutlineColor={Colors.primary}
                  theme={{
                    colors: {
                      onSurfaceVariant: Colors.fontText.light.primary3,
                      error: Colors.snackbar.error,
                      outline: Colors.background.top,
                    },
                    roundness: 10,
                  }}
                  textColor="#fff"
                  mode="outlined"
                  onChangeText={value => {
                    setSocialAccount({ ...socialAccount, website: value })
                    setSocialErrors({
                      ...socialErrors,
                      website: validateUrl(value, 'website'),
                    })
                  }}
                  error={!!socialErrors.website}
                />
                {!!socialErrors.website && (
                  <Text
                    style={[
                      Gutters.tinyVMargin,
                      { color: Colors.snackbar.error },
                    ]}
                  >
                    {socialErrors.website}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }

  const renderContent = (nowStep: string) => {
    const render: Record<string, any> = {
      editProfile: renderEditProfile(),
    }
    return render[nowStep]
  }

  return (
    <View
      style={[
        Layout.fill,
        Layout.justifyContentBetween,
        isFullScreen && Gutters.largeTPadding,
        { backgroundColor: Colors.background.default },
      ]}
    >
      {renderContent(step)}
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    textInput: {
      backgroundColor: Colors.background.default,
      height: 56,
    },
    socialInput: {
      backgroundColor: Colors.background.default,
      height: 56,
      width: '90%',
    },
    textInputBio: {
      backgroundColor: Colors.background.default,
      maxHeight: 195,
    },
  })

export default EditProfileContainer
