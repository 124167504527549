import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
} from 'react-native'
import { useDispatch } from 'react-redux'
import Geolocation from '@react-native-community/geolocation'
import dayjs from 'dayjs'

import { useTheme } from '@/Hooks'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import {
  FadeInView,
  Header,
  LoadingComponent,
  WarningSignComponent,
} from '@/Components'
import { navigate } from '@/Navigators/utils'
import { PagesEnum } from '@/Navigators/Application'
import loyaltyProgramsApi from '@/Services/modules/loyaltyPrograms'
import { globalActions } from '@/Store/Global'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { capitalizedSentence, removeEmptyKeys } from '@/Util/global'

const CouponDetailContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()
  const dispatch = useDispatch()
  const { id } = route.params

  const { data: couponDetail, isLoading } =
    loyaltyProgramsApi.useGetCouponDetailQuery({ id })

  const [
    redeemCouponRequest,
    {
      isLoading: isRedeeming,
      isSuccess: isRedeemSuccess,
      isError: isRedeemError,
      reset: resetRedeem,
    },
  ] = loyaltyProgramsApi.useRedeemCouponMutation({
    fixedCacheKey: 'shared-redeem-coupon',
  })

  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    if (isRedeemSuccess) {
      resetRedeem()
      navigation.navigate(AuthPagesEnum.CouponRedeemContainer, {
        id,
        couponName: couponDetail?.data.program.couponName,
      })
    }
  }, [isRedeemSuccess, resetRedeem, id, navigation, couponDetail])

  useEffect(() => {
    if (isRedeemError) {
      navigation.navigate(AuthPagesEnum.CouponResultContainer, {
        type: 'failed',
      })
      resetRedeem()
    }
  }, [isRedeemError, navigation, resetRedeem])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const getGeolocation = () => {
    return new Promise<{ latitude: number; longitude: number } | null>(
      resolve => {
        Geolocation.getCurrentPosition(
          position => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          },
          () => {
            resolve(null)
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        )
      },
    )
  }

  const handleSubmit = useCallback(async () => {
    try {
      if (couponDetail) {
        setSubmitLoading(true)
        dispatch(globalActions.closeBottomDialog())
        const location = await getGeolocation()
        const payload = {
          id,
          programId: couponDetail.data.program.id,
          metadata: {
            latitude: location?.latitude,
            longitude: location?.longitude,
          },
        }
        redeemCouponRequest(removeEmptyKeys(payload))
      }
    } catch (error) {
      console.error(error)
      const payload = {
        id,
        programId: couponDetail?.data.program.id,
        metadata: {
          latitude: null,
          longitude: null,
        },
      }
      redeemCouponRequest(removeEmptyKeys(payload))
    } finally {
      setSubmitLoading(false)
    }
  }, [couponDetail, id, redeemCouponRequest, dispatch])

  const renderCouponDetail = (title: string, content: string) => {
    return (
      <View style={[Gutters.smallBMargin]}>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            Gutters.miniBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {title}
        </Text>
        <Text
          style={[
            Fonts.size14,
            Fonts.weight400,
            { color: Colors.fontText.light.primary3 },
          ]}
        >
          {content}
        </Text>
      </View>
    )
  }

  const renderCouponOtherSpirits = (
    title: string,
    spirits: { id: string; title: string }[],
  ) => {
    return (
      <View style={[Gutters.smallBMargin]}>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            Gutters.miniBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {title}
        </Text>
        {spirits.map(spirit => {
          return (
            <TouchableOpacity
              onPress={() => {
                navigate(PagesEnum.ProductDetail, {
                  id: spirit.id,
                })
              }}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.primary },
                ]}
              >
                {capitalizedSentence(spirit.title)}
              </Text>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  const renderCTAButton = () => {
    return (
      <TouchableOpacity
        style={[
          styles.ctaButton,
          {
            backgroundColor:
              couponDetail?.data.usable || isRedeeming
                ? Colors.primary
                : Colors.gray,
          },
        ]}
        disabled={!couponDetail?.data.usable || isRedeeming}
        onPress={handleOpenRedeemDialog}
      >
        {isRedeeming || submitLoading ? (
          <ActivityIndicator size={'large'} color={Colors.white} />
        ) : (
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.fontText.dark.primary2 },
            ]}
          >
            立即兌換（請由店員操作)
          </Text>
        )}
      </TouchableOpacity>
    )
  }

  const handleCloseDialog = useCallback(() => {
    dispatch(globalActions.closeBottomDialog())
  }, [dispatch])

  // 打開領取的 dialog
  const handleOpenRedeemDialog = useCallback(() => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        disabledBackgroundClose: true,
        handleClickBackground: handleCloseDialog,
        content: (
          <View style={[Layout.center, Gutters.regularVMargin]}>
            <View
              style={[
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.regularHPadding,
              ]}
            >
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                確定核銷？
              </Text>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  Gutters.regularBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                （請由店員操作）
              </Text>
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size14,
                  { color: Colors.snackbar.error },
                ]}
              >
                *若自行兌領則視同放棄，恕不補發
              </Text>
            </View>
            <View
              style={[
                {
                  backgroundColor: Colors.fontText.light.primary2,
                  height: 1,
                  width: '100%',
                  marginVertical: 16,
                },
              ]}
            />
            <View style={[Layout.fullWidth, Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.primary,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                disabled={isRedeeming}
                onPress={handleSubmit}
              >
                {isRedeeming ? (
                  <ActivityIndicator size={'large'} color={Colors.white} />
                ) : (
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size16,
                      { color: Colors.fontText.dark.primary2 },
                    ]}
                  >
                    確認
                  </Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                onPress={handleCloseDialog}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  返回
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [
    Fonts.size14,
    Fonts.size16,
    Fonts.size20,
    Fonts.weight400,
    Fonts.weight500,
    Fonts.weight700,
    Gutters.regularBMargin,
    Gutters.regularHPadding,
    Gutters.regularVMargin,
    Gutters.smallBMargin,
    Gutters.tinyVPadding,
    Layout.alignItemsCenter,
    Layout.center,
    Layout.fullWidth,
    dispatch,
    handleCloseDialog,
    handleSubmit,
    isRedeeming,
  ])

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      {(isRedeeming || submitLoading) && <LoadingComponent />}
      <Header
        title={'優惠券詳情'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        {isLoading ? (
          <View style={[Layout.center, Layout.fill]}>
            <ActivityIndicator color={Colors.primary} size={'large'} />
          </View>
        ) : (
          <View
            style={[
              Layout.fill,
              Gutters.regularHPadding,
              Gutters.smallTPadding,
            ]}
          >
            <View
              style={[
                Gutters.smallBMargin,
                Gutters.smallVPadding,
                Gutters.smallHPadding,
                { backgroundColor: Colors.background.surface, borderRadius: 8 },
              ]}
            >
              <View style={[Layout.row, Layout.justifyContentBetween]}>
                <Text
                  style={[
                    Fonts.size20,
                    Fonts.weight500,
                    {
                      color: Colors.fontText.light.primary2,
                    },
                  ]}
                >
                  {couponDetail?.data.program.couponName || ''}
                </Text>
                <Image
                  source={Images.coupon_list_icon}
                  style={[Gutters.regularLMargin, Layout.iconSize56]}
                  resizeMode="contain"
                />
              </View>
            </View>
            {renderCouponDetail(
              '適用於',
              couponDetail?.data.program.applicableTarget || '',
            )}
            {renderCouponDetail(
              '使用效期',
              `${dayjs(couponDetail?.data.startedAt).format(
                'YYYY/MM/DD:HH:mm',
              )}起~迄${dayjs(couponDetail?.data.expiresAt).format(
                'YYYY/MM/DD:HH:mm',
              )}`,
            )}
            {!!couponDetail?.data?.program?.spirits?.length &&
              renderCouponOtherSpirits(
                '適用品項',
                couponDetail?.data.program.spirits || [],
              )}
            {renderCouponDetail(
              '使用規則',
              couponDetail?.data.program.couponRules || '',
            )}
            {renderCouponDetail(
              '使用說明',
              couponDetail?.data.program.couponInstructions || '',
            )}
            {renderCTAButton()}
          </View>
        )}
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    ctaButtonContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    ctaButton: {
      marginTop: 'auto',
      backgroundColor: Colors.primary,
      borderRadius: 8,
      padding: 16,
      alignItems: 'center',
      marginBottom: 16,
    },
  })

export default CouponDetailContainer
