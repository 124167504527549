import React, { useCallback, useMemo } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  ScrollView,
  Dimensions,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { AutoHeightImage, Header } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

const ImagesContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Fonts } = useTheme()
  const { imagePath } = route.params

  const imageWidth = useMemo(() => {
    const desktop = Dimensions.get('window').width > 430
    return desktop ? 430 : Dimensions.get('window').width
  }, [])

  const handleRedirectToHome = useCallback(() => {
    navigation.navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Home,
    })
  }, [navigation])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    handleRedirectToHome()
  }, [navigation, handleRedirectToHome])

  if (!Array.isArray(imagePath)) {
    handleRedirectToHome()
  }

  return (
    <ScrollView
      style={[
        Layout.fill,
        { flex: 1, backgroundColor: Colors.background.surface },
      ]}
    >
      <Header
        title={'相片'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
      />
      <View
        style={[
          Layout.fill,
          { flex: 1, minHeight: Dimensions.get('window').height - 60 },
          { backgroundColor: Colors.background.surface },
        ]}
      >
        {Array.isArray(imagePath) ? (
          imagePath.map((image: string, index: number) => (
            <View key={index} style={[styles.imageContainer]}>
              <AutoHeightImage
                width={imageWidth}
                source={image}
                resizeMode="contain"
                onHeightChange={() => {}}
                maxHeight={900}
              />
            </View>
          ))
        ) : (
          <View style={[Layout.fill, Layout.colCenter]}>
            <TouchableOpacity onPress={handleRedirectToHome}>
              <Text
                style={[Fonts.size20, Fonts.weight700, { color: Colors.white }]}
              >
                返回首頁
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  arrowLeftIcon: {
    width: 24,
    height: 24,
  },
  imageContainer: {
    marginBottom: 32,
  },
})

export default ImagesContainer
