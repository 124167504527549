import React from 'react'
import {
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  ViewStyle,
  ImageStyle,
} from 'react-native'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'

import {
  AdvertiseItem,
  AdvertiseSpaceEnum,
  destinationTypeEnum,
  templateModeEnum,
} from '@/Services/advertiseApi'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { navigate } from '@/Navigators/utils'
import { PagesEnum } from '@/Navigators/Application'
import { advertiseActions } from '@/Store/Advertise'

interface AdvertiseComponentProps {
  data: AdvertiseItem
  space: AdvertiseSpaceEnum
  customCardStyle?: ViewStyle
  customImageStyle?: ImageStyle
  customImageContainerStyle?: ViewStyle
  customSponsorTagStyle?: ViewStyle
  onClick?: (id: string) => void
}

const AdvertiseComponent = ({
  data,
  customCardStyle,
  space,
  customImageStyle,
  customImageContainerStyle,
  customSponsorTagStyle,
  onClick,
}: AdvertiseComponentProps) => {
  const { Layout, Gutters, Fonts } = useTheme()
  const dispatch = useDispatch()

  const { ref: inViewRef, inView } = useInView({
    /* 可選配置 */
    threshold: 1, // 當元素 100% 進入畫面時觸發
    triggerOnce: true, // 只觸發一次
  })

  const getInternalRedirectUrl = (url: string) => {
    try {
      const urlObj = new URL(decodeURIComponent(url))
        .toString()
        .split('&')[0]
        .split('/')
        ?.slice(-3)
      return urlObj
    } catch (error) {
      console.error('Invalid URL:', error)
      return url
    }
  }

  const handleInternalRedirect = (url: string) => {
    const urlObj = new URL(
      decodeURIComponent(url.split('destinationUrl=')[1] || ''),
    )
    // 文字搜尋頁
    if (urlObj.pathname === '/SearchList') {
      navigate(PagesEnum.SearchListContainer, {
        ...Object.fromEntries(urlObj.searchParams),
      })
      return
    }
    // 進階搜尋頁
    if (urlObj.pathname === '/FilterSearchListContainer') {
      navigate(PagesEnum.FilterSearchListContainer, {
        ...Object.fromEntries(urlObj.searchParams),
      })
      return
    }
    // 商品詳細頁
    const [lang, slug, urlId] = getInternalRedirectUrl(urlObj.href)
    navigate(PagesEnum.ProductDetail, {
      lang,
      id: urlId,
      titleUrlSlug: slug,
    })
  }

  const handleClick = (id: string) => {
    if (onClick !== undefined) {
      onClick(id)
    }
    if (data.destinationType === destinationTypeEnum.EXTERNAL_SITE) {
      // @ts-ignore
      window.open(data.destinationUrl, '_blank')
    }
    if (data.destinationType === destinationTypeEnum.INTERNAL_PAGE) {
      handleInternalRedirect(data.destinationUrl)
    }
    if (data.destinationType === destinationTypeEnum.INTERNAL_CAMPAIGN) {
      const iframeUrl = data.destinationUrl
      dispatch(advertiseActions.setCampaignSiteAdvertiseURL(iframeUrl))
      navigate(PagesEnum.CampaignSite, {})
    }
  }

  if (data.templateMode === templateModeEnum.IMAGE) {
    return (
      <TouchableOpacity
        ref={inViewRef}
        style={[Layout.fullSize, customImageContainerStyle]}
        onPress={() => handleClick(data.id)}
      >
        <Image
          source={{ uri: data.images[0].url }}
          resizeMode="cover"
          style={[Layout.fullSize, customImageStyle]}
        />
        {/* 如果不在畫面需隱藏 */}
        {inView && (
          <Image
            style={[styles.impressionUrl]}
            source={{ uri: data.impressionUrl }}
          />
        )}
      </TouchableOpacity>
    )
  }

  if (data.templateMode === templateModeEnum.CARD) {
    return (
      <TouchableOpacity
        ref={inViewRef}
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          styles.cardContainer,
          customCardStyle,
        ]}
        onPress={() => {
          handleClick(data.id)
        }}
      >
        <Image
          source={{ uri: data.images[0].url }}
          resizeMode="cover"
          style={[styles.cardImage, Gutters.smallRMargin]}
        />
        <View style={[Layout.fullHeight, styles.cardContent]}>
          <View
            style={[
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
              Gutters.miniBMargin,
            ]}
          >
            <Text
              style={[
                Fonts.size12,
                Fonts.weight400,
                { color: Colors.fontText.light.primary3 },
              ]}
            >
              {data.contents.brand}
            </Text>
            <View
              style={[
                Layout.row,
                Layout.alignItemsCenter,
                styles.sponsorTag,
                customSponsorTagStyle,
              ]}
            >
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                贊助
              </Text>
            </View>
          </View>
          <Text
            numberOfLines={2}
            style={[
              Fonts.size16,
              Fonts.weight500,
              Gutters.miniBMargin,
              { color: Colors.fontText.light.primary2, lineHeight: 20 },
            ]}
          >
            {data.contents.title}
          </Text>
          <Text
            numberOfLines={2}
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3, lineHeight: 20 },
            ]}
          >
            {data.contents.description}
          </Text>
          {/* 如果不在畫面需隱藏 */}
          {inView && (
            <Image
              ref={inViewRef}
              style={[styles.impressionUrl]}
              source={{ uri: data.impressionUrl }}
            />
          )}
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <View style={[styles.cardContainer]}>
      <Text
        style={[
          Fonts.size16,
          Fonts.weight500,
          { color: Colors.fontText.light.primary2 },
        ]}
      >
        Unknown template mode
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  impressionUrl: {
    width: 1,
    height: 1,
  },
  cardContainer: {
    width: '100%',
    height: 140,
    padding: 16,
    backgroundColor: Colors.background.surface,
  },
  cardImage: {
    width: 100,
    height: 100,
    borderRadius: 8,
  },
  cardContent: {
    width: '70%',
  },
  sponsorTag: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderRadius: 32,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 4,
  },
})

export default React.memo(AdvertiseComponent)
