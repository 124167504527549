import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TextInput,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { PagesEnum } from '@/Navigators/Application'
import { LoadingComponent } from '@/Components'
import { capitalizedSentence } from '@/Util/global'

import useGetPairingsData, {
  PairingDataItem,
} from '../hooks/useGetPairingsData'
import BottomSubmitButton from '../components/bottomSubmitButton'

const PairingFilterModal = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const { pairingsData, filterMetaDataIsLoading, pairingDataIsLoading } =
    useGetPairingsData()
  const styles = getStyle()

  const [initialData, setInitialData] = useState<PairingDataItem[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState<PairingDataItem[]>([])
  const [pairingNewSelectedItems, setPairingNewSelectedItems] = useState<
    string[]
  >([])

  const isSubmitBtnDisabled = useMemo(() => {
    return !pairingNewSelectedItems.length
  }, [pairingNewSelectedItems])

  const resetButtonDisabled = useMemo(() => {
    return !pairingNewSelectedItems.length
  }, [pairingNewSelectedItems])

  const handleGoBack = useCallback(() => {
    navigation.navigate(PagesEnum.MainFilterModal, {
      ...route.params,
    })
  }, [navigation, route.params])

  const handleReset = useCallback(() => {
    setSearchText('')
    setSearchResult(initialData)
    navigation.setParams({ pairings: undefined })
  }, [initialData, navigation])

  const handleSelect = (itemKey: string) => {
    const newSelectedItems = pairingNewSelectedItems.includes(itemKey)
      ? pairingNewSelectedItems.filter((key: string) => key !== itemKey)
      : [...pairingNewSelectedItems, itemKey]

    setPairingNewSelectedItems(newSelectedItems)
  }

  const handleSubmit = useCallback(() => {
    const filter = {
      ...route.params,
      pairings: pairingNewSelectedItems,
    }

    navigation.navigate(PagesEnum.MainFilterModal, {
      ...filter,
    })
  }, [pairingNewSelectedItems, navigation, route.params])

  useEffect(() => {
    if (pairingsData) {
      // 讓被選擇的品項排在最前面
      const selectedItems = pairingsData.filter(item =>
        route?.params?.pairings?.includes(item.i18nKey),
      )
      const unselectedItems = pairingsData.filter(
        item => !route?.params?.pairings?.includes(item.i18nKey),
      )
      setInitialData([...selectedItems, ...unselectedItems])
      setPairingNewSelectedItems(selectedItems.map(item => item.i18nKey))
    }
  }, [pairingsData, route.params])

  useEffect(() => {
    const filteredResults = searchText
      ? initialData.filter(
          item =>
            item.zhName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.enName.toLowerCase().includes(searchText.toLowerCase()),
        )
      : initialData

    setSearchResult(filteredResults)
  }, [initialData, searchText])

  if (filterMetaDataIsLoading || pairingDataIsLoading) {
    return (
      <View style={[styles.container]}>
        <LoadingComponent />
      </View>
    )
  }

  return (
    <View style={[Layout.fill]}>
      <ScrollView style={[styles.container]}>
        {/* header */}
        <View style={[styles.header]}>
          <TouchableOpacity onPress={handleGoBack}>
            <Image
              style={[styles.closeIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <Text
            style={[
              Fonts.weight600,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            餐酒搭配
          </Text>
          <TouchableOpacity
            onPress={handleReset}
            disabled={resetButtonDisabled}
          >
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                {
                  color: resetButtonDisabled
                    ? Colors.fontText.disabled
                    : Colors.primary,
                },
              ]}
            >
              重設
            </Text>
          </TouchableOpacity>
        </View>
        {/* header */}
        <View style={[Gutters.regularHPadding]}>
          <View style={[styles.searchBar]}>
            <Image source={Images.search} style={[Layout.iconSize24]} />
            <TextInput
              style={[
                styles.searchInput,
                { color: Colors.fontText.light.primary2 },
              ]}
              value={searchText}
              onChangeText={setSearchText}
              placeholder="搜尋餐酒搭配"
              placeholderTextColor={Colors.fontText.light.primary3}
            />
          </View>
          <View style={[styles.sectionContent]}>
            {searchResult.map(item => {
              const isSelected = pairingNewSelectedItems.includes(item.i18nKey)
              return (
                <TouchableOpacity
                  onPress={() => handleSelect(item.i18nKey)}
                  style={[
                    styles.sectionContentItem,
                    isSelected && styles.sectionContentItemSelected,
                  ]}
                  key={item.i18nKey}
                >
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size14,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {capitalizedSentence(item.zhName)}
                  </Text>
                  <Text
                    style={[
                      Fonts.weight300,
                      Fonts.size14,
                      Gutters.miniTMargin,
                      {
                        color: isSelected
                          ? Colors.fontText.dark.primary2
                          : Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {`(${item.count})`}
                  </Text>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
      </ScrollView>
      <BottomSubmitButton
        handleSubmit={handleSubmit}
        disabled={isSubmitBtnDisabled}
        title="選取完成"
      />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.background.surface,
      height: Dimensions.get('window').height,
      paddingBottom: 150,
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 30,
      borderBottomWidth: 1,
      borderColor: Colors.background.top,
    },
    searchBar: {
      marginTop: 24,
      marginBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 16,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: Colors.background.top,
      borderRadius: 8,
    },
    searchInput: {
      flex: 1,
      marginLeft: 10,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    sectionContent: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
    },
    sectionContentItem: {
      boxSizing: 'border-box',
      width: '48.5%',
      borderWidth: 1,
      borderColor: Colors.background.top,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 8,
    },
    sectionContentItemSelected: {
      backgroundColor: Colors.primary,
      borderColor: 'none',
    },
  })

export default PairingFilterModal
