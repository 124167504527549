import React, { useCallback, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
  TextInput,
} from 'react-native'
import { useFlavorData, useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { useTranslation } from 'react-i18next'
import { spiritActions } from '@/Store/Spirit'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

import FlavorSelectCard from './components/FlavorSelectCard'

const FlavorContainer = ({ route, navigation }: any) => {
  const { id, mySpiritId, titleUrlSlug } = route.params
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { flavors: selectedFlavorList } = useSelector(
    (state: RootState) => state.spirit.spirit,
  )
  const { result: FlavorData, handleGetFlavorColor } = useFlavorData()

  const flavorListRef = useRef<any>()

  // 取得該支酒的個人資訊，包括是否有收藏、個人評論
  spiritsApi.useGetSpiritPersonalInfoQuery({
    id: route.params?.id,
  })

  // 取得風味資訊
  noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const styles = getStyle()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  const handleSaveFlavorDataInStore = (data: string[]) => {
    dispatch(
      spiritActions.setSpiritData({
        flavors: data,
      }),
    )
  }

  const handleSelectFlavorItem = (flavor: string) => {
    if (selectedFlavorList?.includes(flavor)) {
      const newSelectedFlavorList = selectedFlavorList?.filter(
        item => item !== flavor,
      )
      handleSaveFlavorDataInStore(newSelectedFlavorList)
      return
    }
    handleSaveFlavorDataInStore([...(selectedFlavorList || []), flavor])
  }

  const handleRemoveSelectFlavorItem = (flavor: string) => {
    const newSelectedFlavorList = selectedFlavorList?.filter(
      item => item !== flavor,
    )
    handleSaveFlavorDataInStore(newSelectedFlavorList || [])
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView
        style={(Layout.fill, { height: Dimensions.get('window').height })}
        stickyHeaderIndices={[2]}
        showsVerticalScrollIndicator={false}
      >
        <Header
          title="風味標記"
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <View style={[Gutters.smallHPadding]}>
          <TouchableOpacity
            style={[Layout.row, styles.searchInputContainer]}
            onPress={() =>
              navigation.navigate(AuthPagesEnum.FlavorSearch, {
                id,
                mySpiritId,
                titleUrlSlug,
              })
            }
          >
            <Image
              style={Layout.iconSize24}
              source={Images.home_search}
              resizeMode="contain"
            />
            <TextInput
              style={[styles.searchInput, { color: Colors.white }]}
              placeholder="搜尋風味"
              placeholderTextColor={Colors.gray}
            />
          </TouchableOpacity>
        </View>
        <View
          ref={flavorListRef}
          style={[
            Gutters.tinyBMargin,
            styles.selectedFlavorContainer,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          {/* 已標注風味 */}
          <View style={[Gutters.smallHPadding, Gutters.smallVPadding]}>
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                { color: Colors.secondaryText },
              ]}
            >
              已標注風味
            </Text>
          </View>
          {/* 被選取的風味列表 */}
          <View
            style={[
              Layout.row,
              Gutters.smallBPadding,
              Gutters.smallHPadding,
              styles.flavorItemContainer,
            ]}
          >
            {selectedFlavorList?.length ? (
              selectedFlavorList?.map(item => {
                const color = handleGetFlavorColor(item)
                return (
                  <View
                    key={item}
                    style={[
                      Layout.row,
                      Layout.alignItemsCenter,
                      styles.flavorSelectedItem,
                    ]}
                  >
                    <View
                      style={[
                        Gutters.miniRMargin,
                        styles.flavorDot,
                        { backgroundColor: color },
                      ]}
                    />
                    <Text
                      style={[
                        Fonts.size14,
                        Fonts.weight700,
                        Gutters.miniRMargin,
                        {
                          color: Colors.fontText.light.primary2,
                        },
                      ]}
                    >
                      {t(`flavor-wheel:${item}`)}
                    </Text>
                    <TouchableOpacity
                      onPress={() => handleRemoveSelectFlavorItem(item)}
                    >
                      <Image
                        source={Images.close}
                        resizeMode="contain"
                        style={Layout.iconSize14}
                      />
                    </TouchableOpacity>
                  </View>
                )
              })
            ) : (
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size14,
                  { color: Colors.darkText },
                ]}
              >
                尚無紀錄
              </Text>
            )}
          </View>
        </View>
        <View style={{ backgroundColor: Colors.background.surface }}>
          {/* 風味分類 */}
          <View style={[Gutters.smallHPadding, Gutters.smallVPadding]}>
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                { color: Colors.secondaryText },
              ]}
            >
              風味分類
            </Text>
          </View>
          <FadeInView duration={350}>
            <View style={[Gutters.smallHPadding, Gutters.smallTMargin]}>
              {FlavorData.map((item: any) => (
                <FlavorSelectCard
                  key={item.id}
                  data={item}
                  selectedFlavorList={selectedFlavorList || []}
                  handleGetFlavorColor={handleGetFlavorColor}
                  handleSelectFlavorItem={handleSelectFlavorItem}
                  handleRemoveSelectFlavorItem={handleRemoveSelectFlavorItem}
                  Images={Images}
                />
              ))}
            </View>
          </FadeInView>
        </View>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    letterSpacing10: {
      letterSpacing: 10,
    },
    marginB100: { marginBottom: 100 },
    headerContainer: {
      backgroundColor: Colors.background.default,
      borderBottomColor: Colors.darkText,
      borderBottomWidth: 1,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    searchInputContainer: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      alignItems: 'center',
      paddingLeft: 12,
      borderWidth: 1,
      borderColor: Colors.gray,
      marginBottom: 16,
    },
    searchInput: {
      width: '100%',
      marginLeft: 4,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    flavorSelectedItem: {
      width: 'fit-content',
      marginLeft: 16,
      marginBottom: 16,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 80,
      backgroundColor: Colors.darkLight,
      borderWidth: 1,
      borderColor: Colors.primary,
    },
    flavorTitle: {
      lineHeight: 24,
      fontFamily: 'Poppins',
    },
    flavorDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
    selectedFlavorContainer: {
      shadowColor: '#00000040',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.8,
      shadowRadius: 3.84,
      elevation: 5,
    },
    flavorItemContainer: {
      flexWrap: 'wrap',
    },
  })

export default FlavorContainer
