import { getConfig } from '@/Util/global'

import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
// import AsyncStorage from '@react-native-async-storage/async-storage'
import { globalActions } from '@/Store/Global'

const baseQuery = fetchBaseQuery({
  baseUrl: getConfig().ENV === 'local' ? '' : `${getConfig().API_URL}`,
  prepareHeaders: async (headers, { getState }: any) => {
    // TODO: 因為 SPA 先不用 token，透過 cookie 來驗證，所以先註解掉，等到改回 mobile 再開啟
    // const authToken = await AsyncStorage.getItem('auth-token')
    // header.set('Content-Type', 'application/json;charset=UTF-8')
    // if (authToken) {
    //   header.set('Authorization', `Bearer ${authToken}`)
    // }
    const { language: locale } = getState().theme
    // 如果個別 api 沒有設置語言，這邊統一設置
    if (locale && !headers.has('Accept-Language')) {
      headers.set('Accept-Language', locale)
    }
    return headers
  },
  credentials: 'include',
})

export const prefixProxyEndpoint = (url: string) => {
  return getConfig().ENV === 'local' ? `/proxy-api${url}` : url
}

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  const { dispatch } = api
  // @ts-ignore
  const errorMessage = result?.error?.data?.errors?.[0]?.message

  if (result.error && result.error.status === 401) {
  }

  if (Number(result?.error?.originalStatus) >= 500) {
    dispatch(
      globalActions.openSnackbar({
        visible: true,
        message: '系統異常，請稍後再試！',
        type: 'error',
        showIcon: false,
      }),
    )
  }
  return result
}

interface SiteMeta {
  features: {
    invitation: boolean
    assistant: boolean
  }
  clientVersions: {
    forceUpdate: {
      ios: string
      android: string
    }
  }
  siteStatus: 'operational' | 'inMaintenance'
}

export type TagTypes =
  | 'userInfo'
  | 'spirits'
  | 'note'
  | 'history'
  | 'achievements'
  | 'threads'
  | 'loyaltyPrograms'

const TAG_TYPES: TagTypes[] = [
  'spirits',
  'userInfo',
  'note',
  'history',
  'achievements',
  'threads',
  'loyaltyPrograms',
]

export const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: build => ({
    getSiteMeta: build.query<SiteMeta, void>({
      query: () => ({
        url: prefixProxyEndpoint('/v1/meta'),
        method: 'GET',
      }),
    }),
  }),
  tagTypes: TAG_TYPES,
})
