import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  TextInput,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { useSelector, useDispatch } from 'react-redux'
import { FadeInView, Header } from '@/Components'
import { useTranslation } from 'react-i18next'
import { spiritActions } from '@/Store/Spirit'
import { globalActions } from '@/Store/Global'
import { RootState } from '@/Store'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'

const CommentModal = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const styles = getStyle()

  const commentInputRef = useRef<any>(null)

  const { spirit: spiritData } = useSelector((state: RootState) => state.spirit)

  const [content, setContent] = useState<string>('')

  // 返回 review detail 頁面
  const navigateToReviewDetail = useCallback(() => {
    const routeParams = {
      ...route.params,
      page: route.params.page !== undefined ? route.params.page : '',
    }
    navigation.navigate(AuthPagesEnum.ReviewDetail, routeParams)
  }, [route.params, navigation])

  // 儲存內容
  const saveContent = useCallback(() => {
    dispatch(
      spiritActions.setSpiritData({
        content: content === '' ? ' ' : content,
      }),
    )
  }, [dispatch, content])

  // 提醒尚未儲存 dialog
  const openWarningDialog = useCallback((): void => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '尚未儲存',
        titleStyle: {
          fontSize: 20,
          fontWeight: '700',
        },
        disabledBackgroundClose: false,
        content: (
          <View style={[Layout.center, Gutters.smallBMargin]}>
            <View
              style={[
                Gutters.regularBPadding,
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                {
                  borderBottomWidth: BorderWidth.width2,
                  borderBottomColor: Colors.darkText,
                },
              ]}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                尚有編輯過的筆記沒有儲存，返回時要進行儲存嗎？
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                    backgroundColor: Colors.primary,
                  },
                ]}
                onPress={() => {
                  saveContent()
                  navigateToReviewDetail()
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight500,
                    {
                      color: Colors.fontText.dark.primary2,
                    },
                  ]}
                >
                  儲存並返回
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                    backgroundColor: Colors.transparent,
                    borderWidth: BorderWidth.width1,
                    borderColor: Colors.border.default,
                  },
                ]}
                onPress={() => {
                  navigateToReviewDetail()
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight500,
                    {
                      color: Colors.fontText.light.primary2,
                    },
                  ]}
                >
                  不儲存
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  { height: Height.height48 },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight500,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [dispatch, Layout, Gutters, Fonts, navigateToReviewDetail, saveContent])

  // 返回 review detail 頁面，添加檢查是否有未儲存的內容
  const handleBackToReviewDetail = useCallback((): void => {
    if (content !== spiritData.content) {
      openWarningDialog()
      return
    }

    navigateToReviewDetail()
  }, [content, spiritData.content, openWarningDialog, navigateToReviewDetail])

  // 取得 content 資料
  useEffect(() => {
    if (spiritData.content) {
      setContent(spiritData.content)
    } else {
      setContent('')
    }
  }, [spiritData.content])

  // 顯示空模板警告
  const showWarningEmptyTemplateDialog = useCallback((): void => {
    dispatch(
      globalActions.openSnackbar({
        visible: true,
        message: '模板尚無內容，請輸入模板文字',
        type: 'error',
        showIcon: false,
      }),
    )
  }, [dispatch])

  const handleSaveTemplate = useCallback(() => {
    if (content.trim() === '') {
      showWarningEmptyTemplateDialog()
      return
    }
    saveContent()
    navigateToReviewDetail()
  }, [
    content,
    saveContent,
    navigateToReviewDetail,
    showWarningEmptyTemplateDialog,
  ])

  // 建立板模按鈕區域
  const renderTemplateContainer = useCallback(() => {
    return (
      <View
        style={[
          Layout.fullWidth,
          Layout.row,
          Gutters.smallVPadding,
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <TouchableOpacity
          style={[Layout.row, Layout.alignItemsCenter, Gutters.largeRMargin]}
          onPress={() => {
            navigation.navigate(AuthPagesEnum.CommentTemplateModal, {
              ...route.params,
            })
          }}
        >
          <Image
            source={Images.comment_modal_my_template}
            resizeMode="contain"
            style={[Layout.iconSize24, Gutters.tinyRMargin]}
          />
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            我的模板
          </Text>
        </TouchableOpacity>
      </View>
    )
  }, [Layout, Gutters, Images, Fonts, navigation, route.params])

  return (
    <KeyboardAvoidingView
      style={[Layout.fill]}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 50}
    >
      <View
        style={[Layout.fill, { backgroundColor: Colors.background.surface }]}
      >
        <FadeInView duration={250}>
          <Header
            title={t('commentModal.headerTitle')}
            leftIcon={
              <Image
                style={[styles.arrowLeftIcon]}
                source={Images.arrowLeft}
                resizeMode="contain"
              />
            }
            leftIconPress={() => {
              handleBackToReviewDetail()
            }}
            rightEmptyIconWidth="50"
          />
          {renderTemplateContainer()}
        </FadeInView>
        <ScrollView keyboardDismissMode="interactive">
          <FadeInView duration={350} style={Layout.fullHeight}>
            <View style={[Gutters.regularHPadding, Gutters.regularVPadding]}>
              <TextInput
                ref={commentInputRef}
                placeholder={t('commentModal.flavorPlaceholder')}
                placeholderTextColor="#999999"
                multiline={true}
                numberOfLines={30}
                style={[Gutters.smallBMargin, styles.inputContainer]}
                value={content}
                onChangeText={text => {
                  setContent(text)
                }}
              />
            </View>
          </FadeInView>
        </ScrollView>
        <FadeInView duration={550}>
          <View style={[Gutters.regularHPadding]}>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.rowCenter,
                Gutters.regularBMargin,
                styles.buttonContainer,
              ]}
              onPress={() => {
                handleSaveTemplate()
              }}
            >
              <Text
                style={[
                  Fonts.textCenter,
                  Fonts.bold,
                  {
                    color: Colors.fontText.dark.primary2,
                  },
                ]}
              >
                完成
              </Text>
            </TouchableOpacity>
          </View>
        </FadeInView>
      </View>
    </KeyboardAvoidingView>
  )
}

const getStyle = () =>
  StyleSheet.create({
    inputContainer: {
      // @ts-ignore
      outlineStyle: 'none',
      color: Colors.white,
      height: '100%',
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    buttonContainer: {
      borderRadius: 8,
      backgroundColor: Colors.primary,
      paddingVertical: 9,
      height: 48,
    },
  })

export default CommentModal
