import * as React from 'react'
import {
  Image,
  ImageStyle,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { Modal } from 'react-native-paper'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

export interface ImageLightBoxProps {
  visible: boolean
  imagePath: string | string[]
  imageStyle?: ImageStyle
  containerStyle?: ViewStyle
  modalStyle?: ViewStyle
  resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center'
  hideModal: () => void
}

const ImageLightBox = ({
  visible,
  imagePath,
  imageStyle,
  containerStyle,
  modalStyle,
  resizeMode,
  hideModal,
}: ImageLightBoxProps) => {
  const { Layout, Images } = useTheme()
  const styles = getStyle()
  const [sliderIndex, setSliderIndex] = React.useState(0)

  const handleClose = () => {
    setSliderIndex(0)
    hideModal()
  }

  const handleClickSlider = (type: 'prev' | 'next') => {
    if (type === 'prev') {
      setSliderIndex(sliderIndex - 1)
    } else {
      setSliderIndex(sliderIndex + 1)
    }
  }

  const renderSingleImage = () => {
    return (
      <View style={{ backgroundColor: '#fff', ...containerStyle }}>
        <Image
          // @ts-ignore
          source={imagePath}
          style={{ ...imageStyle }}
          resizeMode={resizeMode}
        />
      </View>
    )
  }

  const renderMultipleImages = () => {
    if (!Array.isArray(imagePath)) {
      return null
    }

    const sliderNumber = imagePath.length

    return (
      <View style={{ backgroundColor: '#fff', ...containerStyle }}>
        <Carousel
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          emulateTouch={true}
          selectedItem={sliderIndex}
          onChange={index => setSliderIndex(index)}
          width={380}
          centerMode
          centerSlidePercentage={100}
        >
          {imagePath.map((image, index) => (
            <Image
              key={index}
              // @ts-ignore
              source={image}
              style={{ ...imageStyle }}
              resizeMode={resizeMode}
            />
          ))}
        </Carousel>
        <View style={[styles.sliderDotsContainer]}>
          <TouchableOpacity
            style={[
              styles.sliderArrow,
              { opacity: sliderIndex === 0 ? 0.5 : 1 },
            ]}
            disabled={sliderIndex === 0}
            onPress={() => handleClickSlider('prev')}
          >
            <Image
              style={[Layout.iconSize40]}
              source={Images.arrowLeft}
              resizeMode="cover"
            />
          </TouchableOpacity>
          {new Array(sliderNumber).fill(0).map((_: number, index: number) => {
            const isActive = index === sliderIndex
            return (
              <View
                style={[isActive ? styles.sliderDotActive : styles.sliderDot]}
              />
            )
          })}
          <TouchableOpacity
            style={[
              styles.sliderArrow,
              { opacity: sliderIndex === sliderNumber - 1 ? 0.5 : 1 },
            ]}
            disabled={sliderIndex === sliderNumber - 1}
            onPress={() => handleClickSlider('next')}
          >
            <Image
              style={[Layout.iconSize40, { transform: [{ rotate: '180deg' }] }]}
              source={Images.arrowLeft}
              resizeMode="cover"
            />
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  const renderImages = () => {
    if (Array.isArray(imagePath)) {
      return renderMultipleImages()
    }
    return renderSingleImage()
  }

  return (
    <Modal visible={visible} onDismiss={handleClose} style={modalStyle}>
      {renderImages()}
    </Modal>
  )
}

ImageLightBox.defaultProps = {
  imageStyle: {},
  containerStyle: {},
  modalStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  resizeMode: 'contain',
}

const getStyle = () =>
  StyleSheet.create({
    closeButton: {
      position: 'absolute',
      top: 0,
      left: 40,
    },
    sliderArrow: {
      height: '100%',
    },
    sliderDotsContainer: {
      width: '100%',
      height: 40,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
    },
    sliderDot: {
      width: 5,
      height: 5,
      borderRadius: 2.5,
      backgroundColor: '#545454',
    },
    sliderDotActive: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: Colors.primary,
    },
  })

export default ImageLightBox
