import React, { useState, useEffect } from 'react'
import {
  View,
  Modal,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  Platform,
  Image,
} from 'react-native'
import { Colors } from '@/Theme/Variables'
import { useCheckLogin, useTheme } from '@/Hooks'

import { EventName, handleNewOldClients } from './util'

interface PopupAdProps {
  visible: boolean
  onClose: () => void
  url: string
  timeout?: number // 自動關閉時間（毫秒）
  closeButtonPosition?: 'top-right' | 'top-left'
}

const PopupAd: React.FC<PopupAdProps> = ({
  visible,
  onClose,
  url,
  timeout,
  closeButtonPosition = 'top-right',
}) => {
  const { Images } = useTheme()
  const [loading, setLoading] = useState(true)
  const isLogin = useCheckLogin()

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (visible && timeout) {
      timer = setTimeout(() => {
        onClose()
      }, timeout)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [visible, timeout, onClose])

  // 監聽 iframe 的 postmessage
  useEffect(() => {
    const handlePostMessage = (event: any) => {
      try {
        const data = JSON.parse(event.data)
        // 新舊客活動 3/24~4/7
        if (data.type === EventName.NEW_OLD_CLIENTS) {
          handleNewOldClients({ data, isLogin })
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    // @ts-ignore
    window.addEventListener('message', handlePostMessage)
    return () => {
      // @ts-ignore
      window.removeEventListener('message', handlePostMessage)
    }
  }, [isLogin])

  const closeButtonStyle = {
    ...(closeButtonPosition === 'top-right'
      ? { top: 15, right: 15 }
      : { top: 15, left: 15 }),
  }

  const renderContent = () => {
    if (Platform.OS === 'web') {
      return (
        <>
          {loading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          )}
          <iframe
            src={url}
            style={styles.iframeContainer}
            onLoad={() => setLoading(false)}
          />
        </>
      )
    }

    return (
      <View style={styles.fallbackContainer}>
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <View
          style={[
            styles.container,
            {
              width: Dimensions.get('window').width * 0.9,
              height: Dimensions.get('window').height * 0.8,
            },
          ]}
        >
          {renderContent()}
          <TouchableOpacity
            style={[styles.closeButton, closeButtonStyle]}
            onPress={onClose}
          >
            <Image
              source={Images.close}
              style={{ width: 24, height: 24 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 500,
    marginHorizontal: 'auto',
  },
  container: {
    backgroundColor: '#FFF',
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    maxWidth: 500,
    marginHorizontal: 'auto',
  },
  closeButton: {
    position: 'absolute',
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: Colors.black,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    zIndex: 1,
  },
  fallbackContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframeContainer: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
})

export default PopupAd
