import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  TextInput,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { FadeInDirectionView, FadeInView, Header } from '@/Components'
import { spiritActions } from '@/Store/Spirit'
import { globalActions } from '@/Store/Global'
import noteApi from '@/Services/modules/note'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const DEFAULT_TEMPLATE_ID_NAME = 'default'
const DEFAULT_TEMPLATE_CONTENT =
  '香氣 (Nose)：\n\n風味 (Palate)：\n\n尾韻 (Finish)：\n\n口感 (Taste)：\n\n總結 (Comment)：'
const CONTENT_EMPTY_ERROR = 'The content field is required.'

const CommentTemplateModal: React.FC<any> = ({ route, navigation }) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const styles = getStyle()

  const commentInputRef = useRef<TextInput>(null)
  const [templateContent, setTemplateContent] = useState<string>('')

  const { isSuccess: isGetCommentTemplateListSuccess } =
    noteApi.useGetCommentTemplateListQuery()

  const {
    data: commentTemplateData,
    isError: notHasTemplate,
    isSuccess: isGetCommentTemplateSuccess,
  } = noteApi.useGetCommentTemplateQuery(
    { id: DEFAULT_TEMPLATE_ID_NAME },
    { skip: !isGetCommentTemplateListSuccess },
  )

  const [
    postCommentTemplate,
    {
      isSuccess: postCommentTemplateIsSuccess,
      error: postCommentTemplateIsError,
      reset: postCommentTemplateReset,
    },
  ] = noteApi.usePostCommentTemplateMutation()

  const [
    patchCommentTemplate,
    {
      isSuccess: patchCommentTemplateIsSuccess,
      error: patchCommentTemplateIsError,
      reset: patchCommentTemplateReset,
    },
  ] = noteApi.usePatchCommentTemplateMutation()

  const disabledSave = useMemo(() => {
    return (
      templateContent?.length === 0 ||
      templateContent === commentTemplateData?.data?.content
    )
  }, [templateContent, commentTemplateData?.data?.content])

  // Notifications
  const showSuccessSnackbar = useCallback(
    (message: string) => {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message,
          type: 'default',
          showIcon: false,
        }),
      )
    },
    [dispatch],
  )

  const showErrorSnackbar = useCallback(
    (message: string) => {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message,
          type: 'error',
          showIcon: false,
        }),
      )
    },
    [dispatch],
  )

  const openWarningEmptyTemplateDialog = useCallback(() => {
    showErrorSnackbar('模板尚無內容，請輸入模板文字')
  }, [showErrorSnackbar])

  const navigateToCommentModal = useCallback(() => {
    navigation.navigate(AuthPagesEnum.CommentModal, { ...route.params })
  }, [navigation, route.params])

  // 有模板資料帶 api 資料，沒有則帶預設模板
  useEffect(() => {
    if (isGetCommentTemplateSuccess) {
      setTemplateContent(commentTemplateData?.data?.content)
      return
    }
    setTemplateContent(DEFAULT_TEMPLATE_CONTENT)
  }, [commentTemplateData?.data, isGetCommentTemplateSuccess])

  useEffect(() => {
    if (postCommentTemplateIsSuccess) {
      showSuccessSnackbar('儲存模板成功')
      postCommentTemplateReset()
    }
  }, [
    postCommentTemplateIsSuccess,
    postCommentTemplateReset,
    showSuccessSnackbar,
  ])

  useEffect(() => {
    if (patchCommentTemplateIsSuccess) {
      showSuccessSnackbar('儲存模板成功')
      patchCommentTemplateReset()
    }
  }, [
    patchCommentTemplateIsSuccess,
    patchCommentTemplateReset,
    showSuccessSnackbar,
  ])

  useEffect(() => {
    const postErrorRes = postCommentTemplateIsError as any
    const patchErrorRes = patchCommentTemplateIsError as any
    const postMessage = postErrorRes?.data?.errors?.[0]?.message
    const patchMessage = patchErrorRes?.data?.errors?.[0]?.message

    if (
      postMessage === CONTENT_EMPTY_ERROR ||
      patchMessage === CONTENT_EMPTY_ERROR
    ) {
      openWarningEmptyTemplateDialog()
    }
  }, [
    patchCommentTemplateIsError,
    postCommentTemplateIsError,
    openWarningEmptyTemplateDialog,
  ])

  // 儲存模板
  const handleSaveTemplate = useCallback(() => {
    if (templateContent?.length === 0) {
      openWarningEmptyTemplateDialog()
      return
    }

    // 如果沒有模板 id 則新增模板
    if (notHasTemplate) {
      // 新增模板
      postCommentTemplate({
        name: DEFAULT_TEMPLATE_ID_NAME,
        content: templateContent,
      })
    } else if (commentTemplateData?.data?.id !== '') {
      // 更新模板
      patchCommentTemplate({
        id: DEFAULT_TEMPLATE_ID_NAME,
        name: DEFAULT_TEMPLATE_ID_NAME,
        content: templateContent,
      })
    }
  }, [
    templateContent,
    commentTemplateData?.data?.id,
    postCommentTemplate,
    patchCommentTemplate,
    openWarningEmptyTemplateDialog,
    notHasTemplate,
  ])

  // 使用模板會覆蓋文字筆記
  const applyTemplateToNote = useCallback(() => {
    dispatch(
      spiritActions.setSpiritData({
        content: commentTemplateData?.data?.content || DEFAULT_TEMPLATE_CONTENT,
      }),
    )
    dispatch(globalActions.closeBottomDialog())
    showSuccessSnackbar('板模套用並覆蓋成功')
    navigateToCommentModal()
  }, [
    dispatch,
    commentTemplateData?.data?.content,
    navigateToCommentModal,
    showSuccessSnackbar,
  ])

  // 不儲存模板
  const handleNotSave = useCallback(() => {
    setTemplateContent(
      commentTemplateData?.data?.content || DEFAULT_TEMPLATE_CONTENT,
    )
    dispatch(globalActions.closeBottomDialog())
    navigateToCommentModal()
  }, [dispatch, commentTemplateData?.data?.content, navigateToCommentModal])

  // 儲存模板並返回
  const handleSaveAndReturn = useCallback(() => {
    handleSaveTemplate()
    dispatch(globalActions.closeBottomDialog())
    // 延遲一下才跳轉，確保 snackbar 出現
    setTimeout(() => {
      navigateToCommentModal()
    }, 300)
  }, [dispatch, handleSaveTemplate, navigateToCommentModal])

  const showWarningUnsavedDialog = useCallback(
    (onSave: () => void, onDiscard: () => void) => {
      dispatch(
        globalActions.openBottomDialog({
          visible: true,
          title: '儲存您的模板',
          titleStyle: {
            fontSize: 20,
            fontWeight: '700',
          },
          disabledBackgroundClose: false,
          content: (
            <View style={[Layout.center, Gutters.smallBMargin]}>
              <View
                style={[
                  Gutters.regularBPadding,
                  Gutters.regularHPadding,
                  Gutters.regularBMargin,
                  {
                    borderBottomWidth: BorderWidth.width2,
                    borderBottomColor: Colors.darkText,
                  },
                ]}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  尚有編輯過的模板沒有儲存，返回時要進行儲存嗎？
                </Text>
              </View>
              <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
                <TouchableOpacity
                  style={[
                    Layout.fullWidth,
                    Layout.center,
                    Gutters.smallBMargin,
                    Gutters.tinyVPadding,
                    {
                      borderRadius: BorderRadius.radius8,
                      height: Height.height48,
                      backgroundColor: Colors.primary,
                    },
                  ]}
                  onPress={onSave}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight500,
                      {
                        color: Colors.fontText.dark.primary2,
                      },
                    ]}
                  >
                    儲存並返回
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    Layout.fullWidth,
                    Layout.center,
                    Gutters.smallBMargin,
                    Gutters.tinyVPadding,
                    {
                      borderRadius: BorderRadius.radius8,
                      height: Height.height48,
                      backgroundColor: Colors.transparent,
                      borderWidth: BorderWidth.width1,
                      borderColor: Colors.border.default,
                    },
                  ]}
                  onPress={onDiscard}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight500,
                      {
                        color: Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    不儲存
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    Layout.fullWidth,
                    Layout.center,
                    Gutters.smallBMargin,
                    { height: Height.height48 },
                  ]}
                  onPress={() => {
                    dispatch(globalActions.closeBottomDialog())
                  }}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight500,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    取消
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ),
        }),
      )
    },
    [dispatch, Layout, Gutters, Fonts],
  )

  const handleGoBack = useCallback(() => {
    const needWarningDialog = notHasTemplate
      ? templateContent !== DEFAULT_TEMPLATE_CONTENT
      : templateContent !== commentTemplateData?.data?.content

    if (needWarningDialog) {
      showWarningUnsavedDialog(handleSaveAndReturn, handleNotSave)
      return
    }

    setTemplateContent(
      commentTemplateData?.data?.content || DEFAULT_TEMPLATE_CONTENT,
    )

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.Main)
    }
  }, [
    notHasTemplate,
    templateContent,
    commentTemplateData?.data?.content,
    showWarningUnsavedDialog,
    handleSaveAndReturn,
    handleNotSave,
    navigation,
  ])

  const openWarningTemplateDialog = useCallback(() => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '使用模板',
        titleStyle: {
          fontSize: 20,
          fontWeight: '700',
        },
        disabledBackgroundClose: false,
        content: (
          <View style={[Layout.center, Gutters.smallBMargin]}>
            <View
              style={[
                Gutters.regularBPadding,
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                {
                  borderBottomWidth: BorderWidth.width2,
                  borderBottomColor: Colors.darkText,
                },
              ]}
            >
              <Text
                style={[Fonts.size14, Fonts.weight500, { color: Colors.white }]}
              >
                使用模板將會覆蓋目前的編輯內容，您確定要繼續嗎？
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                    backgroundColor: Colors.snackbar.error,
                  },
                ]}
                onPress={applyTemplateToNote}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    {
                      color: Colors.fontText.dark.primary2,
                    },
                  ]}
                >
                  確定套用並覆蓋
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  { height: Height.height48 },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.bold, { color: Colors.white }]}>取消</Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [dispatch, Layout, Gutters, Fonts, applyTemplateToNote])

  return (
    <KeyboardAvoidingView
      style={[Layout.fill]}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 50}
    >
      <View style={[Layout.fill]}>
        <View style={styles.filter}>
          <TouchableOpacity style={[Layout.fullHeight]} onPress={() => {}} />
        </View>
        <FadeInDirectionView
          direction="bottom"
          duration={500}
          style={styles.contentContainer}
        >
          <View
            style={[
              Layout.fill,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            <ScrollView keyboardDismissMode="interactive">
              <FadeInView
                duration={250}
                style={{
                  borderBottomColor: Colors.background.onSurface,
                  borderBottomWidth: 1,
                }}
              >
                <Header
                  title={t('commentModal.headerTemplateTitle_edit')}
                  backgroundColor={Colors.background.surface}
                  leftIcon={
                    <Image
                      style={[styles.arrowLeftIcon]}
                      source={Images.arrowLeft}
                      resizeMode="contain"
                    />
                  }
                  leftIconPress={handleGoBack}
                  rightIcon={
                    <TouchableOpacity
                      onPress={handleSaveTemplate}
                      disabled={disabledSave}
                    >
                      <Text
                        style={[
                          Fonts.size16,
                          Fonts.weight400,
                          {
                            color: disabledSave ? Colors.gray : Colors.primary,
                          },
                        ]}
                      >
                        儲存模板
                      </Text>
                    </TouchableOpacity>
                  }
                />
              </FadeInView>
              <FadeInView duration={350} style={Layout.fullHeight}>
                <View
                  style={[Gutters.regularHPadding, Gutters.regularVPadding]}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight500,
                      Gutters.tinyBMargin,
                      { color: Colors.fontText.light.primary3 },
                    ]}
                  >
                    套用模板，或編輯文本以儲存自訂模板
                  </Text>
                  <TextInput
                    ref={commentInputRef}
                    placeholder={t('commentModal.templatePlaceholder')}
                    placeholderTextColor="#999999"
                    multiline={true}
                    numberOfLines={30}
                    style={[Gutters.smallBMargin, styles.inputContainer]}
                    value={templateContent}
                    onChangeText={setTemplateContent}
                  />
                </View>
              </FadeInView>
            </ScrollView>
          </View>
          <FadeInView duration={550} style={styles.bottomContainer}>
            <View style={[Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.regularBMargin,
                  styles.buttonContainer,
                ]}
                onPress={openWarningTemplateDialog}
              >
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.bold,
                    {
                      color: Colors.fontText.dark.primary2,
                    },
                  ]}
                >
                  套用到筆記
                </Text>
              </TouchableOpacity>
            </View>
          </FadeInView>
        </FadeInDirectionView>
      </View>
    </KeyboardAvoidingView>
  )
}

const getStyle = () =>
  StyleSheet.create({
    inputContainer: {
      // @ts-ignore
      outlineStyle: 'none',
      borderWidth: 1,
      borderColor: Colors.border.default,
      borderRadius: BorderRadius.radius8,
      padding: 10,
      color: Colors.white,
      height: '100%',
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    filter: {
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      maxWidth: 428,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#1E1E1E',
      opacity: 1,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    bottomContainer: {
      borderTopWidth: 1,
      borderTopColor: Colors.border.default,
      paddingTop: 16,
    },
    buttonContainer: {
      borderRadius: 8,
      backgroundColor: Colors.primary,
      paddingVertical: 9,
      height: 48,
    },
  })

export default CommentTemplateModal
