import React from 'react'
import { useTheme, useWindowSize } from '@/Hooks'
import { useDispatch, useSelector } from 'react-redux'
import { Colors } from '@/Theme/Variables'
import { RootState } from '@/Store'
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { globalActions } from '@/Store/Global'
import { openDownloadAppWeb } from '@/Util/global'

interface Props {
  bottom?: number
}

// 因為每一頁底部高度不同，且 scroll view 在內頁中不是在最外層，所以只能每頁都放
const DownloadSign = ({ bottom }: Props) => {
  const dispatch = useDispatch()
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const windowSize = useWindowSize()
  const styles = style()

  const { openDownloadSign } = useSelector((state: RootState) => state.global)

  // 如果是桌機版大網，就不用出現了
  if (windowSize.width > 768) {
    return <></>
  }

  if (!openDownloadSign) {
    return null
  }

  return (
    <View style={[styles.container, { bottom: bottom }]}>
      <LinearGradient
        colors={['rgba(18, 18, 18, 0.2)', '#121212', '#121212', '#000']}
        start={{ x: 0.5, y: 0.0 }}
        end={{ x: 0.5, y: 0.9 }}
        style={[Layout.fill, Layout.alignItemsCenter, Gutters.smallTPadding]}
      >
        <View
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            Gutters.smallBMargin,
            { width: '90%' },
          ]}
        >
          <Image
            source={Images.app_icon}
            resizeMode="contain"
            style={[
              Layout.iconSize32,
              Gutters.tinyRMargin,
              { borderRadius: 4 },
            ]}
          />
          <TouchableOpacity
            style={styles.closeIcon}
            onPress={() => dispatch(globalActions.closeDownloadSign())}
          >
            <Image
              source={Images.close}
              resizeMode="contain"
              style={[Layout.iconSize16]}
            />
          </TouchableOpacity>
          <View style={{ width: '80%' }}>
            <Text
              style={[
                Fonts.weight700,
                Fonts.size12,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              立即下載烈酒探索家
              APP！享受掃描辨識烈酒、紀錄品飲筆記與同好交流心得的便利功能！
            </Text>
          </View>
        </View>
        <TouchableOpacity
          style={[Layout.center, styles.downloadBtn]}
          onPress={openDownloadAppWeb}
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              { color: Colors.fontText.dark.primary2 },
            ]}
          >
            取得 APP 獲取更好體驗
          </Text>
        </TouchableOpacity>
      </LinearGradient>
    </View>
  )
}

DownloadSign.defaultProps = {
  bottom: 0,
}

const style = () =>
  StyleSheet.create({
    container: {
      position: 'absolute',
      left: 0,
      height: 115,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      maxWidth: 428,
    },
    closeIcon: {
      position: 'absolute',
      top: 10,
      right: 0,
      zIndex: 999,
    },
    downloadBtn: {
      width: '90%',
      height: 32,
      backgroundColor: Colors.primary,
      borderRadius: 8,
    },
  })

export default DownloadSign
