import React, { useCallback, useEffect, useMemo } from 'react'
import {
  ActivityIndicator,
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { GetSpiritCouponsResponse } from '@/Services/modules/loyaltyPrograms/loyaltyPrograms.type'
import useTheme from '@/Hooks/useTheme'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import { globalActions } from '@/Store/Global'
import loyaltyProgramsApi from '@/Services/modules/loyaltyPrograms'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { navigate } from '@/Navigators/utils'
import useLoginDialog from '@/Hooks/useLoginDialog'
import userApi from '@/Services/modules/users'
import { couponReferral } from '@/Util/couponReferral'

interface CouponsContainerProps {
  data: GetSpiritCouponsResponse
  spiritId: string
}

const CouponsContainer = ({ data, spiritId }: CouponsContainerProps) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const openLoginDialog = useLoginDialog()
  const mainCouponData = data?.data?.[0]

  const { data: getMeData } = userApi.useGetMeQuery()

  const [getCouponRequest, { isLoading, isSuccess, isError }] =
    loyaltyProgramsApi.useGetCouponMutation()

  const CTADisabled = useMemo(() => {
    if (mainCouponData?.collected) {
      return true
    } else if (!mainCouponData?.collectable) {
      return true
    }
    return false
  }, [mainCouponData?.collectable, mainCouponData?.collected])

  const imageBackgroundHeightAndPosition = useMemo(() => {
    const windowWidth = Dimensions.get('window').width
    if (windowWidth > 400) {
      return {
        height: 48,
        bottom: 0,
      }
    } else if (windowWidth > 375) {
      return {
        height: 43,
        bottom: 2,
      }
    } else {
      return {
        height: 40,
        bottom: 3,
      }
    }
  }, [])

  const styles = getStyle({ imageBackgroundHeightAndPosition })

  const handleCloseRedeemDialog = useCallback(() => {
    dispatch(globalActions.closeBottomDialog())
  }, [dispatch])

  const handleRedeemCoupon = useCallback(async () => {
    const referralCode = await couponReferral.validateReferralCode()
    await getCouponRequest({
      programId: mainCouponData?.id,
      spiritId,
      referralCode,
    })
  }, [getCouponRequest, mainCouponData?.id, spiritId])

  // 打開兌換 dialog
  const handleOpenRedeemDialog = useCallback(() => {
    if (!getMeData) {
      openLoginDialog()
      return
    }
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        disabledBackgroundClose: true,
        handleClickBackground: handleCloseRedeemDialog,
        content: (
          <View style={[Layout.center, Gutters.regularVMargin]}>
            <View style={[Layout.fullWidth, Gutters.regularHPadding]}>
              <View style={[styles.couponContainer]}>
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Layout.justifyContentBetween,
                  ]}
                >
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size20,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    {mainCouponData?.couponName}
                  </Text>
                  <Image
                    source={Images.product_detail_coupon_icon}
                    style={[Layout.iconSize56]}
                    resizeMode="contain"
                  />
                </View>
                <Text
                  style={[
                    Fonts.weight400,
                    Fonts.size14,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >{`適用於：${mainCouponData?.applicableTarget}`}</Text>
                <Text
                  style={[
                    Fonts.weight400,
                    Fonts.size14,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >{`有效期限：${dayjs(mainCouponData?.couponStartedAt).format(
                  'YYYY-MM-DD',
                )} - ${dayjs(mainCouponData?.couponExpiresAt).format(
                  'YYYY-MM-DD',
                )}`}</Text>
              </View>
            </View>
            <View
              style={[
                {
                  backgroundColor: Colors.fontText.light.primary2,
                  height: 1,
                  width: '100%',
                  marginVertical: 16,
                },
              ]}
            />
            <View style={[Layout.fullWidth, Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.primary,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                disabled={isLoading}
                onPress={handleRedeemCoupon}
              >
                {isLoading ? (
                  <ActivityIndicator
                    size="small"
                    color={Colors.fontText.light.primary2}
                  />
                ) : (
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size16,
                      { color: Colors.fontText.dark.primary2 },
                    ]}
                  >
                    立即領取
                  </Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.regularHPadding,
                ]}
                onPress={handleCloseRedeemDialog}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [
    getMeData,
    openLoginDialog,
    dispatch,
    handleCloseRedeemDialog,
    Layout,
    Gutters,
    styles,
    Fonts,
    mainCouponData?.couponName,
    mainCouponData?.applicableTarget,
    mainCouponData?.couponStartedAt,
    mainCouponData?.couponExpiresAt,
    Images,
    isLoading,
    handleRedeemCoupon,
  ])

  // 打開成功領取 dialog
  const handleOpenSuccessRedeemDialog = useCallback(() => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        disabledBackgroundClose: true,
        handleClickBackground: handleCloseRedeemDialog,
        content: (
          <View style={[Layout.center, Gutters.regularVMargin]}>
            <View
              style={[
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.regularHPadding,
              ]}
            >
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  Gutters.regularBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                領取成功
              </Text>
            </View>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              優惠劵已經成功領取，可至【我的優惠劵】查看
            </Text>

            <View
              style={[
                {
                  backgroundColor: Colors.fontText.light.primary2,
                  height: 1,
                  width: '100%',
                  marginVertical: 16,
                },
              ]}
            />
            <View style={[Layout.fullWidth, Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.primary,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                onPress={() => {
                  navigate(AuthPagesEnum.CouponListContainer, {})
                  handleCloseRedeemDialog()
                }}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.dark.primary2 },
                  ]}
                >
                  前往查看優惠券
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.regularHPadding,
                ]}
                onPress={handleCloseRedeemDialog}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  返回
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [Fonts, Gutters, Layout, dispatch, handleCloseRedeemDialog])

  // 打開領取失敗的 dialog
  const handleOpenFailedRedeemDialog = useCallback(() => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        disabledBackgroundClose: true,
        handleClickBackground: handleCloseRedeemDialog,
        content: (
          <View style={[Layout.center, Gutters.regularVMargin]}>
            <View
              style={[
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.regularHPadding,
              ]}
            >
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  Gutters.regularBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                優惠券已搶完
              </Text>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                優惠券已搶完，謝謝您的支持
              </Text>
            </View>
            <View
              style={[
                {
                  backgroundColor: Colors.fontText.light.primary2,
                  height: 1,
                  width: '100%',
                  marginVertical: 16,
                },
              ]}
            />
            <View style={[Layout.fullWidth, Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.primary,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                onPress={handleCloseRedeemDialog}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.dark.primary2 },
                  ]}
                >
                  返回
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [Fonts, Gutters, Layout, dispatch, handleCloseRedeemDialog])

  useEffect(() => {
    if (isSuccess) {
      handleCloseRedeemDialog()
      handleOpenSuccessRedeemDialog()
    }
  }, [handleCloseRedeemDialog, handleOpenSuccessRedeemDialog, isSuccess])

  useEffect(() => {
    if (isError) {
      handleCloseRedeemDialog()
      handleOpenFailedRedeemDialog()
    }
  }, [handleCloseRedeemDialog, handleOpenFailedRedeemDialog, isError])

  const handleConvertTitleTextLimit = useCallback(
    (text: string, limit: number) => {
      if (text.length > limit) {
        return text.slice(0, limit) + '...'
      }
      return text
    },
    [],
  )

  const CTAConfigData = useMemo(() => {
    if (mainCouponData?.collectable && !mainCouponData?.collected) {
      return {
        text: '立即搶優惠',
        textStyle: {
          color: Colors.fontText.light.primary2,
        },
        style: {
          backgroundColor: '#E61111',
        },
        onPress: handleOpenRedeemDialog,
      }
    } else if (mainCouponData?.collected) {
      return {
        text: '前往使用',
        textStyle: {
          color: Colors.fontText.dark.primary2,
        },
        style: {
          backgroundColor: Colors.primary,
        },
        onPress: () => {
          navigate(AuthPagesEnum.CouponListContainer, {})
        },
      }
    } else if (!mainCouponData?.collectable) {
      return {
        text: '已搶完',
        textStyle: {
          color: Colors.fontText.dark.primary2,
          opacity: 0.5,
        },
        style: {
          backgroundColor: '#EFC991',
        },
        onPress: () => {},
      }
    }

    return {
      text: '',
      textStyle: {
        color: Colors.fontText.light.primary2,
      },
      style: {
        backgroundColor: '#E61111',
      },
      onPress: () => {},
    }
  }, [
    mainCouponData?.collectable,
    mainCouponData?.collected,
    handleOpenRedeemDialog,
  ])

  return (
    <ImageBackground
      source={Images.product_detail_coupon_background}
      resizeMode="contain"
      style={[Layout.fullWidth, Layout.justifyContentAround, styles.container]}
    >
      <TouchableOpacity
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          Layout.justifyContentBetween,
        ]}
        disabled={CTADisabled}
        onPress={handleOpenRedeemDialog}
      >
        <View style={[Layout.row, Layout.alignItemsCenter]}>
          <Text
            style={[
              Fonts.weight400,
              Fonts.size14,
              {
                color: Colors.fontText.dark.primary2,
              },
            ]}
          >
            {handleConvertTitleTextLimit(mainCouponData?.name, 20)}
          </Text>
        </View>
      </TouchableOpacity>
      <TouchableWithoutFeedback onPress={CTAConfigData?.onPress}>
        <View style={[styles.redeemCTA, CTAConfigData?.style]}>
          <Text
            style={[Fonts.weight700, Fonts.size14, CTAConfigData?.textStyle]}
          >
            {CTAConfigData?.text || ''}
          </Text>
        </View>
      </TouchableWithoutFeedback>
      <View style={[styles.rightNotch]} />
    </ImageBackground>
  )
}

const getStyle = ({
  imageBackgroundHeightAndPosition,
}: {
  imageBackgroundHeightAndPosition: {
    height: number
    bottom: number
  }
}) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 16,
      paddingVertical: 10,
      height: 46,
      minWidth: '100%',
      overflow: 'hidden',
    },
    couponContainer: {
      backgroundColor: Colors.background.onSurface,
      borderRadius: BorderRadius.radius8,
      padding: 16,
    },
    redeemCTA: {
      width: 110,
      position: 'absolute',
      bottom: imageBackgroundHeightAndPosition.bottom,
      right: 0,
      height: imageBackgroundHeightAndPosition.height,
      borderTopRightRadius: BorderRadius.radius8,
      borderBottomRightRadius: BorderRadius.radius8,
      borderTopLeftRadius: 23,
      borderBottomLeftRadius: 23,
      justifyContent: 'center',
      alignItems: 'center',
      ...Platform.select({
        web: {
          cursor: 'pointer',
        },
      }),
    },
    rightNotch: {
      position: 'absolute',
      width: 18,
      height: 14,
      backgroundColor: Colors.black,
      borderRadius: 10,
      right: -10,
      top: 17,
    },
  })

export default React.memo(CouponsContainer)
