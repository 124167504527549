import { EventName, sendIframeMessage } from '../index'
import { getConfig } from '@/Util/global'
import { prefixProxyEndpoint } from '@/Services/api'
import { getParam, navigate, setPendingRedirect } from '@/Navigators/utils'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { Dispatch } from '@reduxjs/toolkit'
import { couponActions } from '@/Store/Coupon'
interface I09SpecialOfferProps {
  data: any
  isLogin: boolean
  dispatch: Dispatch
}

export const USER_DEFINED_ID = [
  'i09_20250407_01',
  'i09_20250407_02',
  'i09_20250407_03',
  'i09_20250407_04',
  'i09_20250407_05',
  'i09_20250407_06',
  'i09_20250407_07',
  'i09_20250407_08',
  'i09_20250407_09',
  'i09_20250407_10',
  'i09_20250407_11',
  'i09_20250407_12',
  'i09_20250407_13',
  'i09_20250407_14',
  'i09_20250407_15',
  'i09_20250407_16',
  'i09_20250407_17',
  'i09_20250407_18',
  'i09_20250407_19',
  'i09_20250407_20',
  'i09_20250407_21',
  'i09_20250407_22',
  'i09_20250407_23',
  'i09_20250407_24',
  'i09_20250407_25',
  'i09_20250407_26',
  'i09_20250407_27',
  'i09_20250407_28',
  'i09_20250407_29',
  'i09_20250407_30',
  'i09_20250407_31',
  'i09_20250407_32',
  'i09_20250407_33',
  'i09_20250407_34',
  'i09_20250407_35',
  'i09_20250407_36',
  'i09_20250407_37',
  'i09_20250407_38',
  'i09_20250407_39',
  'i09_20250407_40',
  'i09_20250407_41',
  'i09_20250407_42',
  'i09_20250407_43',
  'i09_20250407_44',
]

const getURL = (url: string) => {
  return getConfig().ENV === 'local'
    ? prefixProxyEndpoint(url)
    : `${getConfig().API_URL}${url}`
}

// 取得優惠券列表
const GET_COUPON_LIST_REQUEST_URL = getURL('/v1/loyalty-programs:fetch-by-uid')
const getCouponListRequest = async () => {
  try {
    const response = await fetch(GET_COUPON_LIST_REQUEST_URL, {
      method: 'POST',
      body: JSON.stringify({ userDefinedIds: USER_DEFINED_ID }),
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'zh',
      },
    }).then(res => res.json())
    return response
  } catch (error) {
    console.log('error', error)
  }
}

// 領取優惠券
const CLAIM_COUPON_REQUEST_URL = getURL('/v1/coupons')
const claimCouponRequest = async ({
  programId,
  referralCode,
  spiritId,
}: {
  programId: string
  referralCode: string
  spiritId: string
}) => {
  try {
    const response = await fetch(CLAIM_COUPON_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        programId,
        referralCode,
        spiritId,
      }),
    }).then(res => res.json())
    return response
  } catch (error) {
    console.log('error', error)
  }
}

export const handleI09SpecialOffer = async ({
  data,
  isLogin,
  dispatch,
}: I09SpecialOfferProps) => {
  if (data.type === EventName.I09_SPECIAL_OFFER) {
    // 取得優惠券列表
    if (data.action === 'GET_COUPON_LIST') {
      await getCouponList()
    }
    // 領取優惠券
    if (data.action === 'CLAIM_COUPON') {
      if (isLogin) {
        const payload = {
          programId: data.data.programId,
          referralCode: data.data.referralCode,
          spiritId: data.data.spiritId,
        }
        await claimCouponRequest(payload)
        return
      }
      sendIframeMessage({
        type: 'CLAIM_COUPON_REQUEST_NOT_LOGIN',
      })
    }
    // 跳轉至優惠券列表
    if (data.action === 'TO_COUPON_LIST') {
      const params: any = getParam()
      setPendingRedirect(PagesEnum.CampaignSite, {
        url: params?.url || '',
      })
      navigate(AuthPagesEnum.CouponListContainer, {})
    }

    if (data.action === 'TO_LOGIN') {
      const params: any = getParam()
      setPendingRedirect(PagesEnum.CampaignSite, {
        url: params?.url || '',
        from: 'login',
      })
      // 將優惠券資料儲存至 redux
      dispatch(
        couponActions.setClaimCoupon({
          programId: data.data.programId,
          referralCode: data.data.referralCode,
          spiritId: data.data.spiritId,
        }),
      )
      navigate(PagesEnum.LoginMainContainer, {})
    }
  }
}

// 取得優惠券列表
const getCouponList = async () => {
  const response = await getCouponListRequest()
  sendIframeMessage({
    type: 'COUPON_LIST_RESPONSE',
    data: response,
  })
}
