import React from 'react'
import { View, Text } from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

type Props = {
  advancedRatings: {
    nose: number
    taste: number
    finish: number
  }
}

const AdvancedRatingsComponent = ({ advancedRatings }: Props) => {
  const { Layout, Fonts } = useTheme()

  const ratings = [
    { label: '香氣', value: advancedRatings.nose },
    { label: '風味', value: advancedRatings.taste },
    { label: '尾韻', value: advancedRatings.finish },
  ].filter(item => item.value !== null)

  return ratings.map((item, index) => (
    <View key={item.label} style={[Layout.row]}>
      <Text style={[Fonts.size14, { color: Colors.fontText.light.primary3 }]}>
        {`${item.label}：${item.value}`}
      </Text>
      {index < ratings.length - 1 && (
        <Text
          style={[
            Fonts.size14,
            { color: Colors.fontText.light.primary3, marginHorizontal: 8 },
          ]}
        >
          |
        </Text>
      )}
    </View>
  ))
}

export default AdvancedRatingsComponent
