import React, { useCallback, useEffect, useMemo } from 'react'
import { View, Image, StyleSheet } from 'react-native'

import { useCheckLogin, useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { DownLoadSign, Header } from '@/Components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { advertiseActions } from '@/Store/Advertise'
import { PagesEnum } from '@/Navigators/Application'
import { getConfig } from '@/Util/global'
import { getParam } from '@/Navigators/utils'
import loyaltyProgramsApi from '@/Services/modules/loyaltyPrograms'

import { handleNewOldClients } from './util'
import { handleI09SpecialOffer } from './events/i09-special-offer'
import { EventName, EventURL, sendIframeMessage } from './events'
import { couponActions } from '@/Store/Coupon'

const CampaignSite = ({ navigation, route }: any) => {
  const { Layout, Images } = useTheme()
  const dispatch = useDispatch()
  const isLogin = useCheckLogin()

  const urlParams = route.params?.url || ''

  const { campaignSiteAdvertiseURL } = useSelector(
    (state: RootState) => state.advertiseStore,
  )
  const { claimCoupon } = useSelector((state: RootState) => state.coupon)

  const displayWebViewURL = useMemo(() => {
    return urlParams
      ? `${getConfig().CAMPAIGN_SITE_DOMAIN}/${urlParams}`
      : campaignSiteAdvertiseURL
  }, [urlParams, campaignSiteAdvertiseURL])

  const [getCouponListRequest, { data: couponList }] =
    loyaltyProgramsApi.useLazyGetCouponListByProgramIdQuery()

  const [getCouponRequest] = loyaltyProgramsApi.useGetCouponMutation()

  const handleGoBack = useCallback(() => {
    dispatch(advertiseActions.clearCampaignSiteAdvertiseURL())
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.Main)
    }
  }, [dispatch, navigation])

  // 監聽 iframe 的 postmessage
  useEffect(() => {
    const handlePostMessage = (event: any) => {
      try {
        const data = JSON.parse(event.data)
        // 新舊客活動 3/24~4/7
        if (data.type === EventName.NEW_OLD_CLIENTS) {
          handleNewOldClients({ data, isLogin })
        }
        if (data.type === EventName.I09_SPECIAL_OFFER) {
          handleI09SpecialOffer({ data, isLogin, dispatch })
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    // @ts-ignore
    window.addEventListener('message', handlePostMessage)
    return () => {
      // @ts-ignore
      window.removeEventListener('message', handlePostMessage)
    }
  }, [isLogin, couponList, getCouponListRequest, dispatch])

  useEffect(() => {
    if (urlParams === EventURL.I09_SPECIAL_OFFER) {
      const params: any = getParam()
      if (
        params?.from === 'login' &&
        isLogin &&
        Object.values(claimCoupon).length > 0
      ) {
        sendIframeMessage({
          type: 'LOGIN_SUCCESS',
        })
        getCouponRequest({
          programId: claimCoupon.programId,
          referralCode: claimCoupon.referralCode,
          spiritId: claimCoupon.spiritId,
        })
        dispatch(couponActions.clearClaimCoupon())
        navigation.setParams({ from: undefined })
        return
      }
    }
  }, [urlParams, isLogin, claimCoupon, getCouponRequest, dispatch, navigation])

  useEffect(() => {
    if (!displayWebViewURL?.length) {
      handleGoBack()
    }
  }, [displayWebViewURL, handleGoBack])

  return (
    <View style={[Layout.fill]}>
      <View
        style={[Layout.fill, { backgroundColor: Colors.background.surface }]}
      >
        <Header
          title={''}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        {displayWebViewURL && (
          <iframe
            id="iframe"
            src={displayWebViewURL}
            style={styles.iframeContainer}
          />
        )}
      </View>
      <DownLoadSign />
    </View>
  )
}

const styles = StyleSheet.create({
  arrowLeftIcon: { width: 24, height: 24, paddingLeft: 50 },
  iframeContainer: { width: '100%', height: '100%', borderWidth: 0 },
})

export default CampaignSite
