import React, { useEffect } from 'react'
import { View, Image, StyleSheet, ActivityIndicator } from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { DownLoadSign, Header } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { openDownloadAppWeb } from '@/Util/global'

const Universal = ({ navigation }: any) => {
  const { Layout, Images } = useTheme()

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.Main)
    }
  }

  // 後端 302 跳轉回來這頁，我們再把他導到正確的網址
  // 例如：https://www.wenlibai.cc/universal/zh/royal-salute-38-years-old-blended-scotch-whisky/L162QEvZnz
  // 我們要把它導到：https://www.wenlibai.cc/zh/royal-salute-38-years-old-blended-scotch-whisky/L162QEvZnz
  useEffect(() => {
    try {
      // @ts-ignore
      const currentLocation = window.location.href
      // @ts-ignore
      const currentOrigin = window.location.origin
      const url = currentLocation.replace(`${currentOrigin}/universal/`, '')
      const redirectUrl = `Spiritshub://${url}`
      // @ts-ignore
      window.location.href = redirectUrl
    } catch (error) {
      openDownloadAppWeb()
    }
  }, [])

  return (
    <View style={[Layout.fill]}>
      <View
        style={[Layout.fill, { backgroundColor: Colors.background.surface }]}
      >
        <Header
          title={''}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <View style={[Layout.center, Layout.fill]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
      <DownLoadSign />
    </View>
  )
}

const styles = StyleSheet.create({
  arrowLeftIcon: { width: 24, height: 24, paddingLeft: 50 },
})

export default Universal
