import { AdvertiseItem } from '@/Services/advertiseApi'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

export type AdvertiseState = {
  userClickedAdvertise: {
    id: string
    currentIndex: number
  }[]
  AIChatRoomAdvertiseIndex: number
  campaignSiteAdvertiseURL: string
  popupAdUrl: string
  popupShow: boolean
}

const initialState = {
  userClickedAdvertise: [],
  AIChatRoomAdvertiseIndex: 0,
  campaignSiteAdvertiseURL: '',
  popupAdUrl: '',
  popupShow: false,
}

const slice = createSlice({
  name: 'advertise',
  initialState: initialState as AdvertiseState,
  reducers: {
    userClickedAdvertise: (
      state,
      action: PayloadAction<{ id: string; currentIndex: number }>,
    ) => {
      if (state.userClickedAdvertise.length === 0) {
        state.userClickedAdvertise.push(action.payload)
        return
      }

      const hasDuplicate = state.userClickedAdvertise.some(
        item => item.id === action.payload.id,
      )
      if (!hasDuplicate) {
        state.userClickedAdvertise.push(action.payload)
      }
    },
    // 聊天室的廣告需要每次進入頁面時都不一樣
    setAIChatRoomAdvertiseIndex: state => {
      state.AIChatRoomAdvertiseIndex += 1
    },
    clearAIChatRoomAdvertiseIndex: state => {
      state.AIChatRoomAdvertiseIndex = 0
    },
    setCampaignSiteAdvertiseURL: (state, action: PayloadAction<string>) => {
      state.campaignSiteAdvertiseURL = action.payload
    },
    clearCampaignSiteAdvertiseURL: state => {
      state.campaignSiteAdvertiseURL = ''
    },
    openPopupAd: (state, action: PayloadAction<string>) => {
      state.popupAdUrl = action.payload
      state.popupShow = true
    },
    closePopupAd: state => {
      state.popupAdUrl = ''
      state.popupShow = false
    },
  },
})

export const advertiseReducer = slice.reducer
export const advertiseActions = slice.actions

const handlePopupAd = async (advertise: AdvertiseItem, dispatch: Dispatch) => {
  if (advertise.interstitial) {
    // 檢查是否已經看過該廣告及次數
    const viewedAdsCountString = await AsyncStorage.getItem(
      'viewed-popup-ads-count',
    )
    const viewedAdsCount = viewedAdsCountString
      ? JSON.parse(viewedAdsCountString)
      : {}

    // 檢查廣告已經被顯示的次數
    const viewCount = viewedAdsCount[advertise.id] || 0

    // 如果該廣告已經被顯示過兩次，則不再顯示
    if (viewCount >= 2) {
      return
    }

    // 更新計數器
    viewedAdsCount[advertise.id] = viewCount + 1
    await AsyncStorage.setItem(
      'viewed-popup-ads-count',
      JSON.stringify(viewedAdsCount),
    )

    // 打開彈窗廣告
    dispatch(advertiseActions.openPopupAd(advertise.destinationUrl))
  }
}

const handleClearPopupAd = async () => {
  await AsyncStorage.removeItem('viewed-popup-ads-count')
}

export const advertiseUtils = {
  handlePopupAd,
  handleClearPopupAd,
}

export default slice.reducer
