import { createSlice } from '@reduxjs/toolkit'

export interface AuthState {
  isAuthenticated: boolean
}

const initialState: AuthState = {
  isAuthenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: state => {
      if (!state.isAuthenticated) {
        state.isAuthenticated = true
      }
    },
    clearAuth: state => {
      state.isAuthenticated = false
    },
  },
})

export const authReducer = authSlice.reducer
export const authActions = authSlice.actions

export default authSlice.reducer
