import { Meta } from '../spirits/spirits.type'

export interface GetSpiritCouponsRequest {
  spirit: string
}

export interface GetSpiritCouponsResponse {
  data: {
    id: string
    name: string
    applicableTarget: string
    couponName: string
    couponStartedAt: string
    couponExpiresAt: string
    collectable: boolean
    collected: boolean
    redeemed: boolean
  }[]
}

export interface PostCouponRequest {
  programId: string
  referralCode: string
  spiritId: string
}

export enum CouponListStatus {
  USABLE = 'usable',
  REDEEMED = 'redeemed',
  INVALID = 'invalid',
}

export interface GetCouponRequest {
  status: CouponListStatus
  perPage: number
  page: string
}

export interface GetCouponListResponse {
  data: {
    id: string
    startedAt: string
    expiresAt: string
    program: {
      couponName: string
      applicableTarget: string
    }
  }[]
  meta: Meta
}

export interface GetCouponDetailRequest {
  id: string
}

export interface GetCouponDetailResponse {
  data: {
    id: string
    startedAt: string
    expiresAt: string
    usable: boolean
    program: {
      id: string
      applicableTarget: string
      couponName: string
      couponRules: string
      couponInstructions: string
      spirits?: {
        id: string
        title: string
      }[]
    }
  }
}

export interface RedeemCouponRequest {
  id: string
  programId: string
  metadata: {
    latitude: number
    longitude: number
  }
}

export interface RedeemCouponResponse {
  data: {
    vendorData: {
      displayFormat: string
      barcodeEncoder: string
      content: string
    }
  }
}

export interface GetCouponListByProgramIdRequest {
  userDefinedIds: string[]
}

export interface GetCouponListByProgramIdResponse {
  data: {
    id: string
    name: string
    startedAt: string
    endedAt: string
    couponStartedAt: string
    couponExpiresAt: string
    collected: boolean
    spirits: {
      id: string
      title: string
      imageUrl: string
      marketPrice: number
    }[]
  }[]
}
